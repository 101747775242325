<div class="align-mid">
    <form [formGroup]="importForm" (submit)='onStringSubmit()'>
        <mat-card class="description-card">
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width" style="flex-grow: 1;">
                            <input type="text" placeholder="String podle kterého vyhledávat" matInput [matAutocomplete]="description"
                                   [formControl]="stringControl" (click)="$event.target.select();getStringHistory()">
                            <mat-autocomplete #description="matAutocomplete">
                                <mat-option *ngFor="let string of filteredStrings | async" [value]="string"
                                            (change)="selectString(string)"
                                            (click)="$event.stopPropagation()">
                                    <div id="optionString" (click)="stringClicked($event, string)">
                                        {{ string }}
                                    </div>
                                    <mat-icon id="deleteString" (click)="deleteString(string)" title="Smazat">clear</mat-icon>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="importForm.controls.description.hasError('required')">
                                Popisek nemůže být <strong>prázdný</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <button [disabled]="!importForm.valid" mat-raised-button (click)="openDialog()" color="primary" type="submit"
                                matTooltip="Podle zadaného textového řetězce vyhledá projekty na gitlabu a nabídne jejich import do aplikace. Více informací: Uživatelská příručka, sekce 1.8.">
                            Zobrazit preview
                            <mat-icon iconPositionEnd>help</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </form>

    <form [formGroup]="tokenForm" novalidate (ngSubmit)="submitToken()">
        <mat-card>
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width" style="flex-grow: 1;">
                            <input matInput placeholder="Gitlab private access token" formControlName="token">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-icon style="font-size: 22px; margin-top: 18px;" matTooltip="Nutno nastavit pro správné fungování všech funkcí užívajících gitlab api. Lze vygenerovat na Gitlab -> User Settings -> Access Tokens. Podrobnější návod: Uživatelská příručka, sekce 2.1.">help</mat-icon>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button [disabled]="!tokenForm.valid" mat-raised-button color="primary" type="submit">Nastavit
                    token
                </button>
                <button mat-raised-button color="primary" (click)="deleteToken()" type="button">Vymazat token</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
