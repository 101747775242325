<div mat-dialog-content class="center" *ngIf="data.isContainer">
    <p class="center" *ngIf="data.isProject">Určitě chcete projektu {{data.project.name}} vytvořit
        kontejner?</p>
    <p class="center" *ngIf="data.isProjects">Určitě chcete vybraným projektům vytvořit kontejner?'</p>
</div>
<div mat-dialog-actions class="modal-buttons center">
    <button mat-button (click)="onSubmit()">Ano</button>
    <button mat-button (click)="onClose()">Ne</button>
</div>

