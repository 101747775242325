<form [formGroup]="projectForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="create-project">
        <mat-card-header>
            <mat-card-title>{{!isEdit() ? "Nový projekt" : "Upravit projekt ( id: " + editProjectId + " , gitlab id: "}}
                <span *ngIf="gitlabProjectURL || editGitlabProject">
                    <mat-form-field>
                        <input matInput formControlName="gitlabId" value="{{gitlabProjectID}}">
                        <mat-error *ngIf="projectForm.controls['gitlabId'].hasError('pattern')">
                            GitlabId nemůže být <strong>nečíselný</strong>
                        </mat-error>
                    </mat-form-field>
                        <a href="{{gitlabProjectURL}}" target="_blank" *ngIf="gitlabProjectURL">
                            <mat-icon matListIcon id="newWindowButton" class="hand-on-hover"
                                      title="Zobrazit repozitář na Gitlabu">open_in_new</mat-icon>
                        </a>
                    {{")"}}
                </span>
                <span *ngIf="isEdit() && !gitlabProjectURL && !editGitlabProject">
                   <mat-icon matListIcon class="hand-on-hover" style="vertical-align: middle"
                             (click)="editGitlabProject = true"
                             title="Přidat Gitlab repozitář">add_box</mat-icon>
                    {{")"}}
                </span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-bigger">
                    <!--name-->
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Název" formControlName="name">
                        <mat-error *ngIf="projectForm.controls['name'].hasError('required')">
                            Název nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error *ngIf="projectForm.controls['name'].hasError('maxlength')">
                            Název je <strong>příliš dlouhý</strong> (max. 100 znaků)
                        </mat-error>
                        <mat-error *ngIf="projectForm.controls['name'].hasError('pattern')">
                            Název <strong>musí</strong> začínat písmenem a <strong>musí</strong> být tvořeno kombinací
                            písmen, čísel, mezer a podtržítek
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--course-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Předmět" formControlName="course">
                            <mat-option *ngFor="let course of courses" [value]="course.name">
                                {{ course.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="projectForm.controls['course'].hasError('required')">
                            Předmět nemůže být <strong>prázdný</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--description-->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <textarea matInput placeholder="Popis" formControlName="description" rows="6"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <!--leader-->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input type="text" placeholder="Vedoucí projektu" matInput [matAutocomplete]="leader"
                               [formControl]="leaderControl" (click)="$event;resetLeaderFilter()">
                        <mat-autocomplete #leader="matAutocomplete">
                            <mat-option *ngFor="let leader of filteredLeaders | async" [value]="leader.name"
                                        (click)="selectLeader(leader)">
                                <div>
                                    {{ leader.name}}
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="projectForm.controls['leader'].hasError('required')">
                            Vedoucí projektu nemůže být <strong>prázdný</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--students   *ngFor="let student of people"   -->
            <div *ngIf="!project || !project.archive">
                <mat-form-field class="full-width">
                    <input type="text" placeholder="Vybrat studenty" matInput [matAutocomplete]="student"
                           [formControl]="studentControl">
                    <mat-autocomplete #student="matAutocomplete">
                        <ng-container *ngFor="let student of filteredStudents | async">
                            <mat-option *ngIf="!student.selected" [value]="student.id"
                                        (click)="studentClicked($event, student)">
                                <div>
                                    {{ student.name }}
                                </div>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
            </div>


            <mat-label>Studenti:</mat-label>
            <mat-icon matListIcon id="mailButton" title="Poslat email všem studentům"
                      (click)="onMailAllStudentsClick()">mail
            </mat-icon>
            <mat-list dense>
               <mat-list-item *ngIf="selectedStudents?.length === 0">Nebyl vybrán žádný student.</mat-list-item>
                <mat-list-item *ngFor="let student of selectedStudents">
                 <!--   <mat-icon matListIcon>fiber_manual_record</mat-icon> -->
                    <div>{{ student.name }} &nbsp;({{ student.email }})
                        <mat-icon matListIcon class="hand-on-hover" title="Upravit uživatele"
                                  [routerLink]="['/person/' + student.id]">settings
                        </mat-icon>
                        <mat-icon matListIcon class="hand-on-hover" *ngIf="isEdit()"
                                  title="Poslat přihlašovací údaje studentovi"
                                  (click)="sendProjectAccessStudent(project.id, student.id)">contact_mail
                        </mat-icon>
                        <mat-icon matListIcon class="hand-on-hover" title="Odstranit studenta"
                                  (click)="studentClicked($event, student)">delete
                        </mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>
               <button [disabled]="(projectForm.controls['editProjectId'].value) && projectForm.controls['archive'].value"
                       mat-raised-button color="primary" type="button"
                       (click)="onCreateStudentClick()">
                   {{ createPersonRendered ? 'Skrýt' : 'Vytvořit studenta' }}
               </button>
               <div style="display: flex; flex-direction: column; margin: 5px 0;" *ngIf="!isEdit()">
                   <mat-checkbox
                       (change)="changeStatusContainer()"
                       [checked]="isCheckedContainer"><span
                       style="font-size: 17px;">Vytvořit kontejner po vytvoření projektu</span></mat-checkbox>
                   <mat-checkbox
                       (change)="changeStatusSQ()"
                       [checked]="isCheckedSQ"><span
                       style="font-size: 17px;">Vytvořit SQ po vytvoření projektu</span></mat-checkbox>
               </div>
               <app-person *ngIf="this.createPersonRendered"
                           (personCreatedEmitter)="this.onStudentCreated($event)"></app-person>
               <div *ngIf="isEdit()">
                   <br><br>
                   <mat-label>Přidružené databáze:</mat-label>
                   <mat-list dense>
                       <mat-list-item>
                           <div *ngIf="dbEAname !== ''">
                               <button mat-raised-button type="button" (click)="redirectToDatabase(this.dbEAId)">
                                   EA: {{dbEAname}}
                               </button>
                           </div>
                           <div *ngIf="dbEAname === ''">
                               EA:
                               <button
                                   [disabled]="projectForm.controls['editProjectId'].value && projectForm.controls['archive'].value"
                                   mat-raised-button type="button"
                                   color="primary" (click)="createEaDatabase(project)">Vytvořit
                               </button>
                           </div>
                       </mat-list-item>
                       <mat-list-item>
                           <div *ngIf="dbProjectId">
                               <button
                                   mat-raised-button (click)="redirectToDatabase(project.databaseProject.id)">
                                   Projektová: {{dbProjectName}}
                               </button>
                           </div>
                           <div *ngIf="dbProjectName === ''  && dbProjectId === null">
                               Projektová:
                               <button
                                   [disabled]="projectForm.controls['editProjectId'].value && projectForm.controls['archive'].value"
                                   mat-raised-button type="button"
                                   color="primary" (click)="createAdditionalDatabase(project)">Vytvořit
                               </button>
                           </div>
                       </mat-list-item>
                   </mat-list>
               </div>
           </mat-card-content>
           <mat-card-actions>
               <button style="margin-right: 5px;" [disabled]="!projectForm.valid || isLoading" mat-raised-button color="primary"
                       type="submit">{{editProjectId == null ? "Vytvořit" : "Uložit"}}</button>

               <button *ngIf="editProjectId && !projectForm.controls['archive'].value" mat-raised-button color="primary"
                       (click)="archive(project.id)" type="button">Archivovat
               </button>
               <button *ngIf="projectForm.controls['archive'].value" mat-raised-button color="primary"
                       (click)="dearchive(project.id)" type="button">Dearchivovat
               </button>
           </mat-card-actions>
       </mat-card>
   </form>
   <mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
