const TOKEN_KEY = 'AuthToken';
const BUG_REPORT_URL_KEY = 'BugReportUrl'

export class AppStorage {


    public static setToken(token: string) {
        AppStorage.set(TOKEN_KEY, token);
    }

    public static getToken(): string {
        return AppStorage.get(TOKEN_KEY);
    }

    public static setBugReportUrl(url: string) {
        AppStorage.set(BUG_REPORT_URL_KEY, url);
    }

    public static getBugReportUrl(): string {
        return AppStorage.get(BUG_REPORT_URL_KEY);
    }

    public static getTokenOrDefault(defVal: string): string {
        return AppStorage.getOrDefault(TOKEN_KEY, defVal)
    }

    static removeToken() {
        AppStorage.remove(TOKEN_KEY);
    }

    private static getOrDefault(key: string, defVal) {
        const val = AppStorage.get(key);
        if (!!val) {
            return val
        } else {
            return defVal
        }
    }

    public static getObject(key: string) {
        return JSON.parse(AppStorage.get(key))
    }

    public static setObject(key: string, obj) {
        sessionStorage.setItem(key, JSON.stringify(obj))
    }

    public static get(key: string) {
        return sessionStorage.getItem(key)
    }

    public static set(key: string, val) {
        sessionStorage.setItem(key, val)
    }


    public static remove(key: string) {
        sessionStorage.removeItem(key)
    }

    public static clear() {
        console.log('Clearing storage.')
        const bugReportUrl = AppStorage.getBugReportUrl();
        sessionStorage.clear();
        AppStorage.setBugReportUrl(bugReportUrl);
    }
}
