<h1 mat-dialog-title>Přidat tag k projektu</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 350px">
        <input type="text" placeholder="Napište nebo vyberte tag" matInput [matAutocomplete]="tag"
               [formControl]="tagControl" (click)="$event.target.select();">
        <mat-autocomplete #tag="matAutocomplete">
            <mat-option *ngFor="let tag of filteredUserTags | async" [value]="tag"
                        (change)="selectTag(tag)"
                        (click)="$event.stopPropagation()">
                <div id="optionTag" (click)="tagClicked($event, tag)">
                    {{ tag }}
                </div>
                <mat-icon id="deleteTag" (click)="deleteUserTag(tag)" title="Smazat">clear</mat-icon>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <button mat-raised-button (click)="addUserTag(tagControl.value);" style="margin-left: 20px">Zapamatovat si tag</button>
</div>
<br>
<div mat-dialog-actions style="margin-left: 375px">
    <button mat-raised-button (click)="onNoClick()">Zrušit</button>
    <button mat-raised-button [mat-dialog-close]="tagControl.value" cdkFocusInitial>Přidat</button>
</div>
