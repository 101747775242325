<div mat-dialog-content>
    <div class="uploadFileButton" >
        <button type="button" mat-raised-button (click)="fileInput.click()">Vybrat soubor</button>
    </div>
    <input class="fileName" matInput [(ngModel)]="fileName" readonly name="name" />

    <input hidden (change)="onFileSelected($event)" #fileInput type="file">
    <mat-form-field class="message-input" >
        <input matInput [(ngModel)]="backupMessage" placeholder="Backup message">
    </mat-form-field>
</div>
<div mat-dialog-actions class="modal-buttons">
    <button [disabled]="!this.readyToDeploy || !this.backupMessage" mat-button (click)="onSubmit()">Nahrát</button>
    <button mat-button (click)="onClose()">Zrušit</button>
</div>
