<div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="databaseName">
            <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Název databáze</th>
            <td mat-cell *matCellDef="let db"> {{db}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>
<div mat-dialog-actions class="modal-buttons center">
    <button mat-button (click)="onClose()">Zavřít</button>
</div>
