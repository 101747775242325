import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-odbc-export-modal',
    templateUrl: './odbc-export-modal.component.html',
    styleUrls: ['./odbc-export-modal.component.css']
})
export class OdbcExportComponent implements OnInit {
    dbRole: string = '';
    dbPassword: string = '';
    alternativeCredentials: boolean = false;

    constructor(public dialogRef: MatDialogRef<OdbcExportComponent>,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        // do nothing.
    }

    onSubmit() {
        this.notificationService.notify("Začíná export.")
        this.dialogRef.close({
            alternativeCredentials: this.alternativeCredentials,
            dbRole: this.dbRole,
            dbPassword: this.dbPassword
        });


    }

    onClose() {
        this.dialogRef.close();
    }
}
