import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationService} from "./notification.service";
import {AppStorage} from './app.storage';
import {AuthenticationService} from "./authentication.service";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private notificationService: NotificationService,
                private authService: AuthenticationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = AppStorage.getToken();
        if (token !== null && !JwtInterceptor.tokenExpired(token)) {
            req = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});
            // because of multipart file, content type cannot be automatically set
            // so the browser sets it automatically
            // req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
            // console.log(req.headers.get('Content-type'))
        } else if (token !== null && JwtInterceptor.tokenExpired(token)) {
            AppStorage.removeToken();
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            return event;
        }, error => {
            if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 200)) {
                // unauthorized shouldn't happen outside re-logging
                // getting new token automatically might be nice
                console.log('unauthorized - redirect to login', error);
                this.authService.logout(false);
                this.notificationService.notifyError('Byli jste automaticky odhlášeni, přihlašte se prosím znovu.');
                this.router.navigate(['login']);
            }
        }));
    }

    private static tokenExpired(token: string): boolean {
        if (token === null) {
            return false;
        }
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
}
