<mat-card class="detail-database">
    <mat-card-header>
        <mat-card-title>Detail databáze</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="infoRow">
            <div class="row">
                <div class="col">
                    <mat-label class="label">ID:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Název:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Host:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Vytvořeno:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Archivováno:</mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-label *ngIf="databaseData">{{ databaseData.database.id }}</mat-label>
                </div>
                <div class="col">
                    <mat-label *ngIf="databaseData">{{ databaseData.database.name }}</mat-label>
                </div>
                <div class="col">
                    <mat-label *ngIf="databaseData">{{ databaseData.database.host }}</mat-label>
                </div>
                <div class="col">
                    <mat-label
                        *ngIf="databaseData">{{ databaseData.database.whenCreated | date: dateViewFormat }}</mat-label>
                </div>
                <div class="col">
                    <mat-label *ngIf="databaseData">{{ databaseData.database.isArchived ? 'Ano' : 'Ne' }}</mat-label>
                </div>
            </div>
        </div>
        <div class="infoRow">
            <div class="row">
                <div class="col">
                    <mat-label class="label">Verze:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Pravidlo zálohování:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Naposled upraveno:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Projekt:</mat-label>
                </div>
                <div class="col">
                    <mat-label class="label">Typ databáze:</mat-label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-label *ngIf="databaseData">{{ databaseData.database.version }}</mat-label>
                </div>
                <div class="col">
                    <mat-label
                        *ngIf="databaseData && databaseData.database.backupRule">{{ databaseData.database.backupRule.name }}</mat-label>
                </div>
                <div class="col">
                    <mat-label
                        *ngIf="databaseData">{{ databaseData.database.whenModified | date: dateViewFormat }}</mat-label>
                </div>
                <div class="col">
                    <mat-label *ngIf="databaseData">
                        <button mat-raised-button *ngIf="databaseData.project"
                                (click)="redirectToProject(databaseData.project.id)">
                            {{databaseData.project.name}} {{databaseData.project.id ? '(' + databaseData.project.id + ')' : ''}}
                        </button>
                    </mat-label>
                </div>
                <div class="col">
                    <mat-label *ngIf="databaseData">
                        {{ getDatabaseType(databaseData.database.name) }}
                    </mat-label>
                </div>
            </div>
        </div>

        <mat-card-header>
            <mat-card-title>Zálohy</mat-card-title>
        </mat-card-header>
        <button class="backup-button" mat-raised-button (click)="setBackupMessage(this.databaseId)">Vytvořit zálohu</button>
        <button class="backup-button" mat-raised-button (click)="uploadBackup(this.databaseId)">Nahrát zálohu</button>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="backupId">
                <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let backup"> {{backup.backupId}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell style="width: 7%;" *matHeaderCellDef mat-sort-header> Popis</th>
                <td mat-cell *matCellDef="let backup"> {{backup.description}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="whenCreated">
                <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Vytvořeno</th>
                <td mat-cell *matCellDef="let backup"> {{backup.whenCreated}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            //
            <ng-container matColumnDef="selected">
                <th mat-header-cell style="text-align: center; vertical-align: middle; width: 4%;" *matHeaderCellDef>
                    <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                                  [checked]="selectedBackups.size === dataSource.data.length && dataSource.data.length !== 0">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let backup" style="text-align: center; vertical-align: middle;">
                    <mat-checkbox (change)="switchSelected(backup)" [checked]="backup.selected"></mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
                    <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                                  [checked]="selectedBackups.size === dataSource.data.length && dataSource.data.length !== 0">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell style="width: 3%;" *matHeaderCellDef>
                    <button mat-raised-button [disabled]="selectedBackups.size === 0"
                            [matMenuTriggerFor]="actionMenuTop">
                        Akce
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #actionMenuTop="matMenu">
                        <button mat-menu-item (click)="downloadSelected(-1)">
                            Stáhnout vybrané
                        </button>
                        <button mat-menu-item (click)="deleteSelected(-1)">
                            Smazat vybrané
                        </button>
                    </mat-menu>
                </th>
                <td mat-cell *matCellDef="let backup" style="overflow: visible;">
                    <button mat-raised-button [matMenuTriggerFor]="actionMenu">
                        Akce
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #actionMenu="matMenu">
                        <button mat-menu-item (click)="restoreSelected(backup.backupId)">Obnovit</button>
                        <button mat-menu-item (click)="downloadSelected(backup.backupId)">Stáhnout
                        </button>
                        <button mat-menu-item (click)="deleteSelected(backup.backupId)">Smazat</button>
                    </mat-menu>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <button mat-raised-button [disabled]="selectedBackups.size === 0"
                            [matMenuTriggerFor]="actionMenuBottom">
                        Akce
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #actionMenuBottom="matMenu">
                        <button mat-menu-item (click)="restoreSelected(-1)">
                            Obnovit vybrané
                        </button>
                        <button mat-menu-item (click)="downloadSelected(-1)">
                            Stáhnout vybrané
                        </button>
                        <button mat-menu-item (click)="deleteSelected(-1)">
                            Smazat vybrané
                        </button>
                    </mat-menu>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

    </mat-card-content>
</mat-card>

