<div mat-dialog-content class="center" *ngIf="data.isContainerInstance">
    <p class="center">Opravdu chcete smazat vybranou instanci?
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerInstances">
    <p class="center">Opravdu chcete smazat vybrané instance?
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-actions class="modal-buttons center">
    <button mat-button (click)="onSubmit()">Ano</button>
    <button mat-button (click)="onClose()">Ne</button>
</div>

