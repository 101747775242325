import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

export interface ModalData {
    isCertificate: boolean;
    certificate: string;
}

@Component({
    selector: 'app-certificate-modal',
    templateUrl: './certificate-modal.component.html',
    styleUrls: ['./certificate-modal.component.css']
})
export class CertificateModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<CertificateModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ModalData) {
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close();
    }
}
