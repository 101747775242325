<form [formGroup]="configurationFileForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'Upravit konfiguraci serveru: ' + showName()}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field class="full-width">
                <textarea class="textarea"
                          onkeydown="this.autosize()"
                          matInput
                          formControlName="text"
                          value={{showContent()}}>
                </textarea>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!configurationFileForm.valid" mat-raised-button color="primary"
                    type="submit">Uložit</button>

        </mat-card-actions>

    </mat-card>
</form>
