<mat-tab-group id="list-setup" [selectedIndex]="activeTab" (selectedTabChange)="onTabChange($event)"
               *ngIf="!isLoading">
    <mat-tab label="SonarQube server">
        <form [formGroup]="sonarqubeForm" novalidate (ngSubmit)="onSubmit('sonarqube')" autocomplete="new-password">
            <mat-card id="setup-sonarqube">
                <mat-card-content>
                    <div class="row">
                        <!-- URL -->
                        <div class="col">
                            <mat-label>URL:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="url" required>
                            </mat-form-field>
                        </div>
                        <!-- Port -->
                        <div class="col">
                            <mat-label>Port:</mat-label>
                            <mat-form-field class="full-width">

                                <input matInput formControlName="port" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Username -->
                        <div class="col">
                            <mat-label>Username:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="username" required>
                            </mat-form-field>
                        </div>
                        <!-- Password -->
                        <div class="col">
                            <mat-label>Password:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput type="password" formControlName="password" required>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button [disabled]="!sonarqubeForm.valid || isLoading" mat-raised-button color="primary" type="submit">Uložit</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
    <mat-tab label="Email server">
        <form [formGroup]="emailForm" novalidate (ngSubmit)="onSubmit('email')" autocomplete="new-password">
            <mat-card id="setup-email">
                <mat-card-content>
                    <div class="row">
                        <!-- Email -->
                        <div class="col">
                            <mat-label>Email:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="email" required>
                            </mat-form-field>
                        </div>
                        <!-- Port -->
                        <div class="col">
                            <mat-label>Port:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="port" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Host -->
                        <div class="col">
                            <mat-label>Host:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="host" required>
                            </mat-form-field>
                        </div>
                        <!-- Password -->
                        <div class="col">
                            <mat-label>Password:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput type="password" formControlName="password" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Auth -->
                        <div class="col">
                            <mat-label>Auth:</mat-label>
                            <mat-form-field class="full-width">
                                <mat-select [value]="emailForm.get('auth').value" formControlName="auth" required>
                                    <mat-option [value]="true">Ano</mat-option>
                                    <mat-option [value]="false">Nie</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- Protocol Class -->
                        <div class="col">
                            <mat-label>Protocol Class:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="protocolClass" required>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button [disabled]="!emailForm.valid || isLoading" mat-raised-button
                            color="primary"
                            type="submit">Uložit
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
    <mat-tab label="Gitlab server">
        <form [formGroup]="gitlabForm" novalidate (ngSubmit)="onSubmit('gitlab')" autocomplete="new-password">
            <mat-card id="setup-gitlab">
                <mat-card-content>
                    <div class="row">
                        <!-- gitlab_url -->
                        <div class="col">
                            <mat-label>Gitlab url:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="gitlabUrl" required>
                            </mat-form-field>
                        </div>
                        <!-- gitlab_API_version -->
                        <div class="col">
                            <mat-label>Gitlab url:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="gitlabApiVersion" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- members -->
                        <div class="col">
                            <mat-label>Members:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="members" required>
                            </mat-form-field>
                        </div>
                        <!-- projects -->
                        <div class="col">
                            <mat-label>Projects:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="projects" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- paging -->
                        <div class="col">
                            <mat-label>Paging:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="paging" required>
                            </mat-form-field>
                        </div>
                        <!-- fit_email_domain -->
                        <div class="col">
                            <mat-label>Fit email domain:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="fitEmailDomain" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- search_projects -->
                        <div class="col">
                            <mat-label>Search projects:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="searchProjects" required>
                            </mat-form-field>
                        </div>
                        <!-- and_sign -->
                        <div class="col">
                            <mat-label>And sign:</mat-label>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="andSign" required>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button [disabled]="!gitlabForm.valid || isLoading" mat-raised-button
                            color="primary"
                            type="submit">Uložit
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
    <mat-tab *ngIf="isAdmin" label="Docker server">
        <form [formGroup]="serverConfigurationForm" novalidate (ngSubmit)="onSubmit('server_configuration')" autocomplete="new-password">
            <mat-card id="edit-server-configuration">
<!--                <mat-card-header>-->
<!--                    <mat-card-title>Upravit konfiguraci serveru:</mat-card-title>-->
<!--                </mat-card-header>-->
                <mat-card-content>
                    <div class="row" style="margin-bottom: 20px;">
                        <!-- IP address -->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">IP adresa</span>
                                <mat-icon matSuffix matTooltip="IP adresa serveru, kde běží docker.">info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit IP adresu" formControlName="ipAddress">
                                <mat-error *ngIf="serverConfigurationForm.controls['ipAddress'].hasError('required')">
                                    IP adresa nemůže být <strong>prázdná</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Min port -->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Minimální port</span>
                                <mat-icon matSuffix
                                          matTooltip="Spodní hranice rozmezí portů, které můžou být přidělené kontejnerům na serveru.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit minimální port" formControlName="containerPortMin">
                                <mat-error *ngIf="serverConfigurationForm.controls['containerPortMin'].hasError('required')">
                                    Minimální port nemůže být <strong>prázdný</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['containerPortMin'].hasError('min')">
                                    Minimální port musí být větší nebo roven <strong>1024</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['containerPortMin'].hasError('max')">
                                    Minimální port musí být menší nebo roven <strong>49151</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Max port -->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Maximální port</span>
                                <mat-icon matSuffix
                                          matTooltip="Horní hranice rozmezí portů, které můžou být přidělené kontejnerům na serveru.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit maximální port" formControlName="containerPortMax">
                                <mat-error *ngIf="serverConfigurationForm.controls['containerPortMax'].hasError('required')">
                                    Maximální port nemůže být <strong>prázdný</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['containerPortMax'].hasError('min')">
                                    Maximální port musí být větší nebo roven <strong>1024</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['containerPortMax'].hasError('max')">
                                    Maximální port musí být menší nebo roven <strong>49151</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <!-- SSH port -->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">SSH port</span>
                                <mat-icon matSuffix matTooltip="Port pro komunikaci mezi aplikací a docker serverem přes SSH.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit server port" formControlName="serverPortSSH">
                                <mat-error *ngIf="serverConfigurationForm.controls['serverPortSSH'].hasError('required')">
                                    SSH port nemůže být <strong>prázdný</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['serverPortSSH'].hasError('min')">
                                    SSH port musí být větší nebo roven <strong>22</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['serverPortSSH'].hasError('max')">
                                    SSH port musí být menší nebo roven <strong>65535</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Docker port -->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Docker port</span>
                                <mat-icon matSuffix
                                          matTooltip="Port, na kterém naslouchá docker na serveru pro komunikaci přes TLS.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit docker port" formControlName="serverPortDocker">
                                <mat-error *ngIf="serverConfigurationForm.controls['serverPortDocker'].hasError('required')">
                                    Docker port nemůže být <strong>prázdný</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['serverPortDocker'].hasError('min')">
                                    Docker port musí být větší nebo roven <strong>0</strong>
                                </mat-error>
                                <mat-error *ngIf="serverConfigurationForm.controls['serverPortDocker'].hasError('max')">
                                    Docker port musí být menší nebo roven <strong>65535</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Timeout -->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                        <span style="margin-right: 5px;" class="label-text">
                        Timeout (sec)</span>
                                <mat-icon matSuffix
                                          matTooltip="Timeout pro připojení k serveru v sekundách.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit timeout" formControlName="timeoutSec">
                                <mat-error *ngIf="serverConfigurationForm.controls['timeoutSec'].hasError('required')">
                                    Timeout nemůže být <strong>prázdný</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--certification authority certificate-->
                    <div class="row">
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Certifikát certifikační autority</span>
                                <mat-icon matSuffix
                                          matTooltip="Certifikát autority, která podepsala certifikáty serveru a klienta.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <textarea matInput placeholder="Změnit certifikát certifikační autority" formControlName="certificationAuthorityCertificate" rows="5"></textarea>
                                <mat-error
                                    *ngIf="serverConfigurationForm.controls['certificationAuthorityCertificate'].hasError('required')">
                                    Certification authority certificate nemůže být <strong>prázdný</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--certificate-->
                    <div class="row">
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Certifikát klienta</span>
                                <mat-icon matSuffix
                                          matTooltip="Certifikát klienta podepsaný zadanou certifikační autoritou.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <textarea matInput placeholder="Změnit certifikát klienta" formControlName="certificate" rows="5"></textarea>
                                <mat-error *ngIf="serverConfigurationForm.controls['certificate'].hasError('required')">
                                    Certificate nemůže být <strong>prázdný</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--public key-->
                    <div class="row">
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Klíč klienta</span>
                                <mat-icon matSuffix
                                          matTooltip="Klíč, ze kterého byl vygenerován certifikát klienta.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <textarea matInput placeholder="Změnit klíč klienta" formControlName="publicKey" rows="5"></textarea>
                                <mat-error *ngIf="serverConfigurationForm.controls['publicKey'].hasError('required')">
                                    Public key nemůže být <strong>prázdný</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!--username-->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Uživatelské jméno</span>
                                <mat-icon matSuffix
                                          matTooltip="Jméno uživatele s oprávněním sudo, pod kterým se aplikace může připojit k serveru.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Změnit uživatelské jméno" formControlName="username">
                                <mat-error *ngIf="serverConfigurationForm.controls['username'].hasError('required')">
                                    Uživatelské jméno nemůže být <strong>prázdné</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--password-->
                        <div class="col">
                            <mat-label style="display: flex; flex-direction: row; align-items: center;">
                                <span style="margin-right: 5px;" class="label-text">Heslo</span>
                                <mat-icon matSuffix
                                          matTooltip="Heslo k zadanému uživatelskému účtu na serveru, pod kterým se aplikace může přihlašovat k serveru.">
                                    info
                                </mat-icon>
                            </mat-label>
                            <mat-form-field class="full-width">
                                <input type="password" matInput placeholder="Změnit heslo" formControlName="password">
                                <mat-error *ngIf="serverConfigurationForm.controls['password'].hasError('maxlength')">
                                    Heslo je <strong>příliš dlouhé</strong> (max. 71 znaků)
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button [disabled]="!serverConfigurationForm.valid" mat-raised-button color="primary"
                            type="submit">Uložit
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
</mat-tab-group>
<mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
<mat-spinner class="loading" *ngIf="isLoadingAction"></mat-spinner>


