import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

export interface ModalData {
    isPublicKey: boolean;
    publicKey: string;
}

@Component({
    selector: 'app-public-key-modal',
    templateUrl: './public-key-modal.component.html',
    styleUrls: ['./public-key-modal.component.css']
})
export class PublicKeyModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<PublicKeyModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ModalData) {
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close();
    }
}
