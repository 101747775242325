import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PsqlNavbarComponent} from './psql-navbar/psql-navbar.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatModules} from './export/mat-modules';
import {RouterModule} from '@angular/router';
import {routes} from './export/routes';
import {ProjectComponent} from './project/project.component';
import {PersonComponent} from './person/person.component';
import {ListPeopleComponent} from './list-people/list-people.component';
import {ListProjectsComponent} from './list-projects/list-projects.component';
import {ListDatabasesComponent} from './list-databases/list-databases.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {JwtInterceptor} from './services/jwt.interceptor';
import {CommonModule, DatePipe} from '@angular/common';
import {GitlabImportComponent} from './gitlab-import/gitlab-import.component';
import {GitlabImportPreviewComponent} from './gitlab-import/gitlab-import-preview/gitlab-import-preview.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {ManageTagsComponent} from './manage-tags/manage-tags.component';
import {ListBackupsComponent} from './list-backups/list-backups.component';
import {CommitMessageModalComponent} from './list-databases/commit-message-modal/commit-message-modal.component';
import {ArchiveModalComponent} from './project/archive-modal/archive-modal.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {WarningModalComponent} from './list-backups/warning-modal/warning-modal.component';
import {BackupRulesComponent} from './backup-rules/backup-rules.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DatabaseListComponent} from './backup-rules/database-list/database-list.component';
import {DetailDatabaseComponent} from './detail-database/detail-database.component';
import {CanDeactivateGuard} from "./services/can-deactivate.guard";
import {BugReportSnackBar} from "./bug-report-snackbar/bug-report-snackbar.component";
import {OdbcExportComponent} from "./list-projects/odbc-export-modal/odbc-export-modal.component";
import {BackupUploadModalComponent} from './list-databases/backup-upload-modal/backup-upload-modal.component';
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {ListContainersComponent} from './list-containers/list-containers.component';
import {ContainerInstanceComponent} from "./list-containers/container-instance-form/container-instance.component";
import {ContainerTemplateComponent} from "./list-containers/container-template-form/container-template.component";
import {
    ContainerConfigurationFileComponent
} from "./list-containers/container-configuration-file-form/container-configuration-file.component";
import {ListTemplatesComponent} from "./list-email-templates/list-templates.component";
import {EditTemplateComponent} from "./edit-email-template/edit-template.component";
import {SonarqubeComponent} from './sonarqube/sonarqube.component';
import {WarningModalComponentSQ} from './sonarqube/warning-modal/warning-modal.component';
import {EditConfigurationFileComponent} from "./edit-configuration-file/edit-configuration-file.component";
import {LoadingComponent} from './sonarqube/loading/loading.component';
import {
    CertificationConfigurationCertificateModalComponent
} from "./list-containers/certification-authority-certificate-modal/certification-configuration-certificate-modal.component";
import {CertificateModalComponent} from "./list-containers/certificate-modal/certificate-modal.component";
import {PublicKeyModalComponent} from "./list-containers/public-key-modal/public-key-modal.component";
import {CreateContainerModalComponent} from "./list-projects/create-container-modal/create-container-modal.component";
import {NotifierModule} from "angular-notifier";
import {
    DeleteContainerModalComponent
} from "./list-containers/delete-container-modal/delete-container-modal.component";
import {
    DeleteRunningContainerModalComponent
} from "./list-containers/delete-running-container-modal/delete-running-container-modal.component";
import {PortsMappingModalComponent} from "./list-containers/ports-mapping-modal/ports-mapping-modal.component";
import {
    ProjectContainerInstanceComponent
} from "./project/project-container-instance-form/project-container-instance.component";
import {
    ShowContainerConfigurationFileComponent
} from "./list-containers/show-container-configuration-file-form/show-container-configuration-file.component";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {ListSetupComponent} from "./list-setup/list-setup.component";


@NgModule({
    declarations: [
        AppComponent,
        PsqlNavbarComponent,
        ProjectComponent,
        PersonComponent,
        EditTemplateComponent,
        ListTemplatesComponent,
        ListProjectsComponent,
        ListPeopleComponent,
        ListDatabasesComponent,
        LoginComponent,
        GitlabImportComponent,
        GitlabImportPreviewComponent,
        ManageTagsComponent,
        ListBackupsComponent,
        CommitMessageModalComponent,
        ArchiveModalComponent,
        BackupRulesComponent,
        DatabaseListComponent,
        WarningModalComponent,
        DetailDatabaseComponent,
        BugReportSnackBar,
        OdbcExportComponent,
        BackupUploadModalComponent,
        ListContainersComponent,
        ListSetupComponent,
        ContainerInstanceComponent,
        ContainerTemplateComponent,
        ContainerConfigurationFileComponent,
        SonarqubeComponent,
        WarningModalComponentSQ,
        EditConfigurationFileComponent,
        LoadingComponent,
        CertificationConfigurationCertificateModalComponent,
        CertificateModalComponent,
        PublicKeyModalComponent,
        CreateContainerModalComponent,
        DeleteContainerModalComponent,
        DeleteRunningContainerModalComponent,
        PortsMappingModalComponent,
        ProjectContainerInstanceComponent,
        ShowContainerConfigurationFileComponent
    ],
    imports: [
        RouterModule.forRoot(routes, {}),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        ...MatModules,
        MatSortModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgScrollbarModule,
        NgxMaterialTimepickerModule,
        MatLegacyChipsModule,
        NotifierModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        MatDatepickerModule,
        DatePipe,
        CanDeactivateGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
