<div mat-dialog-content>
    <p class="center">Defaultně se pro přihlašovací údaje do ODBC připojení použijí údaje z Vašeho profilu (DB Role DB
        Heslo).</p>
    <p class="center">Alternativně můžete zadat jiné přihlašovací údaje.</p>
    <p class="center" style="color: red">V exportovaném skriptu bude heslo uloženo v čitelné podobě!</p>
    <mat-checkbox [(ngModel)]="alternativeCredentials" class="center">
        Použít jiné údaje
    </mat-checkbox>

    <mat-form-field class="center">
        <input matInput [(ngModel)]="dbRole" [disabled]="!alternativeCredentials"
               [required]="alternativeCredentials" placeholder="Databázová role">
    </mat-form-field>
    <mat-form-field class="center">
        <input matInput [(ngModel)]="dbPassword" [disabled]="!alternativeCredentials"
               [required]="alternativeCredentials" placeholder="Databázové heslo" type="password">
    </mat-form-field>
</div>
<div mat-dialog-actions class="center">
    <button mat-button (click)="onSubmit()" [disabled]="alternativeCredentials && (dbRole === '' || dbPassword === '')">
        Exportovat
    </button>
    <button mat-button (click)="onClose()">Zrušit</button>
</div>

