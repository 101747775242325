import {ProjectComponent} from '../project/project.component';
import {PersonComponent} from '../person/person.component';
import {ListProjectsComponent} from '../list-projects/list-projects.component';
import {ListDatabasesComponent} from '../list-databases/list-databases.component';
import {ListPeopleComponent} from '../list-people/list-people.component';
import {AuthGuard} from '../services/auth.guard';
import {LoginComponent} from '../login/login.component';
import {GitlabImportComponent} from '../gitlab-import/gitlab-import.component';
import {ListBackupsComponent} from '../list-backups/list-backups.component';
import {ListContainersComponent} from "../list-containers/list-containers.component";
import {BackupRulesComponent} from '../backup-rules/backup-rules.component';
import {DetailDatabaseComponent} from '../detail-database/detail-database.component';
import {CanDeactivateGuard} from "../services/can-deactivate.guard";
import {Route} from "@angular/router";
import {Routes} from "@angular/router";
import {ContainerInstanceComponent} from "../list-containers/container-instance-form/container-instance.component";
import {ContainerTemplateComponent} from "../list-containers/container-template-form/container-template.component";
import {ContainerConfigurationFileComponent} from "../list-containers/container-configuration-file-form/container-configuration-file.component";
import {SonarqubeComponent} from "../sonarqube/sonarqube.component";
import { ListTemplatesComponent } from "../list-email-templates/list-templates.component";
import {EditTemplateComponent} from "../edit-email-template/edit-template.component";
import {
    ProjectContainerInstanceComponent
} from "../project/project-container-instance-form/project-container-instance.component";
import {
    ShowContainerConfigurationFileComponent
} from "../list-containers/show-container-configuration-file-form/show-container-configuration-file.component";
import {AdminGuard} from "../services/admin.guard";
import {ListSetupComponent} from "../list-setup/list-setup.component";

interface CustomRoute extends Route {
    splitter?: boolean;
    name?: string;
}




/**
 * Slight hack to pass a route that doesn't do anything new.
 * It's purpose is to pass an object among other routes
 * that will indicate to render a line break between other routes.
 */
const splitterRoute: CustomRoute = {
    // indicates to render a html break
    splitter: true,
    // necessary parts to be a valid route to be registered by angular
    path: '',
    redirectTo: 'list-projects',
    //component: LoginComponent,
    pathMatch: 'full'
}

export const routes: Routes = [
    {
        name: 'Seznam projektů',
        path: 'list-projects',
        component: ListProjectsComponent,
        canActivate: [AuthGuard]
    },
    {
        name: 'Vytvořit projekt',
        path: 'create-project',
        component: ProjectComponent,
        canActivate: [AuthGuard]
    },
    {
        name: 'Importovat z gitlabu',
        path: 'import/gitlab',
        component: GitlabImportComponent,
        canActivate: [AuthGuard]
    },
    splitterRoute,
    {
        name: 'Seznam osob',
        path: 'list-people',
        component: ListPeopleComponent,
        canActivate: [AuthGuard]
    },
    {
        name: 'Vytvořit osobu',
        path: 'create-person',
        component: PersonComponent,
        canActivate: [AuthGuard]
    },
    splitterRoute,
    {
        name: 'Správa SonarQube',
        path: 'sonarqube',
        component: SonarqubeComponent,
        canActivate: [AuthGuard]
    },
    splitterRoute,
    {
        name: 'Emailové šablony',
        path: 'list-templates',
        component: ListTemplatesComponent,
        canActivate: [AdminGuard]
    },
    splitterRoute,
    {
        name: 'Seznam databází',
        path: 'list-database',
        component: ListDatabasesComponent,
        canActivate: [AuthGuard]
    },
    {
        name: 'Seznam záloh',
        path: 'list-backups',
        component: ListBackupsComponent,
        canActivate: [AuthGuard]
    },
    {
        name: 'Pravidla zálohování',
        path: 'backup-rules',
        component: BackupRulesComponent,
        canActivate: [AuthGuard]
    },
    {
        name: 'Kontejnery',
        path: 'list-containers/:tab',
        component: ListContainersComponent,
        canActivate: [AuthGuard]
    },
    splitterRoute,
    {
        name: 'Nastavení',
        path: 'list-setup/:tab',
        component: ListSetupComponent,
        canActivate: [AdminGuard]
    },
    {
        path: 'list-containers/instances/:projectId',
        component: ListContainersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'person/:id',
        component: PersonComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'template/:id',
        component: EditTemplateComponent,
    },
    {
        path: 'project/:id',
        component: ProjectComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'detail-database/:id',
        component: DetailDatabaseComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'create-container-instance',
        component: ContainerInstanceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'create-project-container-instance',
        component: ProjectContainerInstanceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'create-container-instance/:id',
        component: ContainerInstanceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'create-container-template',
        component: ContainerTemplateComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'create-container-configuration-file',
        component: ContainerConfigurationFileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'container-configuration-file/:id',
        component: ContainerConfigurationFileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'show-container-configuration-file/:id',
        component: ShowContainerConfigurationFileComponent,
        canActivate: [AuthGuard],
        data: {
            state: {
                customData: 'show'
            }
        }
    },
    {
        path: '',
        redirectTo: 'list-projects',
        pathMatch: 'full'
    }
];
