import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppStorage} from "./app.storage";



@Injectable({
    providedIn: 'root'
})
export class BugReportService {
    private bugReportUrl: string;

    constructor(private http: HttpClient) {
        this.http.get<any>('/api/bug-report-url').subscribe(data => {
            this.bugReportUrl = data.response;
            AppStorage.setBugReportUrl(this.bugReportUrl)
            console.log(this.bugReportUrl);
        }, error => {
            console.log('Loading bug report url failed')
            console.log('Couldn\'t post because', error);
        });
    }
}
