import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ContainerInstance} from '../../dm/containerInstance';
import {NotificationService} from '../../services/notification.service';
import {AuthenticationService} from "../../services/authentication.service";
import {HttpErrorService} from "../../services/http.error.service";
import {MatTableDataSource} from "@angular/material/table";
import {Project} from "../../dm/project";
import {ContainerTemplate} from "../../dm/containerTemplate";
import {Person} from "../../dm/person";
import {catchError, forkJoin, Observable, of, Subscription} from "rxjs";
import {IdArrayService} from "../../list-projects/id-array-service/id-service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface ProjectContainerInstanceModalData {
    projectId: Number
}

@Component({
    selector: 'app-container-instance-form',
    templateUrl: './project-container-instance.component.html',
    styleUrls: ['./project-container-instance.component.css']
})
export class ProjectContainerInstanceComponent implements OnInit {
    dataSource = new MatTableDataSource<any>([]);
    templates = new Array<ContainerTemplate>();
    containerInstance: ContainerInstance;
    containerInstanceForm = this.fb.group({
        id: [null],
        createdFromTemplate: [null],
        template: [null],
        templateId: [null],
        project: [null],
        projects: [[]],
        projectsId: [[]],
        portsMapping: [[]],
        loggedUserId: [null],
        runContainer: [null]
    });
    containerInstanceSubscription: Subscription;
    projectFilterControl = new UntypedFormControl();
    templateFilterControl = new UntypedFormControl();
    selectedProjects: Project[] = new Array<Project>();
    loggedUser: Person;
    edited = false;
    projects: Project[] = [];
    selectedTemplate: ContainerTemplate;
    isChecked = false;
    project: Project;
    idArray: number[];
    isAdmin = this.authenticationService.isLoggedUserAdmin;
    isLoading = false;
    projectId;

    constructor(private fb: UntypedFormBuilder,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private notificationService: NotificationService,
                private authenticationService: AuthenticationService,
                private httpErrorService: HttpErrorService,
                private idArrayService: IdArrayService,
                public dialogRef: MatDialogRef<ProjectContainerInstanceComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ProjectContainerInstanceModalData) {
    }

    ngOnInit() {
        this.projectId = this.data.projectId;
        this.selectedProjects = [];
        if (this.idArray) {
            this.idArray = [];
            this.idArrayService.emptyArray();
        } else {
            this.idArray = this.idArrayService.getIdArray();
            this.idArrayService.emptyArray();
        }
        this.getContainerTemplates();
        this.http.get<Person>('/api/person/' + this.authenticationService.loggedUserID)
            .subscribe({
                next: (data) => {
                    this.loggedUser = data;
                    this.containerInstanceForm.patchValue({
                        loggedUserId: this.loggedUser.id
                    });
                },
                error: (error) => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání dat: ' + error.error);
                    }
                    console.error('Couldn\'t get because', error);
                }
            });
        if (this.projectId) {
            let projects: Project[] = [];
            let projectsId: Number[] = [];
            this.idArray = [];
            this.http.get<Project>('/api/project/' + this.projectId)
                .subscribe({
                    next: (data) => {
                        this.project = data;
                        this.projects.push(this.project);
                        this.selectedProjects.push(this.project);
                        this.selectedProjects.forEach(project => {
                            projects.push(project);
                            projectsId.push(project.id)
                        });
                        this.containerInstanceForm.patchValue({
                            projects: projects,
                            projectsId: projectsId
                        });
                        this.doFilter('');
                    },
                    error: (error) => {
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Chyba při načítání dat: ' + error.error);
                        }
                        console.error('Couldn\'t get because', error);
                    }
                });
        } else if (this.idArray && this.idArray.length > 0) {
            let projectsId: Number[] = [];
            const requests = this.idArray.map(projectId =>
                this.http.get<Project>('/api/project/' + projectId)
                    .pipe(
                        catchError(error => {
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při načítání dat pro projekt s ID ' + projectId + ': ' + error.error);
                            }
                            console.error('Couldn\'t get project with ID ' + projectId + ' because', error);
                            // Return an empty object to prevent the error from stopping the forkJoin
                            return of({});
                        })
                    )
            );

            forkJoin(requests).subscribe((projects: Project[]) => {
                this.selectedProjects.push(...projects.filter(project => Object.keys(project).length !== 0));
                this.selectedProjects.forEach(project => {
                    projects.push(project);
                    projectsId.push(project.id)
                });
                this.containerInstanceForm.patchValue({
                    projects: projects,
                    projectsId: projectsId
                });
            });
        } else {
            this.getProjects();
        }
    }

    doFilter(query: string) {
        if (query) {
            this.dataSource.filter = query.trim().toLocaleLowerCase();
        } else {
            this.dataSource.filter = '_';
        }
    }

    getContainerTemplates() {
        let url = '';
        let loggedUserId = this.authenticationService.loggedUserID;
        this.isAdmin = this.authenticationService.isLoggedUserAdmin;

        if (this.isAdmin) {
            url = '/api/containers/templates/all';
        } else {
            url = '/api/containers/templates/owned';
        }

        const options = {
            params: {
                loggedUserId: loggedUserId.toString()
            }
        };

        this.http.get<ContainerTemplate[]>(url, options)
            .subscribe({
                next: (data) => {
                    this.dataSource.data = data;
                    this.templates = data;
                    this.doFilter('');
                },
                error: (error) => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání šablon kontejnerů: ' + error.error);
                    }
                }
            });
    }

    getProjects() {
        let url = '';
        let leaderId = this.authenticationService.loggedUserID;
        this.isAdmin = this.authenticationService.isLoggedUserAdmin;

        if (this.isAdmin) {
            url = '/api/project/all';
        } else {
            url = '/api/project/leader';
        }

        const options = {
            params: {
                leaderId: leaderId.toString()
            }
        };

        this.http.get<Project[]>(url, options)
            .subscribe({
                next: (data) => {
                    this.dataSource.data = data;
                    for (const project of data) {
                        this.projects.push(project);
                    }
                    this.doFilter('');
                },
                error: (error) => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání projektů: ' + error.error);
                    }
                }
            });
    }

    onSubmit() {
        let serializedForm;
        this.isLoading = true;
        let url = '/api/containers/instances/create';
        const values = this.containerInstanceForm.getRawValue();
        serializedForm = JSON.stringify(values);

        this.http.post<Map<String, String>>(url, serializedForm, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')})
            .subscribe({
                next: (data) => {
                    let keyValuePairs = '';
                    for (const [key, value] of Object.entries(data)) {
                        keyValuePairs += `Název projektu: ${key}. ${value}. || `;
                    }
                    console.log(keyValuePairs);
                    const notificationMessage = keyValuePairs;
                    this.notificationService.notify(notificationMessage);

                    const prevState = this.route.snapshot.paramMap.get('currentState');

                    if (prevState) {
                        this.router.navigateByUrl(prevState);
                    }
                },
                error: (error) => {
                    console.error('Couldn\'t post because', error);
                    if (error.status == 400) {
                        this.notificationService.notifyError('Chyba při vytváření: ' + error.error);
                    } else if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při ukládání: ' + error.error);
                    }
                }
            }).add(() => {
            this.isLoading = false;
            this.dialogRef.close();
        });
    }

    projectClicked(event: Event, project: Project) {
        event.stopPropagation();
        this.toggleProjectSelection(project);
        this.projectFilterControl.setValue('Vybrat projekty');
        this.edited = true;
    }

    templateClicked(event: Event, containerTemplate: ContainerTemplate) {
        event.stopPropagation();
        this.selectedTemplate = containerTemplate;
        this.containerInstanceForm.patchValue({
            template: this.selectedTemplate,
            templateId: this.selectedTemplate.id
        });
        this.templateFilterControl.setValue(containerTemplate.name);
        this.edited = true;
    }

    toggleProjectSelection(project: Project) {
        project.selected = !project.selected;
        if (project.selected) {
            this.edited = true;
            this.selectedProjects.push(project);
        } else {
            const i = this.selectedProjects.findIndex(value => value.id === project.id);
            this.selectedProjects.splice(i, 1);
        }

        this.projectFilterControl.setValue('');
        let projects: Project[] = [];
        let projectsId: Number[] = [];
        this.selectedProjects.forEach(project => {
            projects.push(project);
            projectsId.push(project.id)
        });
        this.containerInstanceForm.patchValue({
            projects: projects,
            projectsId: projectsId
        });
    }

    removeProject(project: Project) {
        project.selected = false;
        const index = this.selectedProjects.findIndex(p => p.id === project.id);
        if (index !== -1) {
            this.selectedProjects.splice(index, 1);
        }

        this.projectFilterControl.setValue('');

        let projects: Project[] = [];
        let projectsId: Number[] = [];
        this.selectedProjects.forEach(proj => {
            projects.push(proj);
            projectsId.push(proj.id);
        });
        this.containerInstanceForm.patchValue({
            projects: projects,
            projectsId: projectsId
        });
    }

    changeStatus() {
        this.isChecked = !this.isChecked;

        if (this.isChecked) {
            this.containerInstanceForm.patchValue({runContainer: true});
        } else {
            this.containerInstanceForm.patchValue({runContainer: false});
        }
    }

    ngOnDestroy() {
        if (this.containerInstanceSubscription) {
            this.containerInstanceSubscription.unsubscribe();
        }
    }
}
