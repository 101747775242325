import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Person} from '../../dm/person';
import {ContainerConfigurationFile} from '../../dm/containerConfigurationFile';
import {NotificationService} from '../../services/notification.service';
import {HttpErrorService} from '../../services/http.error.service';
import {AuthenticationService} from '../../services/authentication.service';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from "rxjs";
import {SharedDataService} from "../shared-data-service/shared-data-service";

@Component({
    selector: 'app-show-container-configuration-file',
    templateUrl: './show-container-configuration-file.component.html',
    styleUrls: ['./show-container-configuration-file.component.css']
})
export class ShowContainerConfigurationFileComponent implements OnInit {
    dataSource = new MatTableDataSource<ContainerConfigurationFile>();
    editConfigurationFileId: number;
    containerConfigurationFile: ContainerConfigurationFile;
    containerConfigurationFileForm: FormGroup;
    containerConfigurationFileSubscription: Subscription;
    edited = false;
    loggedUser: Person;
    basicConfigurationFileId = 1;
    isShowing = false;
    isLoading = false;
    customData = null;

    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        private httpErrorService: HttpErrorService,
        private sharedDataService: SharedDataService
    ) {
        this.containerConfigurationFileForm = this.formBuilder.group({
            id: [null],
            commandsId: [null],
            name: [null, [
                Validators.required,
                Validators.maxLength(100),
                Validators.pattern('^[ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z0-9][ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z0-9_\\- ]*$')
            ]],
            // content: ['# Defaultní šablona pro vytvoření template kontejneru\n# --------------------------------------------------------------------------------------\n\nFROM ubuntu:latest\n\n# Pro připojení ke kontejneru musí být instalovaná ssh služba, která poběží\n# na portu 22 a bude nastavená jako entrypoint pro připojení. Aplikace s tímto\n# nastavením počítá a pokud se změní, nebude vzdálená správa ani přístup uživatelů\n# fungovat\nEXPOSE 22\nRUN apt-get update && apt-get install openssh-server sudo -y\nENTRYPOINT service ssh start && bash', Validators.required],
            content: [null, Validators.required],
            // addUserCommand: ['adduser --gecos \\"\\" --disabled-password $username', [Validators.required, , this.usernameValidator()]],
            addUserCommand: [null, [Validators.required, , this.usernameValidator()]],
            // addUserToSudoersCommand: ['adduser $username sudo', [Validators.required, , this.usernameValidator()]],
            addUserToSudoersCommand: [null, [Validators.required, , this.usernameValidator()]],
            // listUsersCommand: ['cat /etc/passwd', Validators.required],
            listUsersCommand: [null, Validators.required],
            // deleteUserCommand: ['deluser $username', [Validators.required, , this.usernameValidator()]],
            deleteUserCommand: [null, [Validators.required, , this.usernameValidator()]],
            loggedUser: [null]
        });
    }

    ngOnInit() {
        this.getBasicConfigurationFile();
        this.isShowing = this.sharedDataService.isShowing;
        if (this.route.routeConfig.component.name === this.constructor.name) {
            this.route.params.subscribe((params: Params) => {
                this.editConfigurationFileId = params['id'];
                this.customData = this.route.snapshot?.root?.firstChild?.data?.state?.customData;
            });
        }

        this.http.get<Person>('/api/person/' + this.authenticationService.loggedUserID)
            .subscribe({
                next: (data) => {
                    this.loggedUser = data;
                    this.containerConfigurationFileForm.patchValue({
                        loggedUser: this.loggedUser
                    });
                },
                error: (error) => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání dat: ' + error.error);
                    }
                    console.error('Couldn\'t get because', error);
                }
            });

        if (this.editConfigurationFileId) {
            this.http.get<ContainerConfigurationFile>('/api/containers/configuration-file/' + this.editConfigurationFileId, {params: {id: this.editConfigurationFileId.toString()}})
                .subscribe({
                    next: (data) => {
                        this.containerConfigurationFile = data;
                        this.containerConfigurationFileForm.patchValue({
                            id: this.containerConfigurationFile.id,
                            commandsId: this.containerConfigurationFile.commandsId,
                            name: this.containerConfigurationFile.name,
                            content: this.containerConfigurationFile.content,
                            addUserCommand: this.containerConfigurationFile.commands.addUserCommand.command,
                            addUserToSudoersCommand: this.containerConfigurationFile.commands.addUserToSudoersCommand.command,
                            listUsersCommand: this.containerConfigurationFile.commands.listUsersCommand.command,
                            deleteUserCommand: this.containerConfigurationFile.commands.deleteUserCommand.command
                        });
                        Object.keys(this.containerConfigurationFileForm.controls).forEach(controlName => {
                            this.containerConfigurationFileForm.controls[controlName].markAsTouched();
                        });
                    },
                    error: (error) => {
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Chyba při načítání dat: ' + error.error);
                        }
                        console.error('Couldn\'t get because', error);
                    }
                });
        }
    }

    getBasicConfigurationFile() {
        this.http.get<ContainerConfigurationFile>('/api/containers/configuration-file/default')
            .subscribe({
                next: (data) => {
                    this.containerConfigurationFile = data;
                    this.containerConfigurationFileForm.patchValue({
                        id: this.containerConfigurationFile.id,
                        commandsId: this.containerConfigurationFile.commandsId,
                        content: this.containerConfigurationFile.content,
                        addUserCommand: this.containerConfigurationFile.commands.addUserCommand.command,
                        addUserToSudoersCommand: this.containerConfigurationFile.commands.addUserToSudoersCommand.command,
                        listUsersCommand: this.containerConfigurationFile.commands.listUsersCommand.command,
                        deleteUserCommand: this.containerConfigurationFile.commands.deleteUserCommand.command
                    });
                    Object.keys(this.containerConfigurationFileForm.controls).forEach(controlName => {
                        this.containerConfigurationFileForm.controls[controlName].markAsTouched();
                    });
                },
                error: (error) => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání dat basic_configuration: ' + error.error);
                    }
                    console.error('Couldn\'t get because', error);
                }
            });
    }

    onSubmit() {
        this.isLoading = true;
        let url;
        let serializedForm;
        const values = this.containerConfigurationFileForm.getRawValue();
        serializedForm = JSON.stringify(values);

        this.containerConfigurationFileForm.markAsPristine();
        this.edited = false;

        if (this.editConfigurationFileId) {
            url = '/api/containers/configuration-file/update/' + this.editConfigurationFileId;
        } else {
            url = '/api/containers/configuration-file/create';
        }

        if (this.isShowing) {
            this.customData = null;
            this.router.navigate(['list-containers', 'configuration_files']);
        }

        this.http.post<ContainerConfigurationFile>(url, serializedForm, {
            headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
        })
            .subscribe({
                next: (data) => {
                    console.log('success!', data);
                    if (this.editConfigurationFileId && !this.isShowing) {
                        this.notificationService.notify('Úspěšně upraveno. [ id: ' + data.id + ', name: ' + data.name + ' ]');
                    } else if (!this.editConfigurationFileId && !this.isShowing) {
                        this.notificationService.notify('Úspěšně vytvořeno. [ id: ' + data.id + ', name: ' + data.name + ' ]');
                    }
                    if (this.route.routeConfig.component.name === this.constructor.name) {
                        this.sharedDataService.isShowing = false;
                        this.router.navigate(['list-containers', 'configuration_files']);
                    }
                },
                error: (error) => {
                    console.error('Couldn\'t post because', error);
                    if (error.status == 400) {
                        this.notificationService.notifyError('Chyba při vytváření: ' + error.error);
                    } else if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při ukládání: ' + error.error);
                    }
                }
            }).add(() => {
            this.isLoading = false;
        });
        ;
    }

    public isEdit(): boolean {
        return !!this.editConfigurationFileId;
    }

    usernameValidator(): ValidatorFn {
        return (control: FormControl) => {
            const username = control.value;
            if (username && !username.includes('$username')) {
                return {invalidUsername: true};
            }
            return null;
        };
    }

    ngOnDestroy() {
        if (this.containerConfigurationFileSubscription) {
            this.containerConfigurationFileSubscription.unsubscribe();
        }
    }
}
