import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

export interface PortsMappingModalData {
    isPortsMapping: boolean;
    portsMapping: Array<any>;
}

@Component({
    selector: 'app-port-mappings-modal',
    templateUrl: './ports-mapping-modal.component.html',
    styleUrls: ['./ports-mapping-modal.component.css']
})
export class PortsMappingModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<PortsMappingModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: PortsMappingModalData) {
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close();
    }
}
