import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from './services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    appName = 'PsqlDB';

    constructor(private router: Router,
                private authenticationService: AuthenticationService) {

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                localStorage.setItem('currentUrl', this.router.url);
            }
        });
    }


    ngOnInit(): void {
        if (!this.authenticationService.authenticated) {
            this.router.navigate(['/login']);
        }
    }
}

