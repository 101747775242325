<mat-card id="list-projects" class="table-filter">
    <mat-card-content class="table-filter-content">
        <mat-form-field>
            <input matInput type="text" (keyup)="filterBasic($event)" placeholder="Vyhledat"
                   [formControl]="basicFilterControl">
        </mat-form-field>
        <mat-form-field style="margin-left: 20px">
            <input matInput type="text" (keyup)="filterPerson($event)" placeholder="Vyhledat podle studenta"
                   [formControl]="personFilterControl">
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="Předmět" [formControl]="courseFilterControl">
                <mat-option [value]="" (click)="filterCourse('')"></mat-option>
                <mat-option *ngFor="let course of courses" [value]="course" (click)="filterCourse(course)">
                    {{ course }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="authenticationService.isLoggedUserAdmin">
            <mat-select placeholder="Vedoucí" [formControl]="leaderFilterControl">
                <mat-option [value]="" (click)="filterLeader('')"></mat-option>
                <mat-option *ngFor="let leader of leaders" [value]="leader" (click)="filterLeader(leader)">
                    {{ leader }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="Archiv" [formControl]="archiveFilterControl" [(ngModel)]="selectedFilter">
                <mat-option value="Nearchivované" (click)="filterArchive('NONE')">Nearchivované</mat-option>
                <mat-option value="Všechny" (click)="filterArchive('ALL')">Všechny</mat-option>
                <mat-option *ngFor="let semester of semesters" [value]="semester" (click)="filterArchive(semester)">
                    {{ semester }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="Tag" [formControl]="tagFilterControl">
                <mat-option [value]="" (click)="filterTag('')"></mat-option>
                <mat-option *ngFor="let tag of tags" [value]="tag" (click)="filterTag(tag)">
                    {{ tag }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button style="margin-left: 20px" (click)="clearFilters()">Zrušit filtry</button>
    </mat-card-content>
</mat-card>


<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let project"> {{project.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> Název projektu</th>
        <td mat-cell *matCellDef="let project">
            <button mat-button (click)="edit(project.id)">{{project.name}}</button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Leader Column -->
    <ng-container matColumnDef="leader">
        <th mat-header-cell style="width: 15%;" *matHeaderCellDef mat-sort-header> Vedoucí</th>
        <td mat-cell *matCellDef="let project"> {{project.leader.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Project db Column -->
    <ng-container matColumnDef="databaseProject">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> Projektová databáze</th>
        <td mat-cell *matCellDef="let project"> {{project.databaseProject ? project.databaseProject.name : ""}}
            <button mat-raised-button *ngIf="!project.databaseProject" (click)="createAdditionalDatabase(project)">
                Vytvořit
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Project Container Column -->
    <ng-container matColumnDef="container">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> Kontejner</th>
        <td mat-cell *matCellDef="let project">
            <ng-container *ngIf="project.ownedContainers?.length != 0">
                <button style="margin-right: 5px;" mat-raised-button (click)="showInstances(project.id)">
                    Zobrazit
                </button>
            </ng-container>
            <ng-container>
                <button mat-raised-button (click)="createContainer(project)">
                    Vytvořit
                </button>
            </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Project SonarQube Column -->
    <ng-container matColumnDef="sonarQube">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> SonarQube</th>
        <td mat-cell *matCellDef="let project"> {{project.sonarQube ? project.sonarQube.name : ""}}
            <button mat-raised-button *ngIf="!project.sonarQube" (click)="createSonarQube(project)">
                Vytvořit
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Course Column -->
    <ng-container matColumnDef="course">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> Předmět</th>
        <td mat-cell *matCellDef="let project"> {{project.course.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Students Column -->
    <ng-container matColumnDef="enrolledStudents">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> # studentů</th>
        <td mat-cell *matCellDef="let project"> {{project.enrolledStudents.length}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- GitLab ID Column -->
    <ng-container matColumnDef="gitlabId">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> GitlabID</th>
        <td mat-cell *matCellDef="let project"><a href="{{project.gitlabProjectURL}}"
                                                  target="_blank">{{project.gitlabProjectID}}</a></td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Archive Column -->
    <ng-container matColumnDef="archive">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Archiv</th>
        <td mat-cell *matCellDef="let project"> {{project.archive}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- TagsColumn -->
    <ng-container matColumnDef="tags">
        <th mat-header-cell style="width: 22%;" *matHeaderCellDef mat-sort-header> Tagy</th>
        <td mat-cell style=" " *matCellDef="let project">
            <mat-chip-list>
                <mat-chip *ngFor="let tag of project.tags">
                    {{tag}}
                    <mat-icon style="display: flex; justify-content: center;" matChipRemove
                              (click)="removeTagFromProject(project, tag)">cancel
                    </mat-icon>
                </mat-chip>
                <mat-icon style="padding-left: 6px; font-size: 20px; width: 32px"
                          class="mat-icon notranslate material-icons mat-icon-no-color mat-chip mat-standard-chip"
                          (click)="addTag(project)">add
                </mat-icon>
            </mat-chip-list>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!--Selected Column -->
    <ng-container matColumnDef="selected">
        <th mat-header-cell style="text-align: center; vertical-align: middle; width: 4%;" *matHeaderCellDef>
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedProjects.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let project" style="text-align: center; vertical-align: middle;">
            <mat-checkbox (change)="switchSelected(project)" [checked]="project.selected"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedProjects.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Action buttons Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 8%;" *matHeaderCellDef>
            <button mat-raised-button [disabled]="selectedProjects.size === 0" [matMenuTriggerFor]="actionMenuTop">Akce
                <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuTop="matMenu">
                <button mat-menu-item (click)="archiveSelected()">
                    Archivovat vybrané
                </button>
                <button mat-menu-item (click)="dearchiveSelected()">
                    Dearchivovat vybrané
                </button>
                <button mat-menu-item (click)="gitlabCloneScriptExportSelected()">
                    Exportovat vybrané (gitlab)
                </button>
                <button mat-menu-item (click)="csvExportSelected()">
                    Exportovat vybrané (csv)
                </button>
                <button mat-menu-item (click)="odbcExportSelected()">
                    Exportovat vybrané (odbc)
                </button>
                <button mat-menu-item (click)="deleteSelected()">
                    Smazat vybrané
                </button>
                <button mat-menu-item (click)="createContainersSelected()">
                    Vytvořit kontejner pro vybrané
                </button>
                <button mat-menu-item (click)="addTagToSelected()">
                    Přidat tag k vybraným
                </button>
                <button mat-menu-item (click)="updateSelected()"
                        matTooltip="Naimportuje do vybraných projektů, které mají nastavené gitlabID, chybějící osoby z gitlabu. Více informací: Uživatelská příručka, sekce 2.2.">
                    Aktualizovat vybrané&nbsp;&nbsp;<mat-icon style="font-size: 22px;">help</mat-icon>
                </button>
            </mat-menu>
        </th>
        <td mat-cell *matCellDef="let project" style="overflow: visible;">
            <button mat-raised-button [matMenuTriggerFor]="actionMenu">Akce
                <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
                <button mat-menu-item (click)="edit(project.id)">Upravit</button>
                <button mat-menu-item *ngIf="!project.archive" (click)="archive(project.id)">Archivovat</button>
                <button mat-menu-item *ngIf="project.archive" (click)="dearchive(project.id)">Dearchivovat</button>
                <button mat-menu-item (click)="delete(project.id)">Smazat</button>
                <button mat-menu-item *ngIf="project.gitlabProjectID !== null"
                        (click)="update(project.id, project.gitlabProjectID)"
                        matTooltip="Naimportuje do projektu chybějící osoby z gitlabu. Více informací: Uživatelská příručka, sekce 2.2.">
                    Aktualizovat&nbsp;&nbsp;<mat-icon style="font-size: 22px;">help</mat-icon>
                </button>
            </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <button mat-raised-button [disabled]="selectedProjects.size === 0" [matMenuTriggerFor]="actionMenuBottom">
                Akce
                <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuBottom="matMenu">
                <button mat-menu-item (click)="archiveSelected()">
                    Archivovat vybrané
                </button>
                <button mat-menu-item (click)="dearchiveSelected()">
                    Dearchivovat vybrané
                </button>
                <button mat-menu-item (click)="gitlabCloneScriptExportSelected()">
                    Exportovat vybrané (gitlab)
                </button>
                <button mat-menu-item (click)="csvExportSelected()">
                    Exportovat vybrané (csv)
                </button>
                <button mat-menu-item (click)="odbcExportSelected()">
                    Exportovat vybrané (odbc)
                </button>
                <button mat-menu-item (click)="deleteSelected()">
                    Smazat vybrané
                </button>
                <button mat-menu-item (click)="createContainersSelected()">
                    Vytvořit kontejner pro vybrané
                </button>
                <button mat-menu-item (click)="addTagToSelected()">
                    Přidat tag k vybraným
                </button>
                <button mat-menu-item (click)="updateSelected()"
                        matTooltip="Naimportuje do vybraných projektů, které mají nastavené gitlabID, chybějící osoby z gitlabu. Více informací: Uživatelská příručka, sekce 2.2.">
                    Aktualizovat vybrané&nbsp;&nbsp;<mat-icon style="font-size: 22px;">help</mat-icon>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
