<div *ngIf="projectsLoaded" style="overflow: hidden;">
    <mat-card-title style="font-size: 15px; margin: 10px; color: white;"> Importované projekty</mat-card-title>
    <ng-scrollbar class="perfect-scrollbar">
        <mat-accordion multi="true">
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Moje projekty ({{mineProjects.length}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-checkbox
                    [checked]="allMineSelected"
                    [indeterminate]="someMineSelected()"
                    (change)="selectAllMine($event.checked)">{{numberSelected(mineProjects)}}/{{mineProjects.length}}
                </mat-checkbox>
                <mat-list>
                    <mat-list-item *ngFor="let project of mineProjects">
                        <mat-checkbox [(ngModel)]="project.selected" (ngModelChange)="updateAllMineSelected()">
                            {{project.name}} ({{project.members.length}})
                            <a href="{{project.web_url}}" target="_blank">
                                <mat-icon [inline]="true" class="hand-on-hover"
                                          title="Zobrazit repozitář na Gitlabu">open_in_new
                                </mat-icon>
                            </a>
                        </mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>

            <mat-divider></mat-divider>

            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Ostatní projekty ({{otherProjects.length}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-checkbox
                    [checked]="allOtherSelected"
                    [indeterminate]="someOtherSelected()"
                    (change)="selectAllOther($event.checked)">{{numberSelected(otherProjects)}}
                    /{{otherProjects.length}}
                </mat-checkbox>
                <mat-list>
                    <mat-list-item *ngFor="let project of otherProjects">
                        <mat-checkbox [(ngModel)]="project.selected" (ngModelChange)="updateAllOtherSelected()">
                            {{project.name}} ({{project.members.length}})
                            <a href="{{project.web_url}}" target="_blank">
                                <mat-icon [inline]="true" class="hand-on-hover"
                                          title="Zobrazit repozitář na Gitlabu">open_in_new
                                </mat-icon>
                            </a>
                        </mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-scrollbar>
    <mat-form-field class="full-width">
        <mat-select placeholder="Předmět" [(value)]="selectedCourse">
            <mat-option *ngFor="let course of courses" [value]="course.name">
                {{ course.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button [disabled]="clicked" (click)="submitImport(); clicked = true;" color="primary"
            type="submit">
        Importovat
    </button>
    <button mat-raised-button (click)="manageTags()">Přidat tag</button>
    <button mat-raised-button mat-dialog-close>Zavřít</button>
    <mat-chip-list>
        <mat-chip *ngFor="let tag of tags">
            {{tag}}
            <mat-icon style="display: flex; justify-content: center;" matChipRemove (click)="removeTag(tag)">cancel
            </mat-icon>
        </mat-chip>
    </mat-chip-list>
</div>
