<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <!-- hide paths without name -->
            <span *ngFor="let route of routes">
                <mat-divider *ngIf="route.splitter"></mat-divider>
                <a *ngIf="route.name && (!isAdminRoute(route) || isAdmin()) && !route.splitter" mat-list-item
                   routerLink="{{route.path}}">{{route.name || route.path}}</a>
            </span>
        </mat-nav-list>
        <div id="version-name">
            PSQLDB
        </div>
        <div id="version-version">
            v{{version}}
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary" style="display: flex; align-items: center; justify-content: center; gap: 15px">
                <button
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()">
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <span
                    style="flex-grow: 1;">{{ authenticationService.loggedUserName !== "null" ? authenticationService.loggedUserName : "" }}</span>
                <div style="display: flex; align-items: center; justify-content: center;">
                    <mat-icon *ngIf="getNotification() !== ''"
                              style="height: 100%; width: 100%; color: #b30004; font-size: 40px;"
                              matTooltip="{{getNotification()}}">error
                    </mat-icon>
                </div>
                <div>
                    <a href="{{LocalStorage.getBugReportUrl()}}" target="_blank">
                        <button mat-raised-button>Nahlásit chybu
                            <mat-icon iconPositionEnd>bug_report</mat-icon>
                        </button>
                    </a>
                </div>
                <div>
                    <a href="https://gitlab.fit.cvut.cz/klickjan/psql-db/wikis/uploads/894017b73f35b9c581a88d57d816c7d6/uzivatelska_prirucka1_9.pdf"
                       target="_blank">
                        <button mat-raised-button>Nápověda
                            <mat-icon iconPositionEnd>help</mat-icon>
                        </button>
                    </a>
                </div>
                <div>
                    <button *ngIf="this.authenticationService.authenticated" mat-raised-button (click)="logout()">
                        Odhlásit
                    </button>
                </div>
            </mat-toolbar>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
