import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from '../services/authentication.service';
import {HttpErrorService} from "../services/http.error.service";
import {EmailTemplate} from "../dm/emailTemplate";
import {UntypedFormBuilder} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ServerConfiguration} from "../dm/serverConfiguration";
import {MatTableDataSource} from "@angular/material/table";
import {ContainerConfigurationFile} from "../dm/containerConfigurationFile";


@Component({
    selector: 'app-edit-configuration-file',
    templateUrl: './edit-configuration-file.component.html',
    styleUrls: ['./edit-configuration-file.component.css']
})
export class EditConfigurationFileComponent implements OnInit {
    dataSource = new MatTableDataSource<ContainerConfigurationFile>();
    configurationFileForm = this.formBuilder.group({
        id: [null],
        name: [null],
        content: [null],
        addUserCommand: [null],
        addUserToSudoersCommand: [null],
        listUsersCommand: [null],
        deleteUserCommand: [null],
    })
    editConfigurationFileId: number;

    constructor(private formBuilder: UntypedFormBuilder,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private notificationService: NotificationService,
                private authentificationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => this.editConfigurationFileId = params['id']);
        this.getConfigurationFiles();
    }

    getConfigurationFiles() {
        this.http.get<ContainerConfigurationFile[]>('/api/containers/configuration-file/all')
            .subscribe(
                data => {
                    this.dataSource.data = data;
                    this.configurationFileForm.patchValue( {
                        id: this.dataSource.data[0].id,
                        ipAddress: this.dataSource.data[0].name,
                        portMax: this.dataSource.data[0].content,
                        portMin: this.dataSource.data[0].addUserCommand,
                        serverPort: this.dataSource.data[0].addUserToSudoersCommand,
                        timeout: this.dataSource.data[0].listUsersCommand,
                        username: this.dataSource.data[0].deleteUserCommand,
                        }
                    )
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání konfigurace: [' + error.response + '].');
                    }
                }
            );
    }

    showName(): string {
        return this.dataSource.data[0].name;
    }

    showContent(): string {
        return this.dataSource.data[0].content;
    }

    showAddUserCommand(): string {
        return this.dataSource.data[0].addUserCommand;
    }

    showAddUserToSudoersCommand(): string {
        return this.dataSource.data[0].addUserToSudoersCommand;
    }

    showListUsersCommand(): string {
        return this.dataSource.data[0].listUsersCommand;
    }

    showDeleteUserCommand(): string {
        return this.dataSource.data[0].deleteUserCommand;
    }

    onSubmit() {
        let serializedForm = JSON.stringify(this.configurationFileForm.getRawValue());
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

        this.http.post<ServerConfiguration>(
            `api/containers/configuration-file/update/${this.editConfigurationFileId}}`,
            serializedForm,
            { headers: headers }
        ).subscribe(
            data => {
                console.log('successfully update configuration file', data);
                this.notificationService.notify('Úspěšně upraveno.');
                this.router.navigate(['./list-containers']);

            },
            error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při načítání dat: [' + error.status + '].');
                }
                console.error('Couldn\'t get because', error);
            }
        );

    }
}
