<div mat-dialog-content id="ports-mapping-modal" *ngIf="data.isPortsMapping">
    <button mat-raised-button class="close-button" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
    <ul style="margin-right: 40px;">
        <li *ngFor="let mapping of data.portsMapping">
            <p>{{ mapping.containerPort }} : {{ mapping.serverPort }}</p>
        </li>
    </ul>
</div>
