<mat-card id="list-databases" class="table-filter">
    <mat-card-content class="table-filter-content">
        <mat-form-field>
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Vyhledat">
        </mat-form-field>
    </mat-card-content>
</mat-card>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="database.id">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.database.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Project Column -->
    <ng-container matColumnDef="project.name">
        <th mat-header-cell style="width: 21%;" *matHeaderCellDef mat-sort-header> Projekt</th>
        <td mat-cell *matCellDef="let element">
            <button mat-button *ngIf="element.project" (click)="redirectToProject(element.project.id)">
                {{element.project.name}} {{element.project.id ? '(' + element.project.id + ')' : ''}}
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="database.name">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> Název</th>
        <td mat-cell *matCellDef="let element">
            <button mat-button *ngIf="element.database" (click)="redirectToDatabase(element.database.id)">
                {{element.database.name}}
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Host Column -->
    <ng-container matColumnDef="database.host">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> Host</th>
        <td mat-cell *matCellDef="let element"> {{element.database.host}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Disk Usage Column -->
    <ng-container matColumnDef="diskUsage">
        <th mat-header-cell style="width: 30%;" *matHeaderCellDef mat-sort-header> Velikost</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.diskUsage">{{(element.diskUsage / 1024 / 1024)|number:'1.0-2'}} MB</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!--Selected Column -->
    <ng-container matColumnDef="selected">
        <th mat-header-cell style="text-align: center; vertical-align: middle; width: 5%;" *matHeaderCellDef>
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedDatabases.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center; vertical-align: middle;">
            <mat-checkbox (change)="switchSelected(element.database)" [checked]="element.database.selected"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedDatabases.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Action buttons Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 10%;" *matHeaderCellDef>
            <button mat-raised-button [disabled]="selectedDatabases.size === 0" [matMenuTriggerFor]="actionMenuTop">Akce
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuTop="matMenu">
                <!--TODO-->
                <!--                <button mat-menu-item>-->
                <!--                    Zálohovat vybrané-->
                <!--                </button>-->
                <button mat-menu-item (click)="exportSelected()"
                        matTooltip="Stáhne JSON pro import vybraných databází do aplikace pgAdmin.">
                    Exportovat vybrané&nbsp;&nbsp;<mat-icon style="font-size: 22px;">help</mat-icon>
                </button>
            </mat-menu>
        </th>
        <td mat-cell *matCellDef="let element" style="overflow: visible;">
            <button mat-raised-button [matMenuTriggerFor]="actionMenu">Akce
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
                <button mat-menu-item (click)="setBackupMessage(element.database.id)">Zálohovat</button>
                <button mat-menu-item (click)="uploadBackup(element.database.id)">Nahrát zálohu</button>
                <button mat-menu-item (click)="exportDatabase(element.database.id)"
                        matTooltip="Stáhne JSON pro import databáze do aplikace pgAdmin.">
                    Exportovat&nbsp;&nbsp;<mat-icon style="font-size: 22px;">help</mat-icon>
                </button>
                <button mat-menu-item *ngIf="getDatabaseType(element.database.name) === 'project'"
                        (click)="delete(element)">Smazat
                </button>
            </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <button mat-raised-button [disabled]="selectedDatabases.size === 0" [matMenuTriggerFor]="actionMenuBottom">
                Akce
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuBottom="matMenu">
                <!--TODO-->
                <!--                <button mat-menu-item>-->
                <!--                    Zálohovat vybrané-->
                <!--                </button>-->
                <button mat-menu-item (click)="exportSelected()"
                        matTooltip="Stáhne JSON pro import vybraných databází do aplikace pgAdmin.">
                    Exportovat vybrané&nbsp;&nbsp;<mat-icon style="font-size: 22px;">help</mat-icon>
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

</table>
