import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from '../services/authentication.service';
import {MatTableDataSource} from '@angular/material/table';
import {HttpErrorService} from "../services/http.error.service";
import {ContainerInstance} from "../dm/containerInstance";
import {ContainerTemplate} from "../dm/containerTemplate";
import {ContainerConfigurationFile} from "../dm/containerConfigurationFile";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {MatDialog} from "@angular/material/dialog";
import {FormBuilder, FormGroup, UntypedFormBuilder, Validators} from "@angular/forms";

import {Subscription, take} from "rxjs";

import {IdArrayService} from "../list-projects/id-array-service/id-service";

import {MatSort} from "@angular/material/sort";


@Component({
    selector: 'app-list-setup',
    templateUrl: './list-setup.component.html',
    styleUrls: ['./list-setup.component.css']
})
export class ListSetupComponent implements OnInit {
    dataSource = new MatTableDataSource<any>([]);
    containerInstancesDataSource = new MatTableDataSource<any>([]);
    containerTemplatesDataSource = new MatTableDataSource<any>([]);
    containerConfigurationsDataSource = new MatTableDataSource<any>([]);
    containerServerConfigurationDataSource = new MatTableDataSource<any>([]);
    sonarqubeDataSource = new MatTableDataSource<any>([]);
    sonarqubeForm: FormGroup; emailDataSource = new MatTableDataSource<any>([]);
    emailForm: FormGroup;gitlabDataSource = new MatTableDataSource<any>([]);
    gitlabForm: FormGroup;
    serverConfigurationForm:FormGroup;
    displayedColumnsContainerServerConfiguration: string[] = ['ipAddress', 'sshPort', 'dockerPort', 'certificationAuthorityCertificate', 'certificate', 'publicKey', 'userName', 'portRange', 'timeoutSec', 'actions'];
    selectedContainerInstances = new Set<ContainerInstance>();
    selectedContainerTemplates = new Set<ContainerTemplate>();
    selectedContainerConfigurations = new Set<ContainerConfigurationFile>();
    certificationAuthorityCertificate: string;
    certificate: string;
    publicKey: string;
    portsMapping: Array<any>;
    indeterminateV = false;
    tab: string;
    selectedTab: string;
    isAdmin = false;
    isCollapsed = false;
    activeTab: number = 0;
    listContainersSubscription: Subscription;
    isLoaded = false;
    idArray: number[];
    selectedTemplateId: number;
    selectedProjectId: number;
    basicConfigurationFileId = 1;
    basicConfigurationFileLoaded = false;
    isLoading = false;
    isLoadingAction = false;

    tabIndex = {
        "sonarqube": 0,
        "email": 1,
        "gitlab": 2,
        "server_configuration": 3
    }

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private authentificationService: AuthenticationService,
        public dialog: MatDialog,
        private httpErrorService: HttpErrorService,
        private idArrayService: IdArrayService,

    ) {
        this.sonarqubeForm = this.formBuilder.group({
            id: [null],
            url: ['', Validators.required],
            port: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
        this.emailForm=this.formBuilder.group({
            email:['', Validators.required],
            port:['', Validators.required],
            host:['', Validators.required],
            password:['', Validators.required],
            auth:['', Validators.required],
            protocolClass:['', Validators.required]
        });
        this.gitlabForm=this.formBuilder.group({
            gitlabUrl:['', Validators.required],
            gitlabApiVersion:['', Validators.required],
            projects:['', Validators.required],
            members:['', Validators.required],
            searchProjects:['', Validators.required],
            paging:['', Validators.required],
            fitEmailDomain:['', Validators.required],
            andSign:['', Validators.required]
        });
        this.serverConfigurationForm = this.formBuilder.group({
            id: [null],
            ipAddress: [null, Validators.required],
            containerPortMax: [null, [Validators.required, Validators.min(1024), Validators.max(49151)]],
            containerPortMin: [null, [Validators.required, Validators.min(1024), Validators.max(49151)]],
            serverPortSSH: [null, [Validators.required, Validators.min(22), Validators.max(65535)]],
            serverPortDocker: [null, [Validators.required, Validators.min(0), Validators.max(65535)]],
            certificationAuthorityCertificate: [null, Validators.required],
            certificate: [null, Validators.required],
            publicKey: [null, Validators.required],
            timeoutSec: [null, Validators.required],
            username: [null, Validators.required],
            password: [null, Validators.maxLength(71)],
            version: [null],
        });
    }


    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.containerInstancesDataSource.sort = sort;
    }

    ngOnInit() {
        if (this.idArray) {
            this.idArray = [];
            this.idArrayService.emptyArray();
        } else {
            this.idArray = this.idArrayService.getIdArray();
            this.selectedProjectId = this.idArray[0];
            this.idArrayService.emptyArray();
        }
        this.route.params.subscribe(params => {
            if (params['tab'] && params['tab'] != ":tab") {
                this.activeTab = this.tabIndex[params['tab']];
                this.tab = params['tab'];
            } else {
                this.activeTab = 0;
                this.tab = 'sonarqube';
            }
            this.selectedTab = this.tab;
            this.router.navigate(['list-setup', this.tab]);
            if (this.idArray && this.idArray.length > 0) {
                //        this.getInstancesByProject();
            } else {
                this.get(this.tab);
            }
            this.isAdmin = this.authentificationService.isLoggedUserAdmin;
        });
    }

    doFilter(value: string) {
        this.containerInstancesDataSource.filter = value;
        this.containerTemplatesDataSource.filter = value;
        this.containerConfigurationsDataSource.filter = value;
        this.containerServerConfigurationDataSource.filter = value;
    }

    get(type: string) {
        this.isLoading = true;
        let url = '';
        let loggedUserId = this.authentificationService.loggedUserID;
        this.isAdmin = this.authentificationService.isLoggedUserAdmin;

        if (type === 'sonarqube') {
            this.dataSource = this.sonarqubeDataSource;
            if (this.isAdmin) {
                url = '/api/sonarqube-setup/all';
            } else {
                url = '/api/sonarqube-setup/all';
            }
        } else if (type === 'email') {
            this.dataSource = this.containerTemplatesDataSource;
            if (this.isAdmin) {
                url = '/api/email-setup/all';
            } else {
                url = '/api/email-setup/all';
            }
        } else if (type === 'gitlab') {
            this.dataSource = this.containerConfigurationsDataSource;
            if (this.isAdmin) {
                url = '/api/gitlab-setup/all';
            } else {
                url = '/api/gitlab-setup/all';
            }
        } else if (type === 'server_configuration') {
            this.dataSource = this.containerServerConfigurationDataSource;
            url = '/api/containers/server/get';
        }

        const options = {
            params: {
                loggedUserId: loggedUserId.toString()
            }
        };

        this.http.get<any[]>(url, options)
            .subscribe({
                next: (data: any) => {
                    console.log('Data are:', data);
                    switch (true) {
                        case type === 'sonarqube':
                            this.sonarqubeForm.patchValue(data);
                            break;
                        case type === 'email':
                            this.emailForm.patchValue(data);
                            break;
                        case type === 'gitlab':
                            this.gitlabForm.patchValue(data);
                            break;
                        case type === 'server_configuration':
                            this.serverConfigurationForm.patchValue(data);
                            console.log('Data are:', data);
                            break;
                        default:
                            console.error('This should never happened.')
                            break;
                    }

                },
                error: (error) => {
                    const errorPrefix =
                        this.selectedTab === 'sonarqube' ? 'konfigurace SonarQube  : ' :
                            this.selectedTab === 'email' ? 'konfigurace emailu ' :
                                this.selectedTab === 'gitlab' ? 'konfigurací gitlabu: ' :
                                    this.selectedTab === 'server_configuration' ? 'konfigurace serveru: ' : '';
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání ' + errorPrefix + ' ' + error.error.error);
                    }
                }
            }).add(() => {
            this.isLoading = false;
        });
    }


    onSubmit(type: string) {
        let url = '';
        let json = '';
        switch (true) {
            case type === 'sonarqube':
                url = '/api/sonarqube-setup/update'; // the URL of your backend endpoint
                json = JSON.stringify(this.sonarqubeForm.value);
                this.http.post(url, json, {headers: {'Content-Type': 'application/json'}})
                    .subscribe({
                        next: (v) => {console.log(v);this.notificationService.notify('Úspěšně upraveno.')},
                        error: (e) => {console.error('Error occured', e); this.notificationService.notifyError('Chyba: '+ e)}
                    } );
                break;
            case type === 'email':
               url = '/api/email-setup/update'; // the URL of your backend endpoint
               json = JSON.stringify(this.emailForm.value);
                this.http.post(url, json, {headers: {'Content-Type': 'application/json'}})
                    .subscribe({
                        next: (v) => {console.log(v);this.notificationService.notify('Úspěšně upraveno.')},
                        error: (e) => {console.error('Error occured', e); this.notificationService.notifyError('Chyba: '+ e)}
                    });
                break;
            case type === 'gitlab':
                url = '/api/gitlab-setup/update'; // the URL of your backend endpoint
                json = JSON.stringify(this.gitlabForm.value);
                this.http.post(url, json, {headers: {'Content-Type': 'application/json'}})
                    .subscribe({
                        next: (v) => {console.log(v);this.notificationService.notify('Úspěšně upraveno.')},
                        error: (e) => {console.error('Error occured', e); this.notificationService.notifyError('Chyba: '+ e)}
                    });
                break;
            case type === 'server_configuration':
                url = 'api/containers/server/update'; // the URL of your backend endpoint
                json = JSON.stringify(this.serverConfigurationForm.value);
                this.http.post(url, json, {headers: {'Content-Type': 'application/json'}})
                    .subscribe({
                        next: (v) => {console.log(v);this.notificationService.notify('Úspěšně upraveno.')},
                        error: (e) => {console.error('Error occured', e); this.notificationService.notifyError('Chyba: '+ e)}
                    });
                break;
            default:
                // Default case, if no earlier cases match
                break;
        }



        if (this.selectedTab === 'instances') {
            this.router.navigate(['./create-container-instance']);
        } else if (this.selectedTab === 'templates') {
            this.router.navigate(['./create-container-template']);
        } else if (this.selectedTab === 'configuration_files') {
            this.router.navigate(['./create-container-configuration-file']);
        }
    }




    onTabChange(event: MatTabChangeEvent) {
        if (event.index === 0) {
            this.selectedTab = "sonarqube";
            this.tab = "sonarqube";
            // this.get('instances');
        } else if (event.index === 1) {
            this.isLoaded = false;
            this.selectedTab = "email";
            this.tab = "email";
            // this.get('templates');
        } else if (event.index === 2) {
            this.isLoaded = false;
            this.selectedTab = "gitlab";
            this.tab = "gitlab";
            // this.get('configuration_files');
        } else if (event.index === 3) {
            this.isLoaded = false;
            this.selectedTab = "server_configuration";
            this.tab = "server_configuration";
            // this.get('server_configuration');
        }
        this.router.navigate(['list-setup', this.tab]);
    }

}
