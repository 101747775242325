import {Component, HostListener, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Person} from '../dm/person';
import {Project} from '../dm/project';
import {NotificationService} from '../services/notification.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ArchiveModalComponent} from './archive-modal/archive-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Archive} from '../dm/archive';
import {Course} from "../dm/course";
import {HttpErrorService} from "../services/http.error.service";
import {WarningModalComponentSQ} from "../sonarqube/warning-modal/warning-modal.component";
import {
    DeleteContainerModalComponent
} from "../list-containers/delete-container-modal/delete-container-modal.component";
import {
    ProjectContainerInstanceComponent
} from "./project-container-instance-form/project-container-instance.component";

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
    studentControl = new UntypedFormControl();
    leaderControl = new UntypedFormControl();
    selectedStudents: Person[] = new Array<Person>();
    filteredStudents: Observable<Person[]>;
    filteredLeaders: Observable<Person[]>;
    lastStudentFilter: string = '';
    lastLeaderFilter: string = '';
    editProjectId: number;
    archivedProject = false;
    gitlabProjectURL: string;
    editGitlabProject: boolean = false;
    gitlabProjectID: number;
    dbProjectName: string = '';
    dbProjectId: number = null;
    dbEAname: string = '';
    dbEAId: number = null;
    createPersonRendered: boolean = false;
    projectForm = this.fb.group({
        id: [null],
        name: [null, [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern('^[ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z0-9][ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z0-9_\\- ]*$')
        ]],
        description: [''],
        course: [null],
        archive: [null],
        leader: [null, Validators.required],
        students: [[]],
        editProjectId: [null],
        ownedContainers: [[]],
        gitlabId: [null, Validators.pattern('^[0-9]*$')],
    });
    project: Project;
    public leaders: Person[] = [];
    public people: Person[] = [];
    public courses: Course[] = [];
    edited = false;
    semesters = [];
    isCheckedSQ = false;
    isCheckedContainer = false;
    isLoading = false;

    constructor(private fb: UntypedFormBuilder,
                private http: HttpClient,
                private router: Router,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private notificationService: NotificationService,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        this.obtainSemesterLabels();
        if (this.route.routeConfig.component.name == this.constructor.name) {
            this.route.params.subscribe((params: Params) => this.editProjectId = params['id']);
            console.log("Leader was selected   " + this.editProjectId)
        }
        this.http.get<Person[]>('/api/person/all')
            .subscribe(
                data => {
                    this.people = data;
                    this.filteredStudents = this.studentControl.valueChanges.pipe(
                        startWith<string | Person[]>(''),
                        map(value => typeof value === 'string' ? value : this.lastStudentFilter),
                        map(filter => this.filterStudents(filter))
                    );
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání lidí: [' + error.status + '].');
                    }
                }
            );
        this.http.get<Person[]>('/api/person/teachers')
            .subscribe(
                data => {
                    this.leaders = data;
                    this.filteredLeaders = this.leaderControl.valueChanges.pipe(
                        startWith<string | Person[]>(''),
                        map(value => typeof value === 'string' ? value : this.lastLeaderFilter),
                        map(filter => this.filterLeaders(filter))
                    );
                },
                error => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání učitelů: [' + error.status + '].');
                    }
                }
            );
        this.http.get<Course[]>('/api/course/all')
            .subscribe(
                data => {
                    this.courses = data;
                    this.projectForm.patchValue({course: data[0].name});
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání kurzů: ' + error.status);
                    }
                }
            );
        if (this.editProjectId) {
            this.getProject((data: Project) => {
                    this.loadProject(data)
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání dat: [' + error.status + '].');
                    }
                    console.error('Couldn\'t get because', error);
                });
        }
    }

    getProject(success: CallableFunction, error: CallableFunction) {
        this.http.get<Project>('/api/project/' + this.editProjectId)
            .subscribe(data => success(data), data => error(data));
    }

    loadProject(data: Project) {
        this.project = data;
        this.selectedStudents = [];
        this.gitlabProjectURL = this.project.gitlabProjectURL;
        this.gitlabProjectID = this.project.gitlabProjectID;
        this.dbEAname = this.project.databaseEA !== null ? this.project.databaseEA.name : null;
        this.dbEAId = this.project.databaseEA !== null ? this.project.databaseEA.id : null;
        if (this.project.databaseProject != null) {
            this.dbProjectName = this.project.databaseProject.name;
            this.dbProjectId = this.project.databaseProject.id;
        }
        this.project.enrolledStudents.forEach(student => {
            student.selected = false;
            this.toggleStudentSelection(student);
            const index = this.people.findIndex(value => value.id === student.id);
            this.people[index] = student;
            this.edited = false;
        });
        this.projectForm.patchValue({
            id: this.project.id,
            name: this.project.name,
            description: this.project.description,
            course: this.project.course.name,
            archive: this.project.archive,
            leader: this.project.leader.id,
            gitlabId: this.gitlabProjectID,
        });
        this.projectForm.controls['name'].markAsTouched();
        this.projectForm.controls['description'].markAsTouched();
        this.projectForm.controls['course'].markAsTouched();
        this.projectForm.controls['leader'].markAsTouched();
        this.studentControl.setValue('');
        this.leaderControl.setValue(this.project.leader.name, {onlySelf: true, emitEvent: false});
    }

    obtainSemesterLabels() {
        this.http.get<String[]>('/api/project/get-semesters')
            .subscribe(
                data => {
                    console.log(data);
                    this.semesters = data;
                },
                error => {
                    console.log("Semester labels haven't been obtained")
                }
            );
    }

    leaderClicked(event: Event, leader: Person) {
        event.stopPropagation();
        this.selectLeader(leader);
        this.edited = true;

    }

    selectLeader(leader: Person) {
        this.leaderControl.setValue(leader.name);
        this.projectForm.patchValue({leader: leader.id});
    }

    studentClicked(event: Event, user: Person) {
        event.stopPropagation();
        this.toggleStudentSelection(user);
        this.edited = true;
    }

    toggleStudentSelection(person: Person) {
        person.selected = !person.selected;
        if (person.selected) {
            this.edited = true;
            this.selectedStudents.push(person);
        } else {
            const i = this.selectedStudents.findIndex(value => value.id === person.id);
            this.selectedStudents.splice(i, 1);
        }

        this.studentControl.setValue('');
        let studentsIDs: Number[] = [];
        this.selectedStudents.forEach(person => studentsIDs.push(person.id));
        this.projectForm.patchValue({students: studentsIDs});
    }

    filterStudents(filter: string): Person[] {
        this.lastStudentFilter = filter;
        if (filter) {
            return this.people.filter(option => {
                return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        } else {
            return this.people.slice();
        }
    }

    filterLeaders(filter: string): Person[] {
        this.lastLeaderFilter = filter;
        if (filter) {
            return this.leaders.filter(option => {
                return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        } else {
            return this.leaders.slice();
        }
    }

    resetLeaderFilter() {
        let currentLeader = this.leaderControl.value;
        this.leaderControl.setValue('');
        this.leaderControl.setValue(currentLeader, {onlySelf: true, emitEvent: false});
    }

    sendProjectAccessStudent(projectId: number, studentId: number) {
        this.http.get('/api/project/resend-email/' + projectId + '/' + studentId)
            .subscribe(
                data => {
                    console.log('Email sent', data);
                    this.notificationService.notify('Email odeslán úspěšně.');
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Odeslání emailu selhalo.');
                    }
                    console.error('Couldn\'t send email', error);
                }
            );
    }

    onSubmit() {
        this.isLoading = true;
        const serializedForm = JSON.stringify(this.projectForm.getRawValue());
        let url;

        this.projectForm.markAsPristine();
        this.edited = false;

        if (this.editProjectId) {
            url = '/api/project/update/' + this.editProjectId;
        } else {
            url = '/api/project/create';
        }

        console.log(serializedForm);

        this.http.post<Project>(url, serializedForm, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')})
            .subscribe(
                data => {
                    console.log('success!', data);
                    if (this.editProjectId && !this.archivedProject) {
                        this.notificationService.notify('Úspěšně upraveno.');
                    } else if (!this.editProjectId && !this.archivedProject) {
                        this.notificationService.notify('Úspěšně vytvořeno.');
                        if (this.isCheckedSQ) {
                            this.createSonarQube(data.id);
                        }
                        if (this.isCheckedContainer) {
                            this.createContainer(data.id);
                        }
                    }
                    // turns off redirect to updated project if component is used outside router outlet
                    if (this.route.routeConfig.component.name == this.constructor.name) {
                        this.router.navigate(['./project', data.id]);
                    }
                },
                error => {
                    console.error('Couldn\'t post because', error);

                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při ukládání: [' + error.status + '].');
                    }
                }
            ).add(() => {
            this.isLoading = false;
        });

        if (this.editProjectId && this.gitlabProjectID !== this.projectForm.controls['gitlabId'].value) {
            this.http.get<Project>('/api/import/gitlab/add-members/' + this.editProjectId + '/' + this.projectForm.controls['gitlabId'].value)
                .subscribe(
                    (data: Project) => {
                        console.log('Gitlab id changed', data);
                        this.loadProject(data);
                        this.notificationService.notify('GitlabId upraveno.');
                    },
                    error => {
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Chyba při ukládání gitlab id.');
                        }
                        console.error('Couldn\'t change gitlabId', error);
                    }
                ).add(() => {
                this.isLoading = false;
            });
        }
    }

    public onCreateStudentClick() {
        this.createPersonRendered = !this.createPersonRendered;
    }

    public onMailAllStudentsClick() {
        let addresses = '';
        this.selectedStudents.forEach(student => {
            addresses += '<' + student.email + '>,';
        });
        window.open('mailto:' + addresses, '_blank');
    }

    private onStudentCreated(person) {
        if (person) {
            this.createPersonRendered = false;
            this.people.push(person);
            this.toggleStudentSelection(person);
            this.edited = true;
        }
    }

    createSonarQube(projectId: Number) {
        const dialogRef = this.dialog.open(WarningModalComponentSQ, {
            width: '500px',
            data: {isSonarQube: true, isDeleting: false}
        });
        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.isLoading = true;
                this.http.post('/api/sonarqube/create', {projectId: projectId})
                    .subscribe(response => {
                        console.log('SonarQube project created successfully:', response);
                        this.notificationService.notify('SonarQube projekt vytvořen úspešne. Studenti byli automaticky přidáni.');
                        setTimeout(() => {
                            this.notificationService.clearNotification();
                        }, 12000);
                    }, error => {
                        console.log('Couldn\'t create because', error);
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            const errorMessage = 'Chyba při vytváření SonarQube: [' + error.error + '].';
                            this.notificationService.notifyError(errorMessage);
                            setTimeout(() => {
                                this.notificationService.clearNotification(errorMessage);
                            }, 12000);
                        }
                    }).add(() => {
                    this.isLoading = false;
                });
            }
        });
    }

    createContainer(projectId: Number) {
        const dialogRef = this.dialog.open(ProjectContainerInstanceComponent, {
            width: '500px',
            data: {projectId}
        });
    }

    archive(id: number) {
        let archive: Archive;
        const dialogRef = this.dialog.open(ArchiveModalComponent, {
            width: '500px',
            data: {semesters: this.semesters}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.expirationDate) {
                console.log(result.expirationDate);
                const projectId: number[] = [];
                projectId.push(id);
                archive = {
                    expirationDate: result.expirationDate,
                    hasExpired: false,
                    archiveSemester: result.archiveSemester,
                    ids: projectId
                };
                this.http.post<Array<number>>('/api/project/archive', archive)
                    .subscribe(
                        data => {
                            if (data.length == 1) {
                                this.notificationService.notify('Archivace proběhla úspěšně.');
                                this.router.navigate(['./list-projects']);
                            } else {
                                this.notificationService.notifyError('Archivace selhala.');
                                console.error('Couldn\'t archive');
                            }
                        },
                        error => {
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Archivace selhala.');
                            }
                            console.error('Couldn\'t archive because', error);
                        }
                    );
            }
            this.archivedProject = true;
        });
    }

    dearchive(id: number) {
        this.http.get('/api/project/dearchive/' + id)
            .subscribe(
                data => {
                    this.notificationService.notify('Dearchivace proběhla úspěšně.');
                    this.router.navigate(['./list-projects']);
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Dearchivace selhala.');
                    }
                    console.error('Couldn\'t dearchive because ', error);
                }
            );
        this.archivedProject = false;
    }

    public isEdit(): boolean {
        return !!this.editProjectId;
    }

    createAdditionalDatabase(project: Project) {
        event.stopPropagation();
        if (confirm('Určitě chcete projektu ' + project.name + ' vytvořit pomocnou databázi?')) {
            this.http.get('/api/project/create-project-database/' + project.id)
                .subscribe(data => {
                    console.log(data);
                    this.notificationService.notify('Pomocná databáze úspěšně vytvořena.');
                    this.getProject((data: Project) => {
                        this.project = data;
                        this.dbProjectName = data.databaseProject.name;
                        this.dbProjectId = data.databaseProject.id;
                    }, error => {
                        console.error('Couldn\'t get because', error);
                    });

                }, error => {
                    console.log('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při vytváření pomocné databáze: [' + error + '].');
                    }
                });
        }
    }

    createEaDatabase(project: Project) {
        event.stopPropagation();
        if (confirm('Určitě chcete projektu ' + project.name + ' vytvořit EA databázi?')) {
            this.http.get('/api/project/create-ea-database/' + project.id)
                .subscribe(data => {
                    console.log(data);
                    this.notificationService.notify('EA databáze úspěšně vytvořena.');

                    this.getProject((data: Project) => {
                        this.dbEAname = data.databaseEA.name;
                    }, error => {
                        console.error('Couldn\'t get because', error);
                    });
                }, error => {
                    console.log('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při vytváření EA databáze: [' + error.code + '].');
                    }
                });
        }
    }

    redirectToDatabase(id: number) {
        this.router.navigate(['detail-database', id]);
    }

    //this should be done generic
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }

    canDeactivate() {
        if (this.projectForm.dirty || this.edited || this.createPersonRendered) {
            return window.confirm('Zahodit změny?');
        } else {
            return true;
        }
    }

    changeStatusContainer() {
        this.isCheckedContainer = !this.isCheckedContainer;
    }

    changeStatusSQ() {
        this.isCheckedSQ = !this.isCheckedSQ;
    }
}
