import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IdArrayService {
    private idArray: number[] = [];

    setIdArray(idArray: number[]) {
        this.idArray = idArray;
    }

    getIdArray(): number[] {
        return this.idArray;
    }

    emptyArray() {
        this.idArray = [];
    }
}
