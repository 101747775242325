import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {interval, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {routes} from '../export/routes';
import {AuthenticationService} from '../services/authentication.service';
import {DatabaseNotificationService} from "../services/database.notification.service";
import {AppStorage} from '../services/app.storage';
import {BugReportService} from "../services/bug.report.service";
const packageJson = require('../../../package.json');

@Component({
    selector: 'app-psql-navbar',
    templateUrl: './psql-navbar.component.html',
    styleUrls: ['./psql-navbar.component.css']
})
export class PsqlNavbarComponent implements OnInit {
    version: String = packageJson.version;
    notification: String = "";
    routes = [];
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches)
        );
    LocalStorage = AppStorage;

    constructor(private breakpointObserver: BreakpointObserver,
                public authenticationService: AuthenticationService,
                private databaseNotificationService: DatabaseNotificationService,
                // bug service injection is needed to run its constructor
                private bugService: BugReportService) {
    }

    ngOnInit(): void {
        this.updateRoutes(this.authenticationService.authenticated);
        this.authenticationService.loggedInEmitter.subscribe(loggedIn => {
            this.updateRoutes(loggedIn)
        })
        this.databaseNotificationService.changed.subscribe(notification => {
            this.notification = notification;
        });
        // todo is this necessary?
        if (this.authenticationService.authenticated) {
            this.databaseNotificationService.checkNotification();
        }
        //check notifications every five minutes
        interval(1000 * 60 * 5).subscribe(x => {//TODO move timer to service, do only change.subscribe, instead of this if
            if (this.authenticationService.authenticated) {
                this.databaseNotificationService.checkNotification();
            }
            if (this.databaseNotificationService.notificationActive) {
                this.notification = this.databaseNotificationService.notificationString;
            } else {
                this.notification = '';
            }
        });
    }

    public updateRoutes(authenticated: boolean): void {
        this.routes = [...routes];
        const index = routes.findIndex(route => route.path === '/login');
        if (authenticated) {
            if (index >= 0) {
                this.routes.splice(index, 1);
            }
            this.routes = routes;
        } else {
            if (index >= 0) {
                this.routes = [routes[index]];
            } else {
                this.routes = [];
            }
        }
    }

    isAdminRoute(route: any): boolean {
        const adminRoutes = ['list-templates', 'list-setup/:tab'];
        return adminRoutes.includes(route.path);
    }

    isAdmin(): boolean {
        return this.authenticationService.isLoggedUserAdmin;
    }

    getNotification() {
        return (this.authenticationService.authenticated ? this.notification : '')
    }

    onActivate(elementRef) {
        if (elementRef.loggedInEmitter) {
            elementRef.loggedInEmitter.subscribe(loginSuccessful => {
                this.updateRoutes(loginSuccessful);
            });
        }
    }

    logout() {
        this.authenticationService.logout(true);
    }

}
