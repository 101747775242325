import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Person} from '../dm/person';
import {Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {MatSort} from '@angular/material/sort';
import {AuthenticationService} from '../services/authentication.service';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {HttpErrorService} from "../services/http.error.service";

@Component({
    selector: 'app-list-people',
    templateUrl: './list-people.component.html',
    styleUrls: ['./list-people.component.css']
})
export class ListPeopleComponent implements OnInit {
    dataSource = new MatTableDataSource<Person>();
    displayedColumns: string[] = ['id', 'name', 'username', 'databaseRole', 'projects', 'role', 'selected', 'actions'];
    selectedPeople: Person[] = [];
    indeterminateV = false;

    constructor(private http: HttpClient,
                private router: Router,
                private notificationService: NotificationService,
                private authentificationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    @ViewChild(MatTable) table: MatTable<Person>;

    ngOnInit() {
        this.getPeople();
    }

    doFilter(value: string) {
        this.dataSource.filter = value;
    }

    delete(element: any) {
        this.http.delete('/api/person/' + element.id)
            .subscribe(
                data => {
                    const index = this.dataSource.data.indexOf(element, 0);
                    if (index > -1) {
                        this.dataSource.data.splice(index, 1);
                        this.dataSource = new MatTableDataSource<Person>(this.dataSource.data);
                        console.log('Delete successful ', data);
                        this.notificationService.notify('Úspěšně smazáno.');
                    }
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Smazání selhalo: [' + error.status + '].');
                    }
                    console.error('Couldn\'t delete because', error);
                }
            );
    }

    getPeople() {
        this.http.get<Person[]>('/api/person/all')
            .subscribe(
                data => {
                    this.table.renderRows();
                    if (this.authentificationService.isLoggedUserAdmin) {
                        for (const person of data) {
                            person.selected = false;
                            this.dataSource.data.push(person);
                        }
                    } else {
                        for (const person of data) {
                            if (person.role == 'STUDENT' || person.role == 'EXTERNIST') {
                                person.selected = false;
                                this.dataSource.data.push(person);
                            }
                        }
                    }

                    this.dataSource.filterPredicate = (person: Person, filter: string): boolean => {
                        const dataStr = Object.keys(person).reduce((currentTerm: string, key: string) => {
                            return (currentTerm + (person as { [key: string]: any })[key] + '◬');
                        }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                        const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                        return dataStr.indexOf(transformedFilter) != -1;
                    };

                    this.doFilter('');
                    this.selectedPeople = [];
                    this.indeterminate();
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání osob: [' + error.response + '].');
                    }
                }
            );
    }

    edit(id: number) {
        this.router.navigate(['./person', id]);
    }

    switchSelected(p: Person) {
        p.selected = !p.selected;
        if (p.selected) {
            this.selectedPeople.push(p);
        } else {
            const index = this.selectedPeople.findIndex(pp => pp === p);
            if (index > -1) {
                this.selectedPeople.splice(index, 1);
            }
        }
        this.indeterminate();
    }

    switchAll() {
        if (this.selectedPeople.length !== this.dataSource.data.length || this.selectedPeople.length === 0) {
            this.selectedPeople = [];
            for (const pp of this.dataSource.data) {
                pp.selected = true;
                this.selectedPeople.push(pp);
            }
        } else {
            for (const pp of this.dataSource.data) {
                pp.selected = false;
            }
            this.selectedPeople = [];
        }
        this.indeterminate();
    }

    deleteSelected() {
        const idArray: number[] = [];
        for (const pp of this.selectedPeople) {
            idArray.push(pp.id);
        }
        this.http.post<Array<number>>('/api/person/delete', {ids: idArray})
            .subscribe(data => {
                    if (data.length == idArray.length) {
                        idArray.forEach(id => {
                            const index = this.dataSource.data.findIndex(element => element.id === id);
                            this.dataSource.data.splice(index, 1);
                            this.dataSource = new MatTableDataSource<Person>(this.dataSource.data);
                        });
                        this.notificationService.notify('Úspěšně smazáno.');
                    } else {
                        this.notificationService.notifyError('Mazání bylo neúspěšné pro osoby s id: (' + idArray.filter(value => !data.includes(value)) + ').');
                    }
                },
                error => {
                    console.error('Couldn\'t delete because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při mazání osob: [' + error.response + '].');
                    }
                }
            );
    }

    indeterminate() {
        this.indeterminateV = this.selectedPeople.length !== 0 && this.dataSource.data.length !== 0
            && this.selectedPeople.length !== this.dataSource.data.length;
    }
}


