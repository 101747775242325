<div mat-dialog-content class="center" *ngIf="data.isSonarQube" >
    <p class="center" *ngIf="data.isDeleting">Opravdu chcete smazat vybraný SonarQube projekt?
        Projekt bude zmazán a s ním i výsledky jeho analýz.<br>
        Studenti budou automaticky z projektu odhlášeni.
        </p>
    <p class="center" *ngIf="!data.isDeleting">Určitě chcete projektu vytvořit projekt na SonarQube?
        Projekt bude vytvořen a studenti budou automaticky přidáni.<br>
    </p>

</div>
<div mat-dialog-actions class="modal-buttons center">
    <button mat-button (click)="onSubmit()">Ano</button>
    <button mat-button (click)="onClose()">Ne</button>
</div>

