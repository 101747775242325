<div mat-dialog-content>
    <mat-form-field class="message-input" >
        <input matInput [(ngModel)]="backupMessage" [value]="'Ruční záloha'" placeholder="Backup message">
    </mat-form-field>

</div>
<div mat-dialog-actions class="modal-buttons">
    <button mat-button (click)="onSubmit()">Zálohovat</button>
    <button mat-button (click)="onClose()">Zrušit</button>
</div>
