<form [formGroup]="containerConfigurationFileForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card id="create-configuration-file">
        <mat-card-header>
            <mat-card-title>
                {{"Konfigurace (id: " + editConfigurationFileId + ")"}}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <!-- name -->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Název" formControlName="name" [readonly]="isShowing">
                        <mat-error *ngIf="containerConfigurationFileForm.controls['name'].hasError('required')">
                            Název nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error *ngIf="containerConfigurationFileForm.controls['name'].hasError('maxlength')">
                            Název je <strong>příliš dlouhý</strong> (max. 100 znaků)
                        </mat-error>
                        <mat-error *ngIf="containerConfigurationFileForm.controls['name'].hasError('pattern')">
                            Název <strong>musí</strong> začínat písmenem a <strong>musí</strong> být tvořeno kombinací
                            písmen, čísel, mezer a podtržítek
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- content -->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <textarea matInput placeholder="Obsah" formControlName="content" rows="15"
                                  [readonly]="isShowing"></textarea>
                        <mat-error *ngIf="containerConfigurationFileForm.controls['content'].hasError('required')">
                            Obsah souboru nemůže být <strong>prázdný</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- addUserCommand -->
            <div class="row" class="container-configuration-file-form-content">
                <div class="col">
                    <mat-label>
                        Příkaz pro přidání uživatele (musí obsahovat $username)
                    </mat-label>
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Příkaz pro přidání uživatele" formControlName="addUserCommand"
                               [readonly]="isShowing">
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['addUserCommand'].hasError('required')">
                            Příkaz nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['addUserCommand'].hasError('invalidUsername')">
                            Příkaz musí obsahovat <strong>$username</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- addUserToSudoersCommand -->
            <div class="row" class="container-configuration-file-form-content">
                <div class="col">
                    <mat-label>
                        Příkaz pro přidání uživatele do skupiny superuživatelů (musí obsahovat $username)
                    </mat-label>
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Příkaz pro přidání uživatele do skupiny superuživatelů"
                               formControlName="addUserToSudoersCommand" [readonly]="isShowing">
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['addUserToSudoersCommand'].hasError('required')">
                            Příkaz nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['addUserToSudoersCommand'].hasError('invalidUsername')">
                            Příkaz musí obsahovat <strong>$username</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- listUsersCommand -->
            <div class="row" class="container-configuration-file-form-content">
                <div class="col">
                    <mat-label>
                        Příkaz pro výpis uživatelů
                    </mat-label>
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Příkaz pro výpis uživatelů" formControlName="listUsersCommand"
                               [readonly]="isShowing">
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['listUsersCommand'].hasError('required')">
                            Příkaz nemůže být <strong>prázdný</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- deleteUserCommand -->
            <div class="row" class="container-configuration-file-form-content">
                <div class="col">
                    <mat-label>
                        Příkaz pro odstranění uživatele (musí obsahovat $username)
                    </mat-label>
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Příkaz pro odstranění uživatele"
                               formControlName="deleteUserCommand" [readonly]="isShowing">
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['deleteUserCommand'].hasError('required')">
                            Příkaz nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="containerConfigurationFileForm.controls['deleteUserCommand'].hasError('invalidUsername')">
                            Příkaz musí obsahovat <strong>$username</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button *ngIf="!isShowing" [disabled]="!containerConfigurationFileForm.valid || isLoading" mat-raised-button
                    color="primary"
                    type="submit">{{editConfigurationFileId == null ? "Vytvořit" : "Uložit"}}</button>
            <button *ngIf="isShowing" mat-raised-button color="primary"
                    type="submit">Zavřít
            </button>
        </mat-card-actions>
    </mat-card>
</form>
<mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
