import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators} from '@angular/forms';
import { Observable,} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotificationService} from '../services/notification.service';
import {map, startWith} from 'rxjs/operators';
import {BackupRule} from '../dm/backupRule';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DatabaseListComponent} from './database-list/database-list.component';
import {Router} from '@angular/router';
import {Database} from '../dm/database';
import {HttpErrorService} from "../services/http.error.service";

@Component({
  selector: 'app-backup-rules',
  templateUrl: './backup-rules.component.html',
  styleUrls: ['./backup-rules.component.css']
})
export class BackupRulesComponent implements OnInit {
    editMode = false;
    loadingDatabases: boolean;
    usedDbIds: number[] = new Array<number>();
    indeterminateV = false;
    selectedBackupRules = new Set();
    monthlyPeriodicity = false;
    smartBackupEnabled = false;
    databaseControl = new UntypedFormControl();
    filteredDatabases: Observable<Database[]>;
    lastDatabaseFilter = '';
    selectedDatabases: Database[] = new Array<Database>();
    dataSource = new MatTableDataSource<BackupRule>();
    displayedColumns: string[] = ['id', 'backupRuleName', 'description', 'database', 'selected', 'actions'];
    public databases: Database[] = [];
    backupRuleForm = this.fb.group({
        id: [null],
        name: [null, [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern('^[ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z][ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z0-9_ ]*$')
        ]],
        description: [null],
        periodicity: [null, Validators.required],
        serviceLife: [null, Validators.required],
        day: [null, Validators.required],
        time: [null, Validators.required],
        databasesIds: [null],
        smartBackup: false
    });
    periodicityOptions = ['Denně', 'Týdně', 'Měsíčně'];
    serviceLifeOptions = ['Den', 'Týden', 'Měsíc', 'Rok'];
    dayOptionsWeeklyPeriodicity = ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'];
    dayOptionsMonthlyPeriodicity = [];

  constructor(private fb: UntypedFormBuilder,
              private http: HttpClient,
              private router: Router,
              public dialog: MatDialog,
              private notificationService: NotificationService,
              private httpErrorService: HttpErrorService
  ) { }

  ngOnInit() {
      this.getAllBackupRules();
      this.loadDatabases();
      this.fillDays();
  }

  fillDays() {
      for (let day = 1; day <= 28; day++) {
          this.dayOptionsMonthlyPeriodicity.push(day.toString());
      }
  }

  databaseClicked(event: Event, database: Database) {
      event.stopPropagation();
      this.toggleDatabaseSelection(database);
  }

    toggleDatabaseSelection(database: Database) {
      database.selected = !database.selected;
      if (database.selected) {
          this.selectedDatabases.push(database);
      } else {
          if (database.backupRule) {
              database.backupRule = null;
          }
          const i = this.selectedDatabases.findIndex(value => value.id === database.id);
          this.selectedDatabases.splice(i, 1);
          const dbsIDs1: number[] = [];
          this.selectedDatabases.forEach(dbs => dbsIDs1.push(dbs.id));
          this.backupRuleForm.patchValue({databasesIds: dbsIDs1});
      }

      const ix = this.selectedDatabases.indexOf(database);
      if (ix > -1) {
          const dbsIDs: number[] = [];
          this.selectedDatabases.forEach(dbs => dbsIDs.push(dbs.id));
          this.backupRuleForm.patchValue({databasesIds: dbsIDs});
      }
      this.databaseControl.setValue('');
    }

  switchSelected(backupRule: BackupRule) {
      backupRule.selected = !backupRule.selected;
      if (backupRule.selected) {
          this.selectedBackupRules.add(backupRule);
      } else {
          this.selectedBackupRules.delete(backupRule);
      }
      this.indeterminate();
  }

    switchAll() {
        if (this.selectedBackupRules.size < this.dataSource.filteredData.length) {
            for (const backupRule of this.dataSource.filteredData) {
                backupRule.selected = true;
                this.selectedBackupRules.add(backupRule);
            }
        } else {
            for (const backupRule of this.dataSource.filteredData) {
                backupRule.selected = false;
                this.selectedBackupRules.delete(backupRule);
            }
        }
        this.indeterminate();
    }

  loadDatabases(backupRule?: BackupRule) {
      this.loadingDatabases = true;
      this.getDatabases(backupRule).then(data => {
          this.databases = data;
          this.filteredDatabases = this.databaseControl.valueChanges.pipe(
              startWith<string | Database[]>(''),

              map(value => typeof value === 'string' ? value : this.lastDatabaseFilter),

              map(filter => this.filterDatabases(filter)),

              map(value => this.selectDatabases(value, backupRule))
          );
      }).catch(error => {
          console.error('Couldn\'t get because', error);
          if (!this.httpErrorService.ignoreError(error.status)) {
              this.notificationService.notifyError('Chyba při načítání databází: [' + error.response + '].');
          }
      });
  }

  async getDatabases(backupRule?: BackupRule) {
      return await this.http.get<Database[]>('/api/database/unarchived').toPromise();
  }

  selectDatabases(databases: Database[], backupRule: BackupRule) {
        if (backupRule) {
            return this.filterSelectedDatabasedOnEdit(databases, backupRule);
        }

        if (!backupRule) {
            return this.filterUsedDatabasesOnCreate(databases);
        }
  }

  filterUsedDatabasesOnCreate(value: Database[]): Database[] {
      let filtered: Database[] = [];

      filtered = value.filter(database => database.backupRule === null);

      filtered.forEach(database => database.selected = false);

      return filtered;
  }

  filterSelectedDatabasedOnEdit(value: Database[], backupRule: BackupRule): Database[] {
      value.forEach(db => {
         if (db.backupRule) {
             if (db.backupRule.id === backupRule.id) {
                 db.selected = true;
                 const i = this.selectedDatabases.findIndex(val => val.id === db.id);
                 if (i === -1) {
                     this.selectedDatabases.push(db);
                 }
             } else {
                 db.selected = false;
             }
         }
      });
      return value;
  }

  filterDatabases(filter: string): Database[] {
      this.lastDatabaseFilter = filter;
      if (filter) {
          return this.databases.filter(option => {
              return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
          });
      } else {
          return this.databases.slice();
      }
  }

  toggleSmartBackup() {
      if (!this.backupRuleForm.get('smartBackup').value) {
          this.backupRuleForm.controls.periodicity.setValidators([Validators.required]);
          this.backupRuleForm.controls.serviceLife.setValidators([Validators.required]);
          this.backupRuleForm.controls.time.setValidators([Validators.required]);
          this.backupRuleForm.controls.day.setValidators([Validators.required]);

          this.backupRuleForm.controls.periodicity.updateValueAndValidity();
          this.backupRuleForm.controls.serviceLife.updateValueAndValidity();
          this.backupRuleForm.controls.time.updateValueAndValidity();
          this.backupRuleForm.controls.day.updateValueAndValidity();
      } else {
          this.backupRuleForm.controls.periodicity.clearValidators();
          this.backupRuleForm.controls.serviceLife.clearValidators();
          this.backupRuleForm.controls.time.clearValidators();
          this.backupRuleForm.controls.day.clearValidators();

          this.backupRuleForm.controls.periodicity.updateValueAndValidity();
          this.backupRuleForm.controls.serviceLife.updateValueAndValidity();
          this.backupRuleForm.controls.time.updateValueAndValidity();
          this.backupRuleForm.controls.day.updateValueAndValidity();
      }
  }

  togglePeriodicity() {
      this.backupRuleForm.patchValue({day: null});
      this.updateValidatorsByPeriodicity();
  }

  updateValidatorsByPeriodicity() {
      const periodicity = this.backupRuleForm.get('periodicity').value;
      if (periodicity === 'Denně') {
          this.backupRuleForm.controls.day.clearValidators();
          this.backupRuleForm.controls.day.updateValueAndValidity();
          this.backupRuleForm.controls.day.disable();
          this.monthlyPeriodicity = false;
      } else if (periodicity === 'Týdně') {
          this.backupRuleForm.controls.day.enable();
          this.backupRuleForm.controls.day.setValidators([Validators.required]);
          this.backupRuleForm.controls.day.updateValueAndValidity();
          this.monthlyPeriodicity = false;
      } else {
          this.backupRuleForm.controls.day.enable();
          this.backupRuleForm.controls.day.setValidators([Validators.required]);
          this.backupRuleForm.controls.day.updateValueAndValidity();
          this.monthlyPeriodicity = true;
      }
  }

  createNewRule() {
      this.createRule().then(_ => {
          this.notificationService.notify('Pravidlo vytvořeno.');
          this.selectedDatabases = [];
          this.getAllBackupRules();
          this.loadDatabases();
          this.clearChosenDatabases();
      }).catch(e => {
          console.log('Err: ', e);
          this.notificationService.notifyError('Chyba při vytváření pravidla.');
      });
  }

  clearChosenDatabases() {
      this.backupRuleForm.patchValue({
          databasesIds: []
      });
  }

  editRule() {
      this.editPost().then(_ => {
          this.notificationService.notify('Úspěšně upraveno.');
          this.editMode = false;
          this.loadDatabases();
          this.getAllBackupRules();
          this.formResetAndClearFalseErrors(this.backupRuleForm);
          }
      ).catch(error => {
          console.log('err: ', error);
          if (!this.httpErrorService.ignoreError(error.status)) {
              this.notificationService.notifyError('Chyba při ukládání. ');
          }
      });
  }

  formResetAndClearFalseErrors(form: UntypedFormGroup): void {
        form.reset();
        Object.keys(form.controls).forEach(key => {
            form.controls[key].setErrors(null);
        });
    }

  async createRule() {
      if (this.backupRuleForm.get('databasesIds').value == null) {
          this.backupRuleForm.patchValue({
              databasesIds: []
          });
      } else {
          this.backupRuleForm.get('databasesIds').value.forEach(id => {
             if (!this.usedDbIds.includes(id)) {
                 this.usedDbIds.push(id);
             }
          });
      }
      const serializedForm = JSON.stringify(this.backupRuleForm.getRawValue());
      const url = '/api/backup/rule/create';
      await this.http.post<BackupRule>(url, serializedForm, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')}).toPromise();
  }

  getAllBackupRules() {
       this.http.get<BackupRule[]>('/api/backup/rule/all').subscribe(
           data => {
               this.dataSource = new MatTableDataSource<BackupRule>(data);
               this.fillDbIds();
               this.selectedBackupRules = new Set();
               this.indeterminate();
           },
           error => {
               console.log('Couldn\'t get because', error);
               if (!this.httpErrorService.ignoreError(error.status)) {
                   this.notificationService.notifyError('Chyba při načítání pravidel: [' + error.response + '].');
               }
           });
  }

  fillDbIds() {
      this.dataSource.data.forEach(bu => {
          bu.databasesIds.forEach(id => {
              if (!this.usedDbIds.includes(id)) {
                  this.usedDbIds.push(id);
              }
          });
      });
  }

    indeterminate() {
        this.indeterminateV = this.selectedBackupRules.size !== 0 && this.dataSource.data.length !== 0
            && this.selectedBackupRules.size !== this.dataSource.data.length;
    }

    showDatabaseList(backupRule: BackupRule) {
      const dialogRef = this.dialog.open(DatabaseListComponent, {
          width: '800px',
          data: { databasesName: backupRule.databasesName }
      });
    }

    deleteAndGetDatabases(backupRule: BackupRule) {
      this.delete(backupRule).then(data => {
              const index = this.dataSource.data.indexOf(backupRule, 0);
              if (index > -1) {
                  this.dataSource.data.splice(index, 1);
                  this.dataSource = new MatTableDataSource<BackupRule>(this.dataSource.data);
                  console.log('Delete successful ', data);
                  this.notificationService.notify('Smazání pravidla proběhlo úspěšně.');
                  this.deleteId(backupRule);
                  this.loadDatabases();
              }
          },
          error => {
              if (!this.httpErrorService.ignoreError(error.status)) {
                  this.notificationService.notifyError('Smazání pravidla selhalo: [' + error.status + '].');
              }
              console.error('Couldn\'t delete because', error);
          }
      );
    }

    deleteId(backupRule: BackupRule) {
        backupRule.databasesIds.forEach(flDb => {
            const index = this.usedDbIds.findIndex(id => backupRule.databasesIds.includes(id));
            if (index !== -1) {
                this.usedDbIds.splice(index, 1);
            }
        });
    }

    async delete(backupRule: BackupRule) {
      await this.http.delete('/api/backup/rule/delete/' + backupRule.id, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')}).toPromise();
    }

    edit(backupRule: BackupRule) {
      this.backupRuleForm.patchValue(backupRule);
      if (!isNaN(+backupRule.day)) {
          this.fillDays();
          this.backupRuleForm.patchValue({day: backupRule.day});
      }
      this.updateValidatorsByPeriodicity();
      this.editMode = true;
      this.loadDatabases(backupRule);

    }

    async editPost() {
        const serializedForm = JSON.stringify(this.backupRuleForm.getRawValue());
        const url = '/api/backup/rule/update';
        await this.http.post<BackupRule>(url, serializedForm, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')}).toPromise();
    }

    cancelEdit() {
      this.formResetAndClearFalseErrors(this.backupRuleForm);
      this.editMode = false;
      this.loadDatabases();
    }
}

