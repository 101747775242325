import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from '../services/authentication.service';
import {MatTableDataSource} from '@angular/material/table';
import {HttpErrorService} from "../services/http.error.service";
import {ContainerInstance} from "../dm/containerInstance";
import {ContainerTemplate} from "../dm/containerTemplate";
import {ContainerConfigurationFile} from "../dm/containerConfigurationFile";
import {ServerConfiguration} from "../dm/serverConfiguration";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {MatDialog} from "@angular/material/dialog";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {WarningModalComponent} from "../list-backups/warning-modal/warning-modal.component";
import {
    CertificationConfigurationCertificateModalComponent
} from "./certification-authority-certificate-modal/certification-configuration-certificate-modal.component";
import {CertificateModalComponent} from "./certificate-modal/certificate-modal.component";
import {PublicKeyModalComponent} from "./public-key-modal/public-key-modal.component";
import {Person} from "../dm/person";
import {Project} from "../dm/project";
import {Subscription, take} from "rxjs";
import {
    DeleteContainerModalComponent
} from "./delete-container-modal/delete-container-modal.component";
import {PortsMappingModalComponent} from "./ports-mapping-modal/ports-mapping-modal.component";
import {IdArrayService} from "../list-projects/id-array-service/id-service";
import {SharedDataService} from "./shared-data-service/shared-data-service";
import {
    DeleteRunningContainerModalComponent
} from "./delete-running-container-modal/delete-running-container-modal.component";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'app-list-containers',
    templateUrl: './list-containers.component.html',
    styleUrls: ['./list-containers.component.css']
})
export class ListContainersComponent implements OnInit {
    dataSource = new MatTableDataSource<any>([]);
    containerInstancesDataSource = new MatTableDataSource<any>([]);
    containerTemplatesDataSource = new MatTableDataSource<any>([]);
    containerConfigurationsDataSource = new MatTableDataSource<any>([]);
    containerServerConfigurationDataSource = new MatTableDataSource<any>([]);
    displayedColumnsContainerInstances: string[] = ['id', 'name', 'status', 'projectName', 'containerServerId', 'ports', 'owner', 'selected', 'actions'];
    displayedColumnsContainerTemplates: string[] = ['id', 'name', 'tag', 'owner', 'containerInstance', 'containerServerId', 'selected', 'actions'];
    displayedColumnsContainerConfigurations: string[] = ['id', 'name', 'owner', 'selected', 'actions'];
    displayedColumnsContainerServerConfiguration: string[] = ['ipAddress', 'sshPort', 'dockerPort', 'certificationAuthorityCertificate', 'certificate', 'publicKey', 'userName', 'portRange', 'timeoutSec', 'actions'];
    selectedContainerInstances = new Set<ContainerInstance>();
    selectedContainerTemplates = new Set<ContainerTemplate>();
    selectedContainerConfigurations = new Set<ContainerConfigurationFile>();
    certificationAuthorityCertificate: string;
    certificate: string;
    publicKey: string;
    portsMapping: Array<any>;
    indeterminateV = false;
    tab: string;
    selectedTab: string;
    isAdmin = false;
    isCollapsed = false;
    activeTab: number = 0;
    listContainersSubscription: Subscription;
    containerInstances: ContainerInstance[];
    isLoaded = false;
    idArray: number[];
    selectedTemplateId: number;
    selectedProjectId: number;
    basicConfigurationFileId: number;
    basicContainerTemplateId: number;
    basicConfigurationFileLoaded = false;
    basicContainerTemplateLoaded = false;
    isLoading = false;
    isLoadingAction = false;

    tabIndex = {
        "instances": 0,
        "templates": 1,
        "configuration_files": 2,
        "server_configuration": 3
    }

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    constructor(private fb: UntypedFormBuilder,
                private http: HttpClient,
                private router: Router,
                private route: ActivatedRoute,
                private notificationService: NotificationService,
                private authentificationService: AuthenticationService,
                public dialog: MatDialog,
                private httpErrorService: HttpErrorService,
                private idArrayService: IdArrayService,
                private sharedDataService: SharedDataService) {
    }
    @ViewChild(MatSort) set matSort1(sort1: MatSort) {
        this.containerInstancesDataSource.sort = sort1;
    }

    @ViewChild(MatSort) set matSort2(sort2: MatSort) {
        this.containerTemplatesDataSource.sort = sort2;
    }

    ngOnInit() {
        if (this.idArray) {
            this.idArray = [];
            this.idArrayService.emptyArray();
        } else {
            this.idArray = this.idArrayService.getIdArray();
            this.selectedProjectId = this.idArray[0];
            this.idArrayService.emptyArray();
        }
        this.route.params.subscribe(params => {
            if (params['tab'] && params['tab'] != ":tab") {
                this.activeTab = this.tabIndex[params['tab']];
                this.tab = params['tab'];
            } else {
                this.activeTab = 0;
                this.tab = 'instances';
            }
            this.selectedTab = this.tab;
            this.router.navigate(['list-containers', this.tab]);
            if (this.idArray && this.idArray.length > 0) {
                this.getInstancesByProject();
            } else {
                this.get(this.tab);
            }
            this.isAdmin = this.authentificationService.isLoggedUserAdmin;
        });
    }

    doFilter(value: string) {
        this.containerInstancesDataSource.filter = value;
        this.containerTemplatesDataSource.filter = value;
        this.containerConfigurationsDataSource.filter = value;
        this.containerServerConfigurationDataSource.filter = value;
    }

    get(type: string) {
        this.isLoading = true;
        let url = '';
        let loggedUserId = this.authentificationService.loggedUserID;
        this.isAdmin = this.authentificationService.isLoggedUserAdmin;

        if (type === 'instances') {
            this.dataSource = this.containerInstancesDataSource;
            if (this.isAdmin) {
                url = '/api/containers/instances/all';
            } else {
                url = '/api/containers/instances/owned';
            }
        } else if (type === 'templates') {
            this.dataSource = this.containerTemplatesDataSource;
            if (this.isAdmin) {
                url = '/api/containers/templates/all';
            } else {
                url = '/api/containers/templates/owned';
            }
        } else if (type === 'configuration_files') {
            this.dataSource = this.containerConfigurationsDataSource;
            if (this.isAdmin) {
                url = '/api/containers/configuration-file/all';
            } else {
                url = '/api/containers/configuration-file/owned';
            }
        } else if (type === 'server_configuration') {
            this.dataSource = this.containerServerConfigurationDataSource;
            url = '/api/containers/server/get';
        }

        const options = {
            params: {
                loggedUserId: loggedUserId.toString()
            }
        };

        this.http.get<any[]>(url, options)
            .subscribe({
                next: (data) => {
                    this.dataSource.data = data;
                    console.log('Get successful ', data);
                    this.doFilter('');
                    if (this.isLoaded) {
                        this.containerInstances = data;
                        const filteredInstances = this.containerInstances.filter(instance => instance.createdFromTemplate.id === this.selectedTemplateId);
                        this.dataSource = new MatTableDataSource(filteredInstances);
                    }
                    if (data) {
                        if (type === "configuration_files" && !this.basicConfigurationFileLoaded) {
                            this.http.get<ContainerConfigurationFile>('/api/containers/configuration-file/default')
                                .subscribe({
                                    next: (data) => {
                                        const fileExists = this.dataSource.data.some(existingFile => existingFile.id === data.id);

                                        if (!fileExists) {
                                            this.dataSource.data.push(data);
                                        }
                                        this.basicConfigurationFileLoaded = true;
                                        this.basicConfigurationFileId = data.id;
                                        console.log('Get successful ', data);
                                        this.doFilter('');
                                    },
                                    error: (error) => {
                                        if (!this.httpErrorService.ignoreError(error.status)) {
                                            this.notificationService.notifyError('Chyba při načítání basic_configuration: ' + error.error);
                                        }
                                        console.error('Couldn\'t get because', error);
                                    }
                                }).add(() => {
                                this.basicConfigurationFileLoaded = false;
                            });
                        }

                        if (type === "templates" && !this.basicContainerTemplateLoaded) {
                            this.http.get<ContainerTemplate>('/api/containers/templates/default')
                                .subscribe({
                                    next: (data) => {
                                        const templateExists = this.dataSource.data.some(existingtemplate => existingtemplate.id === data.id);

                                        if (!templateExists) {
                                            this.dataSource.data.push(data);
                                        }
                                        this.basicContainerTemplateLoaded = true;
                                        this.basicContainerTemplateId = data.id;
                                        console.log('Get successful ', data);
                                        this.doFilter('');
                                    },
                                    error: (error) => {
                                        if (!this.httpErrorService.ignoreError(error.status)) {
                                            this.notificationService.notifyError('Chyba při načítání basic template: ' + error.error);
                                        }
                                        console.error('Couldn\'t get because', error);
                                    }
                                }).add(() => {
                                this.basicContainerTemplateLoaded = false;
                            });
                        }
                    }
                },
                error: (error) => {
                    const errorPrefix =
                        this.selectedTab === 'instances' ? 'instancí kontejnerů: ' :
                            this.selectedTab === 'templates' ? 'šablon kontejnerů: ' :
                                this.selectedTab === 'configuration_files' ? 'konfigurací kontejnerů: ' :
                                    this.selectedTab === 'server_configuration' ? 'konfigurace serveru: ' : '';
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání ' + errorPrefix + ' ' + error.error.error);
                    }
                }
            }).add(() => {
            this.isLoading = false;
        });
    }

    getInstancesByProject() {
        this.dataSource = this.containerInstancesDataSource;
        let url = '/api/containers/instances/project';
        let projectId = this.selectedProjectId;

        const options = {
            params: {
                projectId: projectId.toString()
            }
        };

        this.http.get<any[]>(url, options)
            .subscribe({
                next: (data) => {
                    this.dataSource.data = data;
                    console.log('Get successful ', data);
                    this.doFilter('');
                    this.containerInstances = data;
                    this.dataSource = new MatTableDataSource(this.containerInstances);
                },
                error: (error) => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání instancí kontejnerů ' + error.error.error);
                    }
                }
            });
    }

    onSubmit() {
        if (this.selectedTab === 'instances') {
            this.router.navigate(['./create-container-instance']);
        } else if (this.selectedTab === 'templates') {
            this.router.navigate(['./create-container-template']);
        } else if (this.selectedTab === 'configuration_files') {
            this.router.navigate(['./create-container-configuration-file']);
        }
    }

    edit(id: number) {
        if (this.selectedTab === 'instances') {
            this.router.navigate(['./create-container-instance']);
        } else if (this.selectedTab === 'templates') {
            this.router.navigate(['./create-container-template']);
        } else if (this.selectedTab === 'configuration_files') {
            this.router.navigate(['./container-configuration-file', id]);
        } else if (this.selectedTab === 'server_configuration') {
            this.router.navigate(['../edit-server-configuration']);
        }
    }

    show(id: number) {
        if (this.selectedTab === 'configuration_files') {
            this.sharedDataService.isShowing = true;
            this.router.navigate(['./show-container-configuration-file', id]);
        }
    }

    runContainerInstance(containerInstanceID: number) {
        this.isLoadingAction = true;
        const containerInstancesID: number[] = [];
        containerInstancesID.push(containerInstanceID);
        let url = '/api/containers/instances/run';

        this.http.post<Map<String, String>>(url, containerInstancesID)
            .subscribe({
                next: (data) => {
                    let keyValuePairs = '';
                    for (const [key, value] of Object.entries(data)) {
                        keyValuePairs += `Název instance: ${key}. ${value} || `;
                    }
                    console.log(keyValuePairs);
                    const notificationMessage = keyValuePairs;
                    this.notificationService.notify(notificationMessage);
                    this.get('instances');
                },
                error: (error) => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při spuštění instance: ' + error.error.error);
                    }
                }
            }).add(() => {
            this.isLoadingAction = false;
        });
    }

    runSelected() {
        this.isLoadingAction = true;
        let idArray: number[] = this.collectSelectedIds("instances");
        let url = '/api/containers/instances/run';

        this.http.post<Map<String, String>>(url, idArray)
            .subscribe({
                next: (data) => {
                    let keyValuePairs = '';
                    for (const [key, value] of Object.entries(data)) {
                        keyValuePairs += `Název instance: ${key}. ${value} || `;
                    }
                    console.log(keyValuePairs);
                    const notificationMessage = keyValuePairs;
                    this.notificationService.notify(notificationMessage);
                    this.get('instances');
                },
                error: (error) => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        if (this.selectedTab === "instances") {
                            this.notificationService.notifyError('Chyba při spuštění instancí: ' + error.error);
                        }
                    }
                }
            }).add(() => {
            this.isLoadingAction = false;
        });
    }

    stopContainerInstance(containerInstanceID: number) {
        this.isLoadingAction = true;
        const containerInstancesID: number[] = [];
        containerInstancesID.push(containerInstanceID);
        let url = '/api/containers/instances/stop';

        this.http.post<Map<String, String>>(url, containerInstancesID)
            .subscribe({
                next: (data) => {
                    let keyValuePairs = '';
                    for (const [key, value] of Object.entries(data)) {
                        keyValuePairs += `Název instance: ${key}. ${value} || `;
                    }
                    console.log(keyValuePairs);
                    const notificationMessage = keyValuePairs;
                    this.notificationService.notify(notificationMessage);
                    this.get('instances');
                }
                ,
                error: (error) => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při zastavení instance: ' + error.error);
                    }
                }
            }).add(() => {
            this.isLoadingAction = false;
        });
    }

    stopSelected() {
        this.isLoadingAction = true;
        let idArray: number[] = this.collectSelectedIds("instances");
        let url = '/api/containers/instances/stop';

        this.http.post<Map<String, String>>(url, idArray)
            .subscribe({
                next: (data) => {
                    let keyValuePairs = '';
                    for (const [key, value] of Object.entries(data)) {
                        keyValuePairs += `Název instance: ${key}. ${value} || `;
                    }
                    console.log(keyValuePairs);
                    const notificationMessage = keyValuePairs;
                    this.notificationService.notify(notificationMessage);
                    this.get('instances');
                },
                error: (error) => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        if (this.selectedTab === "instances") {
                            this.notificationService.notifyError('Chyba při zastavení instancí: ' + error.error);
                        }
                    }
                }
            }).add(() => {
            this.isLoadingAction = false;
        });
    }

    delete(element: any) {
        const dialogRef = this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {
                isContainerTemplate: this.selectedTab === "templates",
                isContainerConfigurationFile: this.selectedTab === "configuration_files",
                isDeleting: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoadingAction = true;
                let data = [];

                const containerTemplatesIds: number[] = [];
                containerTemplatesIds.push(element.id);

                const containerConfigurationFilesIds: number[] = [];
                containerConfigurationFilesIds.push(element.id);

                let url = '';

                if (this.selectedTab === 'templates') {
                    this.dataSource = this.containerTemplatesDataSource;
                    url = '/api/containers/templates/delete';
                    data = containerTemplatesIds;
                } else if (this.selectedTab === 'configuration_files') {
                    this.dataSource = this.containerConfigurationsDataSource;
                    url = '/api/containers/configuration-file/delete';
                    data = containerConfigurationFilesIds;
                }

                this.http.post<Map<String, String>>(url, data)
                    .subscribe({
                        next: (data) => {
                            const index = this.dataSource.data.indexOf(element, 0);
                            if (index > -1) {
                                this.dataSource.data.splice(index, 1);
                                this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
                                let keyValuePairs = '';
                                for (const [key, value] of Object.entries(data)) {
                                    if (this.selectedTab === "templates") {
                                        keyValuePairs += `Název šablony: ${key}. ${value} || `;
                                    } else if (this.selectedTab === 'configuration_files') {
                                        keyValuePairs += `Název konfiguračního souboru: ${key}. ${value} || `;
                                    }
                                }
                                console.log(keyValuePairs);
                                const notificationMessage = keyValuePairs;
                                this.notificationService.notify(notificationMessage);
                                if (this.selectedTab === "templates") {
                                    this.get('templates');
                                } else {
                                    this.get('configuration_files');
                                }
                            }
                        }
                        ,
                        error: (error) => {
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Smazání selhalo: ' + error.error.error);
                            }
                            console.error('Couldn\'t delete because', error);
                        }
                    }).add(() => {
                    this.isLoadingAction = false;
                });
            }
        });
    }

    deleteInstance(containerInstance: ContainerInstance) {
        this.dataSource = this.containerInstancesDataSource;
        let removeRunning = false;
        const dialogRef1 = this.dialog.open(DeleteContainerModalComponent, {
            width: '500px',
            data: {
                isContainerInstance: true,
                isDeleting: true,
                isRunning: containerInstance.status === "RUNNING"
            }
        });

        dialogRef1.afterClosed().subscribe(result => {
            if (result) {
                if (containerInstance.status === "RUNNING") {
                    const dialogRef2 = this.dialog.open(DeleteRunningContainerModalComponent, {
                        width: '500px',
                        data: {
                            isContainerInstance: true,
                            isDeleting: true,
                            isRunning: containerInstance.status === "RUNNING"
                        }
                    });
                    dialogRef2.afterClosed().subscribe(result => {
                        if (result) {
                            this.isLoadingAction = true;
                            removeRunning = true;
                            const containerInstancesIds: number[] = [];
                            containerInstancesIds.push(containerInstance.id);

                            const options = {
                                params: {removeRunning: removeRunning.toString()}
                            };

                            let url = '/api/containers/instances/delete';

                            this.http.post<Map<String, String>>(url, containerInstancesIds, options)
                                .subscribe({
                                    next: (data) => {
                                        const index = this.dataSource.data.indexOf(containerInstance, 0);
                                        if (index > -1) {
                                            this.dataSource.data.splice(index, 1);
                                            this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
                                            let keyValuePairs = '';
                                            for (const [key, value] of Object.entries(data)) {
                                                keyValuePairs += `Název instance: ${key}. ${value} || `;
                                            }
                                            console.log(keyValuePairs);
                                            const notificationMessage = keyValuePairs;
                                            this.notificationService.notify(notificationMessage);
                                            this.get('instances');
                                        }
                                    },
                                    error: (error) => {
                                        if (!this.httpErrorService.ignoreError(error.status)) {
                                            this.notificationService.notifyError('Smazání selhalo: ' + error.error.error);
                                        }
                                        console.error('Couldn\'t delete because', error);
                                    }
                                }).add(() => {
                                this.isLoadingAction = false;
                            });
                        }
                    });
                } else {
                    this.isLoadingAction = true;
                    removeRunning = true;
                    const containerInstancesIds: number[] = [];
                    containerInstancesIds.push(containerInstance.id);

                    const options = {
                        params: {removeRunning: removeRunning.toString()}
                    };

                    let url = '/api/containers/instances/delete';

                    this.http.post<Map<String, String>>(url, containerInstancesIds, options)
                        .subscribe({
                            next: (data) => {
                                const index = this.dataSource.data.indexOf(containerInstance, 0);
                                if (index > -1) {
                                    this.dataSource.data.splice(index, 1);
                                    this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
                                    let keyValuePairs = '';
                                    for (const [key, value] of Object.entries(data)) {
                                        keyValuePairs += `Název instance: ${key}. ${value} || `;
                                    }
                                    console.log(keyValuePairs);
                                    const notificationMessage = keyValuePairs;
                                    this.notificationService.notify(notificationMessage);
                                    this.get('instances');
                                }
                            },
                            error: (error) => {
                                if (!this.httpErrorService.ignoreError(error.status)) {
                                    this.notificationService.notifyError('Smazání selhalo: ' + error.error.error);
                                }
                                console.error('Couldn\'t delete because', error);
                            }
                        }).add(() => {
                        this.isLoadingAction = false;
                    });
                }
            }
        });
    }

    deleteSelected() {
        //TODO presunut warning modal components do balika ku ktoremu hlasky patria
        const dialogRef = this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {
                isContainerInstances: this.selectedTab === "instances",
                isContainerTemplates: this.selectedTab === "templates",
                isContainerConfigurationFiles: this.selectedTab === "configuration_files",
                isDeleting: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoadingAction = true;
                let idArray: number[];
                let removeRunning = true;
                let options = {};
                let url = '';

                if (this.selectedTab === 'instances') {
                    url = '/api/containers/instances/delete';
                    idArray = this.collectSelectedIds("instances");
                    options = {
                        params: {removeRunning: removeRunning.toString()}
                    };
                } else if (this.selectedTab === 'templates') {
                    url = '/api/containers/templates/delete';
                    idArray = this.collectSelectedIds("templates");
                } else if (this.selectedTab === 'configuration_files') {
                    url = '/api/containers/configuration-file/delete';
                    idArray = this.collectSelectedIds("configuration_files");
                }

                this.http.post<Map<String, String>>(url, idArray, options)
                    .subscribe({
                        next: (data) => {
                            let keyValuePairs = '';
                            for (const [key, value] of Object.entries(data)) {
                                if (this.selectedTab === "instances") {
                                    keyValuePairs += `Název instance: ${key}. ${value} || `;
                                } else if (this.selectedTab === "templates") {
                                    keyValuePairs += `Název šablony: ${key}. ${value} || `;
                                } else {
                                    keyValuePairs += `Název konfiguračního souboru: ${key}. ${value} || `;
                                }
                            }
                            console.log(keyValuePairs);
                            const notificationMessage = keyValuePairs;
                            this.notificationService.notify(notificationMessage);

                            if (this.selectedTab === "instances") {
                                this.get('instances');
                                this.selectedContainerInstances.clear();
                            } else if (this.selectedTab === "templates") {
                                this.get('templates');
                                this.selectedContainerTemplates.clear()
                            } else {
                                this.get('configuration_files');
                                this.selectedContainerConfigurations.clear();
                            }
                        },
                        error: (error) => {
                            console.error('Couldn\'t delete because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                if (this.selectedTab === "instances") {
                                    this.notificationService.notifyError('Chyba při mazání instancí: ' + error.error.error);
                                } else if (this.selectedTab === "templates") {
                                    this.notificationService.notifyError('Chyba při mazání šablon: ' + error.error.error);
                                } else {
                                    this.notificationService.notifyError('Chyba při mazání konfigurací: ' + error.error.error);
                                }
                            }
                        }
                    }).add(() => {
                    this.isLoadingAction = false;
                });
            }
        });
    }

    deleteSelectedContainerInstances() {
        let removeRunning = false;

        let hasRunningContainer = false;
        this.selectedContainerInstances.forEach(containerInstance => {
            if (containerInstance.status === 'RUNNING') {
                hasRunningContainer = true;
            }
        });

        const dialogRef = this.dialog.open(DeleteContainerModalComponent, {
            width: '500px',
            data: {
                isContainerInstances: true,
                isRunning: hasRunningContainer
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoadingAction = true;
                let idArray: number[] = this.collectSelectedIds("instances");
                removeRunning = true;
                let options = {};
                let url = '/api/containers/instances/delete';
                options = {
                    params: {removeRunning: removeRunning.toString()}
                };

                this.http.post<Map<String, String>>(url, idArray, options)
                    .subscribe({
                        next: (data) => {
                            let keyValuePairs = '';
                            for (const [key, value] of Object.entries(data)) {
                                keyValuePairs += `Název instance: ${key}. ${value} || `;
                            }
                            console.log(keyValuePairs);
                            const notificationMessage = keyValuePairs;
                            this.notificationService.notify(notificationMessage);
                            this.get('instances');
                        },
                        error: (error) => {
                            console.error('Couldn\'t delete because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při mazání instancí: ' + error.error.error);
                            }
                        }
                    }).add(() => {
                    this.isLoadingAction = false;
                });
            }
        });
    }

    collectSelectedIds(selectedTab: string) {
        const idArray: number[] = [];

        if (selectedTab === 'instances') {
            this.selectedContainerInstances.forEach((containerInstance) => {
                idArray.push(containerInstance.id);
            });
        } else if (selectedTab === 'templates') {
            this.selectedContainerTemplates.forEach((containterTemplate) => {
                idArray.push(containterTemplate.id);
            });
        } else if (selectedTab === 'configuration_files') {
            this.selectedContainerConfigurations.forEach((containerConfigurationFile) => {
                if (containerConfigurationFile.id !== 1) {
                    idArray.push(containerConfigurationFile.id);
                }
            });
        }
        return idArray;
    }

    switchSelected(object: any) {
        object.selected = !object.selected;
        if (object.selected) {
            if (this.selectedTab === "instances") {
                this.selectedContainerInstances.add(object);
            } else if (this.selectedTab === "templates") {
                this.selectedContainerTemplates.add(object);
            } else if (this.selectedTab === "configuration_files") {
                this.selectedContainerConfigurations.add(object);
            }
        } else {
            if (this.selectedTab === "instances") {
                if (this.selectedContainerInstances.has(object)) {
                    this.selectedContainerInstances.delete(object);
                }
            } else if (this.selectedTab === "templates") {
                if (this.selectedContainerTemplates.has(object)) {
                    this.selectedContainerTemplates.delete(object);
                }
            } else if (this.selectedTab === "configuration_files") {
                if (this.selectedContainerConfigurations.has(object)) {
                    this.selectedContainerConfigurations.delete(object);
                }
            }
        }
        this.indeterminate();
    }

    switchAll() {
        if (this.selectedTab === "instances") {
            this.dataSource = this.containerInstancesDataSource;
            if (this.selectedContainerInstances.size !== this.dataSource.data.length || this.selectedContainerInstances.size === 0) {
                this.selectedContainerInstances = new Set<ContainerInstance>();
                for (const containerInstance of this.dataSource.data) {
                    containerInstance.selected = true;
                    this.selectedContainerInstances.add(containerInstance);
                }
            } else {
                for (const containerInstance of this.dataSource.data) {
                    containerInstance.selected = false;
                }
                this.selectedContainerInstances = new Set<ContainerInstance>();
            }
        } else if (this.selectedTab === "templates") {
            this.dataSource = this.containerTemplatesDataSource;
            if (this.selectedContainerTemplates.size !== this.dataSource.data.length || this.selectedContainerTemplates.size === 0) {
                this.selectedContainerTemplates = new Set<ContainerTemplate>();
                for (const containerTemplate of this.dataSource.data) {
                    containerTemplate.selected = true;
                    if ("tag" in containerTemplate) {
                        this.selectedContainerTemplates.add(containerTemplate);
                    }
                }
            } else {
                for (const containerTemplate of this.dataSource.data) {
                    containerTemplate.selected = false;
                }
                this.selectedContainerTemplates = new Set<ContainerTemplate>();
            }
        } else if (this.selectedTab === "configuration_files") {
            this.dataSource = this.containerConfigurationsDataSource;
            if (this.selectedContainerConfigurations.size !== this.dataSource.data.length || this.selectedContainerConfigurations.size === 0) {
                this.selectedContainerConfigurations = new Set<ContainerConfigurationFile>();
                for (const containerConfigurationFile of this.dataSource.data) {
                    containerConfigurationFile.selected = true;
                    this.selectedContainerConfigurations.add(containerConfigurationFile);
                }
            } else {
                for (const containerConfigurationFile of this.dataSource.data) {
                    containerConfigurationFile.selected = false;
                }
                this.selectedContainerConfigurations = new Set<ContainerConfigurationFile>();
            }
        }
        this.indeterminate();
    }

    indeterminate() {
        if (this.selectedTab === "instances") {
            this.dataSource = this.containerInstancesDataSource;
            this.indeterminateV = this.selectedContainerInstances.size !== 0 && this.dataSource.data.length !== 0
                && this.selectedContainerInstances.size !== this.dataSource.data.length;
        } else if (this.selectedTab === "templates") {
            this.dataSource = this.containerTemplatesDataSource;
            this.indeterminateV = this.selectedContainerTemplates.size !== 0 && this.dataSource.data.length !== 0
                && this.selectedContainerTemplates.size !== this.dataSource.data.length;
        } else if (this.selectedTab === "configuration_files") {
            this.dataSource = this.containerConfigurationsDataSource;
            this.indeterminateV = this.selectedContainerConfigurations.size !== 0 && this.dataSource.data.length !== 0
                && this.selectedContainerConfigurations.size !== this.dataSource.data.length;
        }
    }

    onTabChange(event: MatTabChangeEvent) {
        if (event.index === 0) {
            this.selectedTab = "instances";
            this.tab = "instances";
            // this.get('instances');
        } else if (event.index === 1) {
            this.isLoaded = false;
            this.selectedTab = "templates";
            this.tab = "templates";
            // this.get('templates');
        } else if (event.index === 2) {
            this.isLoaded = false;
            this.selectedTab = "configuration_files";
            this.tab = "configuration_files";
            // this.get('configuration_files');
        } else if (event.index === 3) {
            this.isLoaded = false;
            this.selectedTab = "server_configuration";
            this.tab = "server_configuration";
            // this.get('server_configuration');
        }
        this.router.navigate(['list-containers', this.tab]);
    }

    ngOnDestroy() {
        if (this.listContainersSubscription) {
            this.listContainersSubscription.unsubscribe();
        }
    }

    openCertificationAuthorityCertificateModal(certificationAuthorityCertificate: string): void {
        this.certificationAuthorityCertificate = certificationAuthorityCertificate;
        const dialogRef = this.dialog.open(CertificationConfigurationCertificateModalComponent, {
            data: {
                isCertificationConfigurationCertificate: true,
                certificationAuthorityCertificate: this.certificationAuthorityCertificate
            }
        });
    }

    openCertificateModal(certificate: string): void {
        this.certificate = certificate;
        const dialogRef = this.dialog.open(CertificateModalComponent, {
            data: {
                isCertificate: true,
                certificate: this.certificate
            }
        });
    }

    openPublicKeyModal(publicKey: string): void {
        this.publicKey = publicKey;
        const dialogRef = this.dialog.open(PublicKeyModalComponent, {
            data: {
                isPublicKey: true,
                publicKey: this.publicKey
            }
        });
    }

    openPortsMappingModal(portsMapping: Array<any>) {
        this.portsMapping = portsMapping;
        const dialogRef = this.dialog.open(PortsMappingModalComponent, {
            width: '250px',
            data: {
                isPortsMapping: true,
                portsMapping: this.portsMapping
            }
        });
    }

    showInstances(templateId: number) {
        this.isLoaded = true;
        this.selectedTemplateId = templateId;
        this.router.navigate(['list-containers', 'instances']);
    }
}
