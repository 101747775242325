<mat-card id="list-instances" class="table-filter">
    <mat-card-content class="table-filter-content">
        <mat-form-field>
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Vyhledat">
        </mat-form-field>
    </mat-card-content>
</mat-card>



<mat-tab-group id="list-containers" [selectedIndex]="activeTab" (selectedTabChange)="onTabChange($event)"
               *ngIf="!isLoading">
    <mat-tab label="Instance kontejnerů">
        <table mat-table [dataSource]="containerInstancesDataSource" matSort class="mat-elevation-z8">

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Název</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Status</th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Project name Column -->
            <ng-container matColumnDef="projectName">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Název projektu</th>
                <td mat-cell *matCellDef="let element"> {{element.project?.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Container server id Column -->
            <ng-container matColumnDef="containerServerId">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Id na serveru</th>
                <td mat-cell *matCellDef="let element"> {{element.containerServerId}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Ports Column -->
            <ng-container matColumnDef="ports">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Mapování portů<br>(
                    portContainer : portServer )
                </th>
                <td mat-cell
                    style="display: flex; flex-direction: row; align-items: center; justify-content: center; height: 52px;"
                    *matCellDef="let element">
                    <button mat-raised-button
                            [disabled]="isLoadingAction"
                            (click)="openPortsMappingModal(element.portsMapping)">
                        Zobrazit
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Owner Column -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Vlastník</th>
                <td mat-cell *matCellDef="let element"> {{element.owner?.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Selected Column -->
            <ng-container matColumnDef="selected">
                <th mat-header-cell style="text-align: center; vertical-align: middle; width: 5%;" *matHeaderCellDef>
                    <mat-checkbox
                        [disabled]="isLoadingAction"
                        [(indeterminate)]="indeterminateV" (change)="switchAll()"
                        [checked]="selectedContainerInstances.size === dataSource.data.length && dataSource.data.length !== 0">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center; vertical-align: middle;">
                    <mat-checkbox
                        [disabled]="isLoadingAction"
                        (change)="switchSelected(element)"
                        [checked]="element.selected"></mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
                </td>
            </ng-container>

            <!-- Action buttons Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell style="width: 12%; text-align: center;" *matHeaderCellDef>
                    <div style="margin: 10px 0px">
                        <button mat-raised-button [disabled]="selectedContainerInstances.size === 0 || isLoadingAction"
                                [matMenuTriggerFor]="actionMenuBottom">
                            Akce
                            <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
                        </button>
                        <mat-menu #actionMenuBottom="matMenu">
                            <button mat-menu-item (click)="runSelected()">
                                Spustit vybrané
                            </button>
                            <button mat-menu-item (click)="stopSelected()">
                                Zastavit vybrané
                            </button>
                            <button mat-menu-item (click)="deleteSelectedContainerInstances()">
                                Smazat vybrané
                            </button>
                        </mat-menu>
                    </div>
                    <div style="margin: 10px 0px">
                        <button mat-raised-button [disabled]="isLoadingAction" (click)="onSubmit()">
                            Vytvořit instanci
                        </button>
                    </div>
                </th>
                <td mat-cell *matCellDef="let containerInstance"
                    [attr.id]="'container-' + containerInstance.id"
                    style="display: flex; justify-content: center; align-items: center; overflow: visible; height: 69px; text-align: center;">
                    <button
                        mat-icon-button
                        [disabled]="isLoadingAction"
                        matTooltip="{{containerInstance.status === 'RUNNING' ? 'Container is running. Click for deactivation.' : 'Container is stopped. Click for activation.'}}"
                        [ngClass]="containerInstance.status === 'RUNNING' ? 'btn-deactivate' : 'btn-activate'"
                        (click)="containerInstance.status === 'RUNNING' ? stopContainerInstance(containerInstance.id) : runContainerInstance(containerInstance.id)">
                        <span><mat-icon
                            class="blue"> {{ containerInstance.status === 'RUNNING' ? 'stop_circle_outline' : 'play_circle_outline' }}</mat-icon></span>
                    </button>
                    <button mat-raised-button [disabled]="isLoadingAction" (click)="deleteInstance(containerInstance)">
                        Smazat
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center;">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsContainerInstances"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsContainerInstances;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsContainerInstances"></tr>
        </table>
    </mat-tab>
    <mat-tab label="Šablony kontejnerů">
        <table mat-table [dataSource]="containerTemplatesDataSource" matSort class="mat-elevation-z8">
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Název</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Tag Column -->
            <ng-container matColumnDef="tag">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Tag</th>
                <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Owner Column -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Vlastník</th>
                <td mat-cell *matCellDef="let element"> {{element.owner?.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Instance Column -->
            <ng-container matColumnDef="containerInstance">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Instance kontejnerů</th>
                <td mat-cell
                    *matCellDef="let template">
                    <button mat-raised-button [disabled]="isLoadingAction" (click)="showInstances(template.id)">
                        Zobrazit
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Container server id Column -->
            <ng-container matColumnDef="containerServerId">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Id na serveru</th>
                <td mat-cell *matCellDef="let element"> {{element.templateServerId}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Selected Column -->
            <ng-container matColumnDef="selected">
                <th mat-header-cell style="text-align: center; vertical-align: middle; width: 5%;" *matHeaderCellDef>
                    <mat-checkbox [disabled]="isLoadingAction" [(indeterminate)]="indeterminateV" (change)="switchAll()"
                                  [checked]="selectedContainerTemplates.size === dataSource.data.length && dataSource.data.length !== 0">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center; vertical-align: middle;">
                    <ng-container *ngIf="isAdmin || (element.id != basicContainerTemplateId)">
                        <mat-checkbox [disabled]="isLoadingAction" (change)="switchSelected(element)"
                                      [checked]="element.selected"></mat-checkbox>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
                </td>
            </ng-container>

            <!-- Action buttons Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell style="width: 12%; text-align: center;" *matHeaderCellDef>
                    <div style="margin: 10px 0px">
                        <button mat-raised-button [disabled]="selectedContainerTemplates.size === 0 || isLoadingAction"
                                (click)="deleteSelected()">Smazat vybrané
                        </button>
                    </div>
                    <div style="margin: 10px 0px">
                        <button mat-raised-button [disabled]="isLoadingAction" (click)="onSubmit()">
                            Vytvořit šablonu
                        </button>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element"
                    style="overflow: visible; text-align: center;">
                    <ng-container *ngIf="isAdmin">
                        <button mat-raised-button [disabled]="isLoadingAction" (click)="delete(element)">Smazat</button>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center;">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsContainerTemplates"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsContainerTemplates;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsContainerTemplates"></tr>
        </table>
    </mat-tab>
    <mat-tab label="Konfigurační soubory kontejnerů">
        <table mat-table [dataSource]="containerConfigurationsDataSource" matSort class="mat-elevation-z8">
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Název</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Owner Column -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Vlastník</th>
                <td mat-cell *matCellDef="let element"> {{element.owner?.name}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Selected Column -->
            <ng-container matColumnDef="selected">
                <th mat-header-cell style="text-align: center; vertical-align: middle; width: 5%;" *matHeaderCellDef>
                    <mat-checkbox [disabled]="isLoadingAction" [(indeterminate)]="indeterminateV" (change)="switchAll()"
                                  [checked]="selectedContainerConfigurations.size === dataSource.data.length && dataSource.data.length !== 0">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center; vertical-align: middle;">
                    <ng-container *ngIf="isAdmin || (element.id != basicConfigurationFileId)">
                        <mat-checkbox [disabled]="isLoadingAction" (change)="switchSelected(element)"
                                      [checked]="element.selected"></mat-checkbox>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
                </td>
            </ng-container>

            <!-- Action buttons Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell style="width: 12%; text-align: center;" *matHeaderCellDef>
                    <div style="margin: 10px 0px">
                        <button mat-raised-button
                                [disabled]="selectedContainerConfigurations.size === 0 || isLoadingAction"
                                (click)="deleteSelected()">Smazat vybrané
                        </button>
                    </div>
                    <div style="margin: 10px 0px">
                        <button mat-raised-button [disabled]="isLoadingAction" (click)="onSubmit()">
                            Vytvořit konfiguraci kontejneru
                        </button>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element"
                    style="overflow: visible; text-align: center;">
                    <ng-container *ngIf="element.id == basicConfigurationFileId && isAdmin">
                        <button mat-raised-button
                                [disabled]="isLoadingAction"
                                [matMenuTriggerFor]="actionMenuBottom">
                            Akce
                            <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
                        </button>
                    </ng-container>
                    <mat-menu #actionMenuBottom="matMenu">
                        <button mat-menu-item (click)="show(element.id)">
                            Zobrazit
                        </button>
                        <button mat-menu-item (click)="edit(element.id)">
                            Upravit
                        </button>
                        <button mat-menu-item (click)="delete(element)">
                            Smazat
                        </button>
                    </mat-menu>
                    <ng-container *ngIf="element.id == basicConfigurationFileId && !isAdmin">
                        <button mat-raised-button
                                [disabled]="isLoadingAction"
                                (click)="show(element.id)">
                            Zobrazit
                        </button>
                    </ng-container>
                    <ng-container *ngIf="element.id !== basicConfigurationFileId">
                        <button mat-raised-button
                                [disabled]="isLoadingAction"
                                [matMenuTriggerFor]="actionMenuBottom">
                            Akce
                            <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
                        </button>
                    </ng-container>
                    <mat-menu #actionMenuBottom="matMenu">
                        <button mat-menu-item (click)="show(element.id)">
                            Zobrazit
                        </button>
                        <button mat-menu-item (click)="edit(element.id)">
                            Upravit
                        </button>
                        <button mat-menu-item (click)="delete(element)">
                            Smazat
                        </button>
                    </mat-menu>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: center;">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsContainerConfigurations"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsContainerConfigurations;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsContainerConfigurations"></tr>
        </table>
    </mat-tab>
</mat-tab-group>
<mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
<mat-spinner class="loading" *ngIf="isLoadingAction"></mat-spinner>


