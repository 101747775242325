import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppStorage} from "./app.storage";
import {BugReportSnackBar} from "../bug-report-snackbar/bug-report-snackbar.component";

@Injectable({providedIn: 'root'})
export class NotificationService {
    constructor(private snackBar: MatSnackBar) {
    }

    public notify(text: string) {
        setTimeout(() => this.snackBar.open(text, 'Zavřít'));
    }

    public notifyError(text: string) {
        setTimeout(() => this.snackBar.openFromComponent(BugReportSnackBar, {
            data: {
                errorMessage: text,
                bugReportUrl: AppStorage.getBugReportUrl()
            },
        }));
    }

    public clearNotification(errorMessage: string = null) {
        if (this.snackBar) {
            this.snackBar.dismiss();
        }
        if (errorMessage) {
            this.notify(errorMessage);
        }
    }

}
