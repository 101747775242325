<div mat-dialog-content class="center" *ngIf="data.isBackup">
    <p class="center" *ngIf="data.isDeleting">Opravdu chcete smazat vybranou zálohu? Tato operace již nelze vrátit.</p>
    <p class="center" *ngIf="!data.isDeleting">Opravdu chcete obnovit databázi z vybrané zálohy? Tato operace nenávratně
        upraví databázi.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isBackups">
    <p class="center">Opravdu chcete smazat vybrané zálohy? Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isProject">
    <p class="center">Opravdu chcete smazat vybraný projekt?
        Zálohy, spojené s databázemi tohoto projektu, se taktéž smažou.<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isProjects">
    <p class="center">Opravdu chcete smazat vybrané projekty?
        Zálohy, spojené s databázemi těchto projektů, se taktéž smažou.<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isDatabase">
    <p class="center">Opravdu chcete smazat vybranou databázi?
        Pokud má databáze zálohy, taktéž se smažou.<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerInstance">
    <p class="center">Opravdu chcete smazat vybranou instanci?
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerInstances">
    <p class="center">Opravdu chcete smazat vybrané instance?
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerTemplate">
    <p class="center">Opravdu chcete smazat vybranou šablonu?<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerTemplates">
    <p class="center">Opravdu chcete smazat vybrané šablony?<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerConfigurationFile">
    <p class="center">Opravdu chcete smazat vybranou konfiguraci?<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerConfigurationFiles">
    <p class="center">Opravdu chcete smazat vybrané konfigurace?<br>
        Tato operace již nelze vrátit.</p>
</div>
<div mat-dialog-actions class="modal-buttons center">
    <button mat-button (click)="onSubmit()">Ano</button>
    <button mat-button (click)="onClose()">Ne</button>
</div>

