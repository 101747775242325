import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Backup} from '../dm/backup';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {WarningModalComponent} from './warning-modal/warning-modal.component';
import {MatSort} from '@angular/material/sort';
import {UntypedFormControl} from '@angular/forms';
import * as fileSaver from 'file-saver';
import {HttpErrorService} from '../services/http.error.service';

@Component({
    selector: 'app-list-backups',
    templateUrl: './list-backups.component.html',
    styleUrls: ['./list-backups.component.css']
})
export class ListBackupsComponent implements OnInit {
    selectedBackups = new Set();
    dataSource = new MatTableDataSource<Backup>();
    displayedColumns: string[] = ['backupId', 'projectName', 'dbType', 'databaseId', 'description', 'whenCreated', 'deleteTime', 'selected', 'actions'];
    indeterminateV = false;
    backupFilter = '';
    backupFilterControl = new UntypedFormControl();

    constructor(private http: HttpClient,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private httpErrorService: HttpErrorService
    ) {
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }
    ngOnInit() {
        this.getBackups();
        this.dataSource.filterPredicate = (backup: Backup, filter: string = '') =>
            (
                ((this.backupFilter === 'exists') ? backup.databaseId : true) &&
                ((this.backupFilter === 'doesntExist') ? !backup.databaseId : true)
            );

        this.backupFilter = sessionStorage.getItem('backupFilter');
        this.backupFilterControl.setValue(sessionStorage.getItem('backupFilter'));
    }

    filterDatabase(filter: string) {
        if (filter) {
            this.backupFilter = filter;
            sessionStorage.setItem('backupFilter', this.backupFilter);
        } else {
            sessionStorage.setItem('backupFilter', '');
            this.backupFilter = '';
        }
        this.doFilter(this.dataSource.filter);
    }

    doFilter(query: string) {
        if (query) {
            this.dataSource.filter = query.trim().toLocaleLowerCase();
        } else {
            this.dataSource.filter = '_';
        }
    }

    getBackups() {
        this.http.get<Backup[]>('/api/backup/all')
            .subscribe(
                data => {
                    this.dataSource.data = data;
                    this.selectedBackups = new Set();
                    this.indeterminate();
                },
                error => {
                    console.log('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání záloh: [' + error.response + '].');
                    }
                }
            );
    }

    switchAll() {
        if (this.selectedBackups.size < this.dataSource.filteredData.length) {
            for (const backup of this.dataSource.filteredData) {
                backup.selected = true;
                this.selectedBackups.add(backup);
            }
        } else {
            for (const backup of this.dataSource.filteredData) {
                backup.selected = false;
                this.selectedBackups.delete(backup);
            }
        }
        this.indeterminate();
    }

    switchSelected(backup: Backup) {
        backup.selected = !backup.selected;
        if (backup.selected) {
            this.selectedBackups.add(backup);
        } else {
            this.selectedBackups.delete(backup);
        }
        this.indeterminate();
    }

    indeterminate() {
        this.indeterminateV = this.selectedBackups.size !== 0 && this.dataSource.data.length !== 0
            && this.selectedBackups.size !== this.dataSource.data.length;
    }

    collectSelectedIds() {
        const idArray: number[] = [];
        this.selectedBackups.forEach((backup: Backup) => {
            idArray.push(backup.backupId);
        });
        return idArray;
    }

    downloadSelected(id: number) {
        let idArray: number[] = [];
        if (id !== -1) {
            idArray.push(id);
        } else {
            idArray = idArray.concat(this.collectSelectedIds());
        }

        this.http.post('/api/backup/download', {ids: idArray},
            {responseType: 'blob'})
            .subscribe(data => {
                    fileSaver.saveAs(data, 'backups.zip');
                    console.log('Download successful.');
                },
                error => {
                    console.log('Couldn\'t download backups because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při stahování záloh: [' + error.error.message + '].');
                    }
                }
            );
    }

    deleteSelected(id: number) {
        let idArray: number[] = [];
        if (id !== -1) {
            idArray.push(id);
        } else {
            idArray = idArray.concat(this.collectSelectedIds());
        }

        const dialogRef = this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {isBackups: true}
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.http.post<Array<number>>('/api/backup/delete', {ids: idArray})
                    .subscribe(
                        data => {
                            console.log('Delete successful ', data);
                            this.notificationService.notify('Smazání záloh proběhlo úspěšně.');
                            this.getBackups();
                        },
                        error => {
                            console.error('Couldn\'t delete because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při mazání zálohy: [' + error.error.message + '].');
                            }
                        });
            }
        });
    }

    warningDialog(isBackup: boolean, isDeleting: boolean) {
        return this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {isBackup, isDeleting}
        });
    }

    restoreSelected(id: number) {
        let idArray: number[] = [];
        if (id !== -1) {
            idArray.push(id);
        } else {
            idArray = idArray.concat(this.collectSelectedIds());
        }

        this.warningDialog(true, false).afterClosed().subscribe(result => {
            if (result) {
                this.http.post('/api/backup/restore', {ids: idArray})
                    .subscribe(
                        data => {
                            this.notificationService.notify('Obnova zálohy proběhla úspěšně.');
                        },
                        error => {
                            console.error('Couldn\'t post because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při obnově zálohy: [' + error.error.message + '].');
                            }
                        });
            }
        });
    }
}
