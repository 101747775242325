import { EventEmitter, Injectable, Output, Directive } from '@angular/core'
import {HttpClient} from "@angular/common/http";
import {DatabaseNotification} from "../dm/databaseNotification";

@Directive()
@Injectable({
    providedIn: 'root'
})
export class DatabaseNotificationService {

    notificationActive = false;
    notificationString: string;
    @Output() changed: EventEmitter<String> = new EventEmitter();

    constructor(private http: HttpClient) {
    }

    checkNotification() {
        console.log('Checking database notifications.');
        this.http.get<DatabaseNotification[]>('/api/database/notifications')
            .subscribe(
                data => {
                    if (data.length != 0) {
                        this.notificationActive = true;
                        this.notificationString = data[0].description; //TODO select multiple notifications, make this a universal service
                        this.changed.emit(data[0].description);
                    } else {
                        this.notificationActive = false;
                    }
                },
                error => {
                    console.error('Error while getting database notifications: ', error);
                }
            );

    }

}
