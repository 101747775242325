import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Person} from '../dm/person';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from "../services/authentication.service";
import {HttpErrorService} from "../services/http.error.service";

@Component({
    selector: 'app-person',
    templateUrl: './person.component.html',
    styleUrls: ['./person.component.css']
})
export class PersonComponent implements OnInit {
    editPersonId: number;
    person: Person;
    toggleableDatabasePassword: string;
    personForm = this.fb.group({
        id: [null],
        name: [null, Validators.required],
        password: [null, Validators.maxLength(71)],
        email: [null, [
            Validators.required,
            Validators.email
        ]],
        loginCVUT: [null, [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern('^[ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z][ÁČĎÉĚÍŇÓŘŠŤÚŮÝŽáčďéěíňóřšťúůýža-zA-Z0-9_ ]*$')
        ]],
        role: ['STUDENT', Validators.required],
    });
    @Output() personCreatedEmitter: EventEmitter<Person> = new EventEmitter();

    roles = ['STUDENT', 'EXTERNIST'];

    constructor(private fb: UntypedFormBuilder,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private notificationService: NotificationService,
                private authenticationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        if (this.authenticationService.isLoggedUserAdmin) {
            this.roles = ['ADMINISTRATOR', 'TEACHER', 'STUDENT', 'EXTERNIST'];
        }
        if (this.route.routeConfig.component.name == this.constructor.name) {
            this.route.params.subscribe((params: Params) => this.editPersonId = params['id']);
        }
        if (this.editPersonId) {
            this.http.get<Person>('/api/person/' + this.editPersonId)
                .subscribe(
                    data => {
                        this.person = data;
                        this.personForm.patchValue({
                            id: this.person.id,
                            name: this.person.name,
                            email: this.person.email,
                            loginCVUT: this.person.loginCVUT,
                            role: this.person.role
                        });
                        this.personForm.controls['name'].markAsTouched();
                        this.personForm.controls['email'].markAsTouched();
                        this.personForm.controls['loginCVUT'].markAsTouched();
                        this.personForm.controls['role'].markAsTouched();
                    },
                    error => {
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Chyba při načítání dat: [' + error.status + '].');
                        }
                        console.error('Couldn\'t get because', error);
                    }
                );
            this.toggleableDatabasePassword = "*******";
        }
        const passwordControl = this.personForm.get('password');
        passwordControl.setValidators([Validators.maxLength(71)]);
    }

    onSubmit() {
        let serializedForm;
        let url;
        const values = this.personForm.getRawValue();
        serializedForm = JSON.stringify(values);
        console.log(serializedForm);

        if (this.editPersonId) {
            url = '/api/person/update/' + this.editPersonId;
        } else {
            url = '/api/person/create';
        }

        console.log(serializedForm);

        this.http.post<Person>(url, serializedForm, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')})
            .subscribe(
                data => {
                    console.log('success!', data);
                    if (this.editPersonId) {
                        this.notificationService.notify('Úspěšně upraveno.');
                    } else {
                        this.notificationService.notify('Úspěšně vytvořeno.');
                    }

                    // turns off redirect to updated person if component is used outside router outlet (eg. for creating person inside create-project)
                    if (this.route.routeConfig.component.name == this.constructor.name) {
                        this.router.navigate(['./person', data.id]);
                    }
                    this.personCreatedEmitter.emit(data);
                },
                error => {
                    console.error('Couldn\'t post because', error);
                    if (error.status == 400) {
                        this.notificationService.notifyError('Chyba při vytváření: [duplicitní uživatelské jméno ČVUT].');
                    } else if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při ukládání: [' + error.status + '].');
                    }
                }
            );
    }

    public isEdit(): boolean {
        return !!this.editPersonId;
    }

    private showPersonsName(): string {
        if (this.person !== undefined && this.person.loginCVUT !== undefined) {
            return this.person.loginCVUT;
        }
        return '';
    }

    copyDBPasswordToClipboard() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.person.databaseRolePassword;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notificationService.notify('Zkopírováno do clip-boardu.');
    }

    showPassword() {
        this.toggleableDatabasePassword = this.person.databaseRolePassword;
    }
}
