import {GitlabPerson} from './gitlabPerson';

export class GitlabProject {
    name: string;
    description: string;
    web_url: string;
    id: number;
    members: GitlabPerson[];
    selected: boolean;
    tags: string[];
    course: string;
}
