<form [formGroup]="templateForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'Upravit šablonu: ' + showTemplateName()}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field class="full-width">
                <textarea class="textarea"
                          onkeydown="this.autosize()"
                          matInput
                          formControlName="text"
                          value={{showTemplateText()}}>
                </textarea>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!templateForm.valid" mat-raised-button color="primary"
                    type="submit">Uložit</button>

        </mat-card-actions>
        <mat-card-content>
            <mat-card-title>
                <br>
                Nápověda:
                <br>
                <pre>{{showTemplateHelp()}}</pre>
            </mat-card-title>
        </mat-card-content>

    </mat-card>
</form>
