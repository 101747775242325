import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Database} from '../dm/database';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';
import {NotificationService} from '../services/notification.service';
import {MatTableDataSource} from '@angular/material/table';
import {Backup} from '../dm/backup';
import {CommitMessageModalComponent} from '../list-databases/commit-message-modal/commit-message-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {WarningModalComponent} from '../list-backups/warning-modal/warning-modal.component';
import * as fileSaver from 'file-saver';
import {DatabaseData} from '../dm/databaseData';
import {HttpErrorService} from '../services/http.error.service';
import {BackupUploadModalComponent} from "../list-databases/backup-upload-modal/backup-upload-modal.component";


@Component({
    selector: 'app-detail-database',
    templateUrl: './detail-database.component.html',
    styleUrls: ['./detail-database.component.css']
})
export class DetailDatabaseComponent implements OnInit {
    databaseId: number;
    databaseData: DatabaseData;
    indeterminateV = false;
    selectedBackups = new Set();

    dataSource = new MatTableDataSource<Backup>();
    displayedColumns: string[] = ['backupId', 'description', 'whenCreated', 'selected', 'actions'];

    @ViewChild(MatSort) sort: MatSort;

    readonly dateViewFormat = 'd/M/yyyy';

    constructor(private http: HttpClient,
                private router: Router,
                private notificationService: NotificationService,
                private authentificationService: AuthenticationService,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
        if (this.route.routeConfig.component.name === this.constructor.name) {
            this.route.params.subscribe((params: Params) => {
                this.databaseId = params.id;
                this.getDatabase();
                this.getBackupRulesByDatabaseId();
            });
        }
    }

    getDatabase() {
        this.http.get<DatabaseData>(`/api/database/data/${this.databaseId}`)
            .subscribe(
                data => {
                    this.databaseData = data;
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání databází: [' + error.response + '].');
                    }
                }
            );
    }

    getBackupRulesByDatabaseId() {
        this.http.get<Backup[]>(`/api/backup/by-database/${this.databaseId}`)
            .subscribe(
                data => {
                    this.dataSource.data = data.filter(backup => backup.databaseId === +this.databaseId);
                    this.selectedBackups = new Set();
                    this.indeterminate();
                },
                error => {
                    console.log('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání záloh: [' + error.response + '].');
                    }
                }
            );
    }

    setBackupMessage(id: number) {
        const dialogRef = this.dialog.open(CommitMessageModalComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(message => {
            if (message) {
                this.backup(id, message);
            }
        });
    }

    backup(id: number, message: string) {
        const url = '/api/backup/create/' + id;
        const database = {
            backupId: 0,
            databaseId: 0,
            dbType: '',
            deleteTime: '',
            description: message,
            projectName: '',
            whenCreated: '',
        };
        const serialized = JSON.stringify(database);

        this.http.post(url, serialized, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')})
            .subscribe(
                data => {
                    this.notificationService.notify('Záloha proběhla úspěšně.');
                    this.getBackupRulesByDatabaseId();
                },
                error => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při zálohování: [' + error.error.message + '].');
                    }
                });
    }

    switchAll() {
        if (this.selectedBackups.size < this.dataSource.filteredData.length) {
            for (const backup of this.dataSource.filteredData) {
                backup.selected = true;
                this.selectedBackups.add(backup);
            }
        } else {
            for (const backup of this.dataSource.filteredData) {
                backup.selected = false;
                this.selectedBackups.delete(backup);
            }
        }
        this.indeterminate();
    }

    switchSelected(backup: Backup) {
        backup.selected = !backup.selected;
        if (backup.selected) {
            this.selectedBackups.add(backup);
        } else {
            this.selectedBackups.delete(backup);
        }
        this.indeterminate();
    }

    indeterminate() {
        this.indeterminateV = this.selectedBackups.size !== 0 && this.dataSource.data.length !== 0
            && this.selectedBackups.size !== this.dataSource.data.length;
    }

    warningDialog(isBackup: boolean, isDeleting: boolean) {
        return this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {isBackup, isDeleting}
        });
    }

    collectSelectedIds() {
        const idArray: number[] = [];
        this.selectedBackups.forEach((backup: Backup) => {
            idArray.push(backup.backupId);
        });
        return idArray;
    }

    restoreSelected(id: number) {
        let idArray: number[] = [];
        if (id !== -1) {
            idArray.push(id);
        } else {
            idArray = idArray.concat(this.collectSelectedIds());
        }
        this.warningDialog(true, false).afterClosed().subscribe(result => {
            if (result) {
                this.http.post('/api/backup/restore', {ids: idArray})
                    .subscribe(
                        data => {
                            this.notificationService.notify('Obnova zálohy proběhla úspěšně.');
                        },
                        error => {
                            console.error('Couldn\'t post because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při obnově zálohy: [' + error.error.message + '].');
                            }
                        });
            }
        });
    }

    downloadSelected(id: number) {
        let idArray: number[] = [];
        if (id !== -1) {
            idArray.push(id);
        } else {
            idArray = idArray.concat(this.collectSelectedIds());
        }
        this.http.post('/api/backup/download', {ids: idArray},
            {responseType: 'blob'})
            .subscribe(data => {
                    fileSaver.saveAs(data, 'backups.zip');
                    console.log('Download successful.');
                },
                error => {
                    console.log('Couldn\'t download backups because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při stahování záloh: [' + error.error.message + '].');
                    }
                }
            );
    }

    deleteSelected(id: number) {
        let idArray: number[] = [];
        if (id !== -1) {
            idArray.push(id);
        } else {
            idArray = idArray.concat(this.collectSelectedIds());
        }

        this.warningDialog(true, true).afterClosed().subscribe(result => {
            if (result) {
                this.http.post<Array<number>>('/api/backup/delete', {ids: idArray})
                    .subscribe(
                        data => {
                            console.log('Delete successful ', data);
                            this.notificationService.notify('Smazání záloh proběhlo úspěšně.');
                            this.getBackupRulesByDatabaseId();
                        },
                        error => {
                            console.error('Couldn\'t delete because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při mazání zálohy: [' + error.error.message + '].');
                            }
                        });
            }
        });
    }

    redirectToProject(id: number) {
        this.router.navigate(['project', id]);
    }

    getDatabaseType(databaseName: string) {
        return (databaseName.includes('_project_db') ? 'project' : 'ea');
    }

    uploadBackup(id: number) {
        const dialogRef = this.dialog.open(BackupUploadModalComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(payload => {
            if (payload) {
                let fd = new FormData();
                fd.append('file', payload.file);
                this.http.post(`/api/backup/upload/${id}?description=${payload.description}`, fd).subscribe(data => {
                        this.notificationService.notify('Nahrání zálohy proběhlo úspěšně.');
                        this.getBackupRulesByDatabaseId();
                    }, error => {
                        this.notificationService.notifyError(`${error.error.message}`);
                    }
                );
            }
        })
    }
}
