import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from '../services/authentication.service';
import {HttpErrorService} from "../services/http.error.service";
import {EmailTemplate} from "../dm/emailTemplate";
import {UntypedFormBuilder} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';


@Component({
    selector: 'app-edit-template',
    templateUrl: './edit-template.component.html',
    styleUrls: ['./edit-template.component.css']
})
export class EditTemplateComponent implements OnInit {
    emailTemplate: EmailTemplate;
    templateForm = this.formBuilder.group({
        id: [null],
        name: [null],
        text: [null]
    })

    constructor(private formBuilder: UntypedFormBuilder,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private notificationService: NotificationService,
                private authentificationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        let id;
        this.route.params.subscribe((params: Params) => id = params['id']);
        this.getTemplate(id);
    }

    getTemplate(id: number) {
        this.http.get<EmailTemplate>('/api/template/' + id)
            .subscribe(
                data => {
                    this.emailTemplate = data;
                    this.templateForm.patchValue({
                            id: this.emailTemplate.id,
                            name: this.emailTemplate.name,
                            text: this.emailTemplate.text
                        }
                    )
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání emailové šablony: [' + error.response + '].');
                    }
                }
            );
    }

    showTemplateName(): string {
        return this.emailTemplate.name;
    }

    showTemplateText(): string {
        return this.emailTemplate.text;
    }

    showTemplateHelp(): string {
        return this.emailTemplate.help;
    }

    onSubmit() {
        let serializedForm = JSON.stringify(this.templateForm.getRawValue());
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

        this.http.post<EmailTemplate>(
            `api/template/update/${this.emailTemplate.id}`,
            serializedForm,
            {headers: headers}
        ).subscribe(
            data => {
                console.log('successfully update email template', data);
                this.notificationService.notify('Úspěšně upraveno.');
                this.router.navigate(['./list-templates']);
            },
            error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při načítání dat: [' + error.status + '].');
                }
                console.error('Couldn\'t get because', error);
            }
        );

    }
}
