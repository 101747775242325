import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project} from '../dm/project';
import {NavigationExtras, Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {MatSort} from '@angular/material/sort';
import {MatSortModule} from "@angular/material/sort";
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ManageTagsComponent} from '../manage-tags/manage-tags.component';
import {UntypedFormControl} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
import {ArchiveModalComponent} from '../project/archive-modal/archive-modal.component';
import {Archive} from '../dm/archive';
import {WarningModalComponent} from '../list-backups/warning-modal/warning-modal.component';
import * as fileSaver from 'file-saver';
import {OdbcExportComponent} from './odbc-export-modal/odbc-export-modal.component';
import {HttpErrorService} from "../services/http.error.service";
import {CreateContainerModalComponent} from "./create-container-modal/create-container-modal.component";
import {IdArrayService} from "./id-array-service/id-service";
import {ContainerInstance} from "../dm/containerInstance";

@Component({
    selector: 'app-list-projects',
    templateUrl: './list-projects.component.html',
    styleUrls: ['./list-projects.component.css'],
})
export class ListProjectsComponent implements OnInit {
    selectedProjects = new Set();
    dataSource = new MatTableDataSource<Project>([]);
    displayedColumns: string[] = ['id', 'name', 'databaseProject', 'container', 'sonarQube', 'leader', 'course', 'enrolledStudents', 'gitlabId', 'tags', 'selected', 'actions'];
    indeterminateV = false;
    courses = new Set();
    leaders = new Set();
    semesters = [];
    tags = new Set();
    basicFilter = '';
    courseFilter = '';
    leaderFilter = '';
    personFilter = '';
    archiveFilter = 'NONE';
    tagFilter = '';
    basicFilterControl = new UntypedFormControl();
    courseFilterControl = new UntypedFormControl();
    leaderFilterControl = new UntypedFormControl();
    personFilterControl = new UntypedFormControl();
    archiveFilterControl = new UntypedFormControl();
    tagFilterControl = new UntypedFormControl();
    containerInstances: ContainerInstance[];
    isLoaded = false;
    selectedProjectId: number;
    selectedFilter = 'Nearchivované';

    constructor(private http: HttpClient,
                private router: Router,
                private notificationService: NotificationService,
                public authenticationService: AuthenticationService,
                public dialog: MatDialog,
                private httpErrorService: HttpErrorService,
                private idArrayService: IdArrayService) {
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit() {
        this.obtainSemesterLabels();
        this.archiveFilterControl.setValue('Nearchivované', {onlySelf: true, emitEvent: false});
        this.getProjects();
        this.filterArchive("NONE");
        this.dataSource.filterPredicate = (project: Project, filter: string = '') =>
            (
                ((this.courseFilter) ? (project.course.name === this.courseFilter) : true) &&
                ((this.authenticationService.isLoggedUserAdmin) ? true : (project.leader.id === this.authenticationService.loggedUserID)) &&
                ((this.leaderFilter) ? (project.leader.name === this.leaderFilter) : true) &&
                ((this.archiveFilter === 'NONE') ? (project.archive === null) : true) &&
                ((this.archiveFilter !== 'NONE' && this.archiveFilter !== 'ALL') ? (project.archive === this.archiveFilter) : true) &&
                ((this.tagFilter) ? (this.projectHasTag(project, this.tagFilter)) : true) &&
                (project.id.toString().indexOf(filter) !== -1 ||
                    project.name.toLowerCase().indexOf(filter) !== -1 ||
                    (project.databaseEA === null || project.databaseEA.name.toLowerCase().indexOf(filter) !== -1) ||
                    project.description.toLowerCase().indexOf(filter) !== -1
                ) &&
                ((this.personFilter) ? this.filterPersonFromProject(project, this.personFilter) : true)
            )
        ;
        this.dataSource.filter = sessionStorage.getItem('basicFilter') || '_';

        this.courseFilter = sessionStorage.getItem('courseFilter');
        this.leaderFilter = sessionStorage.getItem('leaderFilter');
        this.personFilter = sessionStorage.getItem('personFilter');
        this.archiveFilter = sessionStorage.getItem('archiveFilter');
        this.tagFilter = sessionStorage.getItem('tagFilter');


        this.basicFilterControl.setValue(sessionStorage.getItem('basicFilter'));
        this.courseFilterControl.setValue(sessionStorage.getItem('courseFilter'));
        this.leaderFilterControl.setValue(sessionStorage.getItem('leaderFilter'));
        this.personFilterControl.setValue(sessionStorage.getItem('personFilter'));
        this.archiveFilterControl.setValue(sessionStorage.getItem('archiveFilter'));
        this.tagFilterControl.setValue(sessionStorage.getItem('tagFilter'));
    }

    obtainSemesterLabels() {
        this.http.get<String[]>('/api/project/get-semesters')
            .subscribe(
                data => {
                    console.log(data);
                    this.semesters = data;
                },
                error => {
                    console.log("Semester labels haven't been obtained")
                }
            );
    }

    addTag(project: Project) {
        const dialogRef = this.dialog.open(ManageTagsComponent, {
            width: '600px',
            data: {managingProject: project}
        });

        dialogRef.afterClosed().subscribe(tag => {
            if (tag != undefined) {
                this.addTagToProject(project, tag);
            }
        });
    }

    addTagToSelected() {
        const dialogRef = this.dialog.open(ManageTagsComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(tag => {
            if (tag != undefined) {
                this.selectedProjects.forEach((project: Project) => {
                    this.addTagToProject(project, tag);
                });
            }
        });
    }

    doFilter(query: string) {
        if (query) {
            this.dataSource.filter = query.trim().toLocaleLowerCase();
        } else {
            this.dataSource.filter = '_';
        }
    }

    update(id: number, gitlabId: number) {
        const idMap = {};
        idMap[id] = gitlabId;
        this.http.post<boolean>('/api/import/gitlab/add-members', idMap)
            .subscribe(data => {
                    if (data) {
                        console.log('Update successful ', data);
                        this.notificationService.notify('Aktualizace proběhla úspěšně.');
                        this.getProjects();
                    }
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Aktualizace selhala. Zkontrolujte, zda máte nastavený gitlab token.');
                    }
                    console.error('Couldn\'t update because', error);
                }
            );
    }

    archive(id: number) {
        let archive: Archive;
        const dialogRef = this.dialog.open(ArchiveModalComponent, {
            width: '500px',
            data: {semesters: this.semesters}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.expirationDate) {
                console.log(result.expirationDate);
                const projectId: number[] = [];
                projectId.push(id);
                archive = {
                    expirationDate: result.expirationDate,
                    hasExpired: false,
                    archiveSemester: result.archiveSemester,
                    ids: projectId
                };
                this.http.post<Array<number>>('/api/project/archive', archive)
                    .subscribe(
                        data => {
                            if (data.length == 1) {
                                this.notificationService.notify('Archivace proběhla úspěšně.');
                                this.getProjects();
                            } else {
                                this.notificationService.notifyError('Archivace selhala.');
                                console.error('Couldn\'t archive');
                            }
                        },
                        error => {
                            console.error('Couldn\'t archive because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při archivaci projektu: [' + error.response + '].');
                            }
                        }
                    );
            }
        });
    }

    dearchive(id: number) {
        this.http.get('/api/project/dearchive/' + id)
            .subscribe(
                ignored => {
                    this.notificationService.notify('Dearchivace proběhla úspěšně.');
                    this.getProjects();
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Dearchivace selhala.');
                    }
                    console.error('Couldn\'t dearchive because ', error);
                }
            );
    }

    delete(id: number) {
        const dialogRef = this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {isProject: true, isDeleting: true}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.http.delete('/api/project/' + id)
                    .subscribe(
                        data => {
                            console.log('Delete successful ', data);
                            this.notificationService.notify('Úspěšně smazáno.');
                            this.getProjects();
                        },
                        error => {
                            console.error('Couldn\'t delete because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při mazání projektu: [' + error.error.message + '].');
                            }
                        });
            }
        });
    }

    getProjects() {
        this.http.get<Project[]>('/api/project/all')
            .subscribe(
                data => {
                    this.dataSource.data = data;
                    console.log("projects", data);
                    for (const project of data) {
                        this.leaders.add(project.leader.name);
                        this.courses.add(project.course.name);
                        for (const tag of project.tags) {
                            this.tags.add(tag);
                        }
                    }
                    this.selectedProjects = new Set();
                    this.indeterminate();
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání projektů: [' + error.response + '].');
                    }
                }
            );
    }

    edit(id: number) {
        this.router.navigate(['./project', id]);
    }

    updateSelected() {
        const idMap = {};
        this.selectedProjects.forEach((project: Project) => {
            if (project.gitlabProjectID) {
                idMap[project.id] = project.gitlabProjectID;
            }
        });
        if (Object.keys(idMap).length !== 0) {
            this.http.post<boolean>('/api/import/gitlab/add-members', idMap)
                .subscribe(data => {
                    if (data) {
                        this.notificationService.notify('Aktualizace proběhla úspěšně.');
                        this.getProjects();
                    }
                }, error => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Aktualizace selhala. Zkontrolujte, zda máte nastavený gitlab token.');
                    }
                });
        }
    }

    archiveSelected() {
        const idArray: number[] = this.collectSelectedIds();

        let archive: Archive;
        const dialogRef = this.dialog.open(ArchiveModalComponent, {
            width: '500px',
            data: {semesters: this.semesters}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.expirationDate) {
                console.log(result.expirationDate);
                archive = {
                    expirationDate: result.expirationDate,
                    hasExpired: false,
                    archiveSemester: result.archiveSemester,
                    ids: idArray
                };
                this.http.post<Array<number>>('/api/project/archive', archive)
                    .subscribe(
                        data => {
                            if (data.length == idArray.length) {
                                this.notificationService.notify('Archivace proběhla úspěšně.');
                            } else {
                                this.notificationService.notifyError('Archivace byla neúspěšná pro projekty s id: (' + idArray.filter(value => !data.includes(value)) + ').');
                            }
                            this.getProjects();
                        },
                        error => {
                            console.error('Couldn\'t archive because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při archivaci projektů: [' + error.response + '].');
                            }
                        }
                    );
            }
        });
    }

    dearchiveSelected() {
        const idArray: number[] = this.collectSelectedIds();

        this.http.post<Array<number>>('/api/project/dearchive', {ids: idArray})
            .subscribe(
                data => {
                    if (data.length == idArray.length) {
                        this.notificationService.notify('Dearchivace proběhla úspěšně.');
                        this.getProjects();
                    } else {
                        this.notificationService.notifyError('Dearchivace selhala pro projekty s id: (' + idArray.filter(value => !data.includes(value)) + ').');
                    }
                },
                error => {
                    console.error('Couldn\'t dearchive because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při dearchivaci projektů: [' + error.response + '].');
                    }
                }
            );
    }

    deleteSelected() {
        const dialogRef = this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {isProjects: true, isDeleting: true}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const idArray: number[] = this.collectSelectedIds();
                this.http.post<Array<number>>('/api/project/delete', {ids: idArray})
                    .subscribe(
                        data => {
                            if (data.length == idArray.length) {
                                this.notificationService.notify('Mazání proběhlo úspěšně.');
                                this.getProjects();
                            } else {
                                this.notificationService.notifyError('Mazání selhalo pro projekty s id: (' + idArray.filter(value => !data.includes(value)) + ').');
                            }
                        },
                        error => {
                            console.error('Couldn\'t delete because', error);
                            if (!this.httpErrorService.ignoreError(error.status)) {
                                this.notificationService.notifyError('Chyba při mazání projektů: [' + error.response + '].');
                            }
                        }
                    );
            }
        })
    }

    collectSelectedIds() {
        const idArray: number[] = [];
        this.selectedProjects.forEach((project: Project) => {
            idArray.push(project.id);
        });
        return idArray;
    }

    gitlabCloneScriptExportSelected() {
        this.http.post('/api/project-export/gitlab-clone-script', {ids: this.collectSelectedIds()}, {responseType: 'blob'})
            .subscribe(data => {
                fileSaver.saveAs(data, `GitlabCloneScript.bat`);
                this.notificationService.notify('Export proběhl úspěšně.');
            }, error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Export selhal.');
                }
            });
    }

    csvExportSelected() {
        this.http.post('/api/project-export/csv', {ids: this.collectSelectedIds()}, {responseType: 'blob'})
            .subscribe(data => {
                fileSaver.saveAs(data, `Groups.csv`);
                this.notificationService.notify('Export proběhl úspěšně.');
            }, error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Export selhal.');
                }
            });
    }

    odbcExportSelected() {
        const dialogRef = this.dialog.open(OdbcExportComponent, {width: '500px'});
        dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    const dbRole = result.alternativeCredentials ? result.dbRole : null;
                    const dbPassword = result.alternativeCredentials ? result.dbPassword : null;
                    this.http.post('/api/project-export/odbc',
                        {
                            'projectIds': this.collectSelectedIds(),
                            'dbRole': dbRole,
                            'dbPassword': dbPassword
                        }, {
                            responseType: 'blob'
                        }
                    ).subscribe(data => {
                        fileSaver.saveAs(data, `odbc_driver_configurations.bat`);
                        this.notificationService.notify('Export proběhl úspěšně.');
                    }, error => {
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Export selhal.');
                        }
                    });
                }
            }
        );
    }

    indeterminate() {
        this.indeterminateV = this.selectedProjects.size !== 0 && this.dataSource.data.length !== 0
            && this.selectedProjects.size !== this.dataSource.data.length;
    }

    switchSelected(project: Project) {
        project.selected = !project.selected;
        if (project.selected) {
            this.selectedProjects.add(project);
        } else {
            this.selectedProjects.delete(project);
        }
        this.indeterminate();
    }

    switchAll() {
        if (this.selectedProjects.size < this.dataSource.filteredData.length) {
            for (const pp of this.dataSource.filteredData) {
                pp.selected = true;
                this.selectedProjects.add(pp);
            }
        } else {
            for (const project of this.dataSource.filteredData) {
                project.selected = false;
                this.selectedProjects.delete(project);
            }
        }
        this.indeterminate();
    }

    createAdditionalDatabase(project: Project) {
        if (confirm('Určitě chcete projektu ' + project.name + ' vytvořit pomocnou databázi?')) {
            this.http.get('/api/project/create-project-database/' + project.id)
                .subscribe(ignored => {
                    this.notificationService.notify('Pomocná databáze úspěšně vytvořena.');
                    this.getProjects();
                }, error => {
                    console.log('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při vytváření pomocné databáze: [' + error + '].');
                    }
                });
        }
    }

    createSonarQube(project: Project) {
        if (confirm('Určitě chcete projektu ' + project.name + ' vytvořit sonarQube')) {
            this.http.post('/api/sonarqube/create', {projectId: project.id})
                .subscribe(response => {
                    console.log('SonarQube project created successfully:', response);
                    this.notificationService.notify('SonarQube úspěšně vytvořen.');
                    this.getProjects();
                }, error => {
                    console.log('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při vytváření SonarQube: [' + error.error + '].');
                    }
                });
        }
    }

    createContainer(project: Project) {
        const dialogRef = this.dialog.open(CreateContainerModalComponent, {
            width: '500px',
            data: {isContainer: true, isProject: true, project: project}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.router.navigate(['../create-container-instance', project.id]);
            }
        });
    }

    createContainersSelected() {
        const dialogRef = this.dialog.open(CreateContainerModalComponent, {
            width: '500px',
            data: {isContainer: true, isProjects: true}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const idArray: number[] = this.collectSelectedIds();
                this.idArrayService.setIdArray(idArray);
                this.router.navigate(['../create-container-instance']);
            }
        });
    }

    addTagToProject(project: Project, tag: string) {
        const index: number = project.tags.indexOf(tag);
        if (index === -1) {
            project.tags.push(tag);
            this.http.post('/api/project/add-tag/' + project.id, tag)
                .subscribe(ignored => {
                    this.getProjects();
                }, error => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při přidávání tagu.');
                    }
                });
        } else {
            this.notificationService.notifyError('Projekt již daný tag má.');
        }
    }

    removeTagFromProject(project: Project, tag: string) {
        const index: number = project.tags.indexOf(tag);
        if (index !== -1) {
            project.tags.splice(index, 1);
            this.http.post('/api/project/remove-tag/' + project.id, tag)
                .subscribe(ignored => {
                    this.getProjects();
                }, error => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při odebírání tagu.');
                    }
                });
        }
    }

    filterBasic($event) {
        if ($event.target.value) {
            this.basicFilter = $event.target.value;
            sessionStorage.setItem('basicFilter', this.basicFilter);
            this.doFilter(this.basicFilter);
        } else {
            sessionStorage.setItem('basicFilter', '');
            this.doFilter('');
        }
    }

    filterCourse(course) {
        if (course != null) {
            this.courseFilter = course;
            sessionStorage.setItem('courseFilter', this.courseFilter);
        } else {
            sessionStorage.setItem('courseFilter', '');
            this.courseFilter = '';
        }
        this.doFilter(this.dataSource.filter);
    }

    filterLeader(leader) {
        if (leader != null) {
            this.leaderFilter = leader;
            sessionStorage.setItem('leaderFilter', this.leaderFilter);
        } else {
            sessionStorage.setItem('leaderFilter', '');
            this.leaderFilter = '';
        }
        this.doFilter(this.dataSource.filter);
    }

    filterArchive(semester) {
        if (semester == 'NONE') {
            this.displayedColumns = ['id', 'name', 'databaseProject', 'container', 'sonarQube', 'leader', 'course', 'enrolledStudents', 'gitlabId', 'tags', 'selected', 'actions'];
        } else {
            this.displayedColumns = ['id', 'name', 'databaseProject', 'container', 'sonarQube', 'leader', 'course', 'enrolledStudents', 'gitlabId', 'archive', 'tags', 'selected', 'actions'];
        }
        this.archiveFilter = semester;
        sessionStorage.setItem('archiveFilter', this.archiveFilter);
        this.doFilter(this.dataSource.filter);
    }

    filterTag(tag) {
        if (tag != null) {
            this.tagFilter = tag;
            sessionStorage.setItem('tagFilter', this.tagFilter);
        } else {
            sessionStorage.setItem('tagFilter', '');
            this.tagFilter = '';
        }
        this.doFilter(this.dataSource.filter);
    }

    filterPerson($event) {
        if ($event.target.value) {
            this.personFilter = $event.target.value;
            sessionStorage.setItem('personFilter', this.personFilter);
        } else {
            sessionStorage.setItem('personFilter', '');
            this.personFilter = '';
        }
        this.doFilter(this.dataSource.filter);
    }

    projectHasTag(project: Project, compareTag: string) {
        let result = false;
        for (const tag of project.tags) {
            if (tag == compareTag) {
                result = true;
                break;
            }
        }
        return result;
    }

    transformString(str: string) {
        return str.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    filterPersonFromProject(prj: Project, person: string) {
        const transformedFilter = this.transformString(person);
        for (const p of prj.enrolledStudents) {
            if (this.transformString(p.name).indexOf(transformedFilter) !== -1
                || this.transformString(p.loginCVUT).indexOf(transformedFilter) !== -1) {
                return true;
            }
        }
        return false;
    }

    clearFilters() {
        this.basicFilter = '';
        this.courseFilter = '';
        this.leaderFilter = '';
        this.personFilter = '';
        this.archiveFilter = 'NONE';
        this.tagFilter = '';

        sessionStorage.setItem('basicFilter', '');
        sessionStorage.setItem('courseFilter', '');
        sessionStorage.setItem('personFilter', '');
        sessionStorage.setItem('leaderFilter', '');
        sessionStorage.setItem('archiveFilter', 'NONE');
        sessionStorage.setItem('tagFilter', '');

        this.basicFilterControl.setValue('', {onlySelf: true, emitEvent: false});
        this.courseFilterControl.setValue('', {onlySelf: true, emitEvent: false});
        this.personFilterControl.setValue('', {onlySelf: true, emitEvent: false});
        this.leaderFilterControl.setValue('', {onlySelf: true, emitEvent: false});
        this.archiveFilterControl.setValue('Nearchivované', {onlySelf: true, emitEvent: false});
        this.tagFilterControl.setValue('', {onlySelf: true, emitEvent: false});
        this.doFilter('_');
    }

    showInstances(projectId: number) {
        this.isLoaded = true;
        const idArray: number[] = [];
        idArray.push(projectId);
        this.idArrayService.setIdArray(idArray);
        this.router.navigate(['list-containers/instances/']);
    }
}
