<form [formGroup]="personForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="create-person">
        <mat-card-header>
            <mat-card-title *ngIf="isEdit()">{{'Upravit osobu: ' + showPersonsName()}}</mat-card-title>
            <mat-card-title *ngIf="!isEdit()">Nová osoba</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Jméno" formControlName="name">
                        <mat-error *ngIf="personForm.controls['name'].hasError('required')">
                            Jméno nemůže být <strong>prázdné</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <input *ngIf="isEdit()" type="password" matInput placeholder="Změnit heslo" formControlName="password">
                        <input *ngIf="!isEdit()" type="password" matInput placeholder="Heslo" formControlName="password">
                        <mat-error *ngIf="personForm.controls['password'].hasError('maxlength')">
                            Heslo je <strong>příliš dlouhé</strong> (max. 71 znaků)
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Uživatelské jméno ČVUT" formControlName="loginCVUT">
                        <mat-error *ngIf="personForm.controls['loginCVUT'].hasError('required')">
                            Uživatelské jméno CVUT nemůže být <strong>prázdné</strong>
                        </mat-error>
                        <mat-error *ngIf="personForm.controls['loginCVUT'].hasError('maxlength')">
                            Uživatelské jméno CVUT je <strong>příliš dlouhé</strong> (max. 100 znaků)
                        </mat-error>
                        <mat-error *ngIf="personForm.controls['loginCVUT'].hasError('pattern')">
                            Uživatelské jméno CVUT <strong>musí</strong> začínat písmenem a <strong>musí</strong> být
                            tvořeno kombinací písmen, čísel, mezer a podtržítek
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Email" formControlName="email">
                        <mat-error *ngIf="personForm.controls['email'].hasError('required')">
                            Email nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error *ngIf="personForm.controls['email'].hasError('email')">
                            Email je <strong>neplatný</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Role" formControlName="role">
                            <mat-option *ngFor="let role of roles" [value]="role">
                                {{ role }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!personForm.controls['role'].valid">
                            Role nemůže být <strong>prázdná</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!personForm.valid" mat-raised-button color="primary"
                    type="submit">{{editPersonId == null ? "Vytvořit" : "Upravit"}}</button>
        </mat-card-actions>
    </mat-card>
</form>
<div *ngIf="person !== undefined">
    <mat-card class="create-person h2">
        <mat-card-title style="margin-left: 10px">{{'Informace o osobě'}}</mat-card-title>
        <mat-card-content>
            <mat-grid-list cols="2" rowHeight="4em">
                <mat-grid-tile>DB Role: &nbsp;{{person.databaseRole}}</mat-grid-tile>
                <mat-grid-tile>
                    DB heslo: &nbsp;{{toggleableDatabasePassword}}&nbsp;&nbsp;

                    <mat-icon class="hand-on-hover"
                              (click)="copyDBPasswordToClipboard()"
                              matTooltip="Zkopíruj do schránky">
                        file_copy
                    </mat-icon>
                    <mat-icon class="hand-on-hover"
                              (click)="showPassword()"
                              matTooltip="Ukázat heslo">
                        remove_red_eye
                    </mat-icon>
                </mat-grid-tile>
            </mat-grid-list>
            <div *ngIf="person.projects !== undefined && person.projects.length > 0">
                <label class="h2">Osoba je členem těchto projektů:</label>
                <ul>
                    <li *ngFor="let project of person.projects">
                        <button *ngIf="authenticationService.isLoggedUserAdmin || project.leader.id === authenticationService.loggedUserID" mat-raised-button class="hand-on-hover h3"
                                [routerLink]="['/project/' + project.id]">{{project.course.name}}
                            - {{project.name}}</button>
                        <button *ngIf="!authenticationService.isLoggedUserAdmin && project.leader.id !== authenticationService.loggedUserID" mat-raised-button class="hand-on-hover h3" >
                                {{project.course.name}}- {{project.name}}</button>
                    </li>
                </ul>
            </div>
            <div *ngIf="person.leadingProjects !== undefined && person.leadingProjects.length > 0">
                <label class="h2">Osoba vede tyto projekty:</label>
                <ul>
                    <li *ngFor="let project of person.leadingProjects">
                        <button mat-raised-button class="hand-on-hover h3"
                                [routerLink]="['/project/' + project.id]">{{project.course}}
                            - {{project.name}}</button>
                    </li>
                </ul>
            </div>
        </mat-card-content>
    </mat-card>
</div>
