<form [formGroup]="backupRuleForm" novalidate>
    <mat-card>
        <mat-card-header>
            <mat-card-title *ngIf="!editMode">Nové pravidlo zálohování</mat-card-title>
            <mat-card-title *ngIf="editMode">Upravit pravidlo zálohování</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col-bigger">
                    <!--name-->
                    <mat-form-field class="half-width">
                        <input matInput placeholder="Název" formControlName="name">
                        <mat-error *ngIf="backupRuleForm.controls['name'].hasError('required')">
                            Název nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error *ngIf="backupRuleForm.controls['name'].hasError('maxlength')">
                            Název je <strong>příliš dlouhý</strong> (max. 100 znaků)
                        </mat-error>
                        <mat-error *ngIf="backupRuleForm.controls['name'].hasError('pattern')">
                            Název <strong>musí</strong> začínat písmenem a <strong>musí</strong> být tvořeno kombinací
                            písmen, čísel, mezer a podtržítek
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row standalone">
                <!--Chytre mazani-->
                <div class="col">
                    <mat-checkbox formControlName="smartBackup" (change)="toggleSmartBackup()">
                        Chytré zálohování
                    </mat-checkbox>
                    <mat-icon style="padding-left: 1%; padding-top: 2%; font-size: 22px; margin-top: 18px;" matTooltip="Zálohy se tvoří každý den ve 4 hodiny ráno. Systém uchovává vždy posledních 7 denních záloh. Také uchovává poslední 4 týdenní zálohy, které se vytváří každý týden. Jednou měsíčně vytvoří měsíční zálohu která se uchová na dobu neurčitou.">help</mat-icon>
                </div>
            </div>
            <div class="row" *ngIf="!this.backupRuleForm.get('smartBackup').value">
                <!--Periodicita-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Periodicita" formControlName="periodicity" (selectionChange)="togglePeriodicity()">
                            <mat-option *ngFor="let periodicity of periodicityOptions" [value]="periodicity">
                                {{ periodicity }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="backupRuleForm.controls['periodicity'].hasError('required')">
                            Periodicita nemůže být <strong>prázdná</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--Zivotnost-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Životnost" formControlName="serviceLife">
                            <mat-option *ngFor="let serviceLife of serviceLifeOptions" [value]="serviceLife">
                                {{ serviceLife }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="backupRuleForm.controls['serviceLife'].hasError('required')">
                            Životnost nemůže být <strong>prázdná</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--Den (tydenni periodicita)-->
                <div class="col" *ngIf="!monthlyPeriodicity" >
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Den" formControlName="day">
                            <mat-option *ngFor="let day of dayOptionsWeeklyPeriodicity" [value]="day">
                                {{ day }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--Den (mesicni periodicita)-->
                <div class="col" *ngIf="monthlyPeriodicity" >
                    <mat-form-field class="full-width">
                        <mat-select placeholder="Den" formControlName="day">
                            <mat-option *ngFor="let day of dayOptionsMonthlyPeriodicity" [value]="day">
                                {{ day }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--Cas-->
                <div class="col">
                    <mat-form-field [attr.aria-disabled]="smartBackupEnabled">
                        <input matInput placeholder="Čas" [ngxTimepicker]="fullTime" [format]="24" formControlName="time" readonly>
                        <ngx-material-timepicker [defaultTime]="'00:00'" #fullTime></ngx-material-timepicker>
                    </mat-form-field>
                </div>
            </div>
            <!--description-->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <textarea matInput placeholder="Popis" formControlName="description" rows="6"></textarea>
                        <mat-error *ngIf="backupRuleForm.controls['description'].hasError('required')">
                            Popis nemůže být <strong>prázdný</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <mat-form-field class="full-width">
                    <input type="text" placeholder="Vybrat databázi" matInput [matAutocomplete]="dbRule"
                           [formControl]="databaseControl">
                </mat-form-field>
                <mat-autocomplete #dbRule="matAutocomplete">
                    <mat-option *ngFor="let database of filteredDatabases | async" [value]="selectedDatabases">
                        <div (click)="databaseClicked($event, database)">
                            <mat-checkbox [checked]="database.selected" (change)="toggleDatabaseSelection(database)"
                                          (click)="$event.stopPropagation()">
                                {{ database.name }}
                            </mat-checkbox>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button *ngIf="!editMode" [disabled]="!backupRuleForm.valid" mat-raised-button color="primary"
                    (click)="createNewRule()">Vytvořit pravidlo</button>
            <button *ngIf="editMode" [disabled]="!backupRuleForm.valid" mat-raised-button color="primary"
                    (click)="editRule()">Upravit pravidlo</button>
            <button *ngIf="editMode" mat-raised-button color="primary"
                    (click)="cancelEdit()">Zrušit úpravu</button>
        </mat-card-actions>
    </mat-card>
</form>

<!--Table of rules-->
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="id">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let backupRule"> {{backupRule.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="backupRuleName">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Pravidlo</th>
        <td mat-cell *matCellDef="let backupRule"> {{backupRule.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Popis</th>
        <td mat-cell *matCellDef="let backupRule"> {{backupRule.description}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="database">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Databáze</th>
        <td mat-cell *matCellDef="let backupRule"><button (click)="showDatabaseList(backupRule)">Seznam databází</button></td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!--Selected Column -->
    <ng-container matColumnDef="selected">
        <th mat-header-cell style="text-align: center; vertical-align: middle; width: 4%;" *matHeaderCellDef>
            <mat-checkbox  [(indeterminate)]="indeterminateV" (change)="switchAll()"
                           [checked]="selectedBackupRules.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let backup" style="text-align: center; vertical-align: middle;">
            <mat-checkbox (change)="switchSelected(backup)" [checked]="backup.selected"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
            <mat-checkbox  [(indeterminate)]="indeterminateV" (change)="switchAll()"
                           [checked]="selectedBackupRules.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 2%;" *matHeaderCellDef>
            <button mat-raised-button [matMenuTriggerFor]="actionMenuTop">Akce <mat-icon>keyboard_arrow_down</mat-icon></button>
            <mat-menu #actionMenuTop="matMenu">
                <button mat-menu-item>
                    Smazat vybrané
                </button>
            </mat-menu>
        </th>
        <td mat-cell *matCellDef="let backupRule" style="overflow: visible;">
            <button mat-raised-button [matMenuTriggerFor]="actionMenu">Akce <mat-icon>keyboard_arrow_down</mat-icon></button>
            <mat-menu #actionMenu="matMenu">
                <button mat-menu-item (click)="edit(backupRule)">Upravit</button>
                <button mat-menu-item (click)="deleteAndGetDatabases(backupRule)">Smazat</button>
            </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <button mat-raised-button [matMenuTriggerFor]="actionMenuBottom">Akce <mat-icon>keyboard_arrow_down</mat-icon></button>
            <mat-menu #actionMenuBottom="matMenu">
                <button mat-menu-item>
                    Smazat vybrané
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
