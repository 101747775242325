<div class="align-mid">
    <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
        <mat-card class="create-person">
            <mat-card-header>
                <mat-card-title>Přihlášení</mat-card-title>
            </mat-card-header>
            <mat-card-content style="padding: 10px; width: 100%; margin-left: auto; margin-right: auto;">
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Jméno" formControlName="username">
                        <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                            Jméno nemůže být <strong>prázdné</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Heslo" type="password" matInput formControlName="password">
                        <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                            Heslo nemůže být <strong>prázdné</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <button [disabled]="!loginForm.valid" mat-raised-button color="primary" type="submit">Přihlásit</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
