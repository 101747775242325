import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from "../../dm/project";

export interface DeleteRunningContainerModalData {
    isRunning: boolean;
    isContainerInstance: boolean;
    isContainerInstances: boolean;
}
@Component({
    selector: 'app-delete-running-container-modal',
    templateUrl: './delete-running-container-modal.component.html',
    styleUrls: ['./delete-running-container-modal.component.css']
})
export class DeleteRunningContainerModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DeleteRunningContainerModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DeleteRunningContainerModalData
    ) {
    }

    ngOnInit() {
        // do nothing.
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    onClose() {
        this.dialogRef.close();
    }
}
