import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormControl, Validators} from '@angular/forms';
import {NotificationService} from '../services/notification.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {GitlabImportPreviewComponent} from './gitlab-import-preview/gitlab-import-preview.component';
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {HttpErrorService} from "../services/http.error.service";

@Component({
    selector: 'app-gitlab-import',
    templateUrl: './gitlab-import.component.html',
    styleUrls: ['./gitlab-import.component.css']
})
export class GitlabImportComponent implements OnInit {
    importForm = this.fb.group({
        description: [null, Validators.required],
    });

    tokenForm = this.fb.group({
        token: [null, Validators.required],
    });

    public stringHistory: string[] = [];
    lastStringFilter: string = '';
    filteredStrings: Observable<string[]>;
    stringControl: FormControl = this.importForm.get('description') as FormControl;

    constructor(private fb: UntypedFormBuilder,
                private notificationService: NotificationService,
                private http: HttpClient,
                public dialog: MatDialog,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        this.getStringHistory();
    }

    getStringHistory() {
        this.stringHistory = [];
        this.http.get<Set<string>>('/api/import/gitlab/autocomplete/get')
            .subscribe(
                data => {
                    this.stringHistory = [];
                    data.forEach(s => this.stringHistory.push(s));
                    this.filteredStrings = this.stringControl.valueChanges.pipe(
                        startWith<string | string[]>(''),
                        map(value => typeof value === 'string' ? value : this.lastStringFilter),
                        map(filter => this.filterStringHistory(filter))
                    );
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání historie stringů: [' + error.status + '].');
                    }
                }
            );
    }

    submitToken() {
        console.log('Submitting token.', this.tokenForm.controls.token.value);
        this.http.post('api/person/set-gitlab-token', {token: this.tokenForm.controls.token.value})
            .subscribe(data => {
                this.notificationService.notify('Token uložen.');
            }, error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při ukládání: [' + error.status + '].');
                }
            });
    }

    deleteToken() {
        this.http.delete('api/person/delete-gitlab-token')
            .subscribe(data => {
                this.notificationService.notify('Token smazán.');
            }, error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při mazání: [' + error.status + '].');
                }
            });
    }

    openDialog() {
        const dialogRef = this.dialog.open(GitlabImportPreviewComponent);
        dialogRef.componentInstance.description = this.importForm.controls.description.value;

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    onStringSubmit() {
        this.http.post('/api/import/gitlab/autocomplete/add', this.importForm.controls.description.value)
            .subscribe(data => {
                if (!data) {
                    console.log('Data is not true, so token either existed or failed to add.', data);
                }
            }, error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při ukládání: [' + error.status + '].');
                }
            });
    }


    stringClicked(event: Event, string: string) {
        event.stopPropagation();
        this.stringControl.setValue(string);
    }

    selectString(string: string) {
        this.stringControl.setValue(string);
    }

    deleteString(string: string) {
        const saved = this.stringControl.value;
        this.http.post('/api/import/gitlab/autocomplete/delete', string)
            .subscribe(data => {
                this.notificationService.notify('String smazán z historie.');
            }, error => {
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při mazání stringu: [' + error.status + '].');
                }
            });
        this.getStringHistory();
        this.stringControl.setValue(saved);
    }

    filterStringHistory(filter: string): string[] {
        this.lastStringFilter = filter;
        if (filter) {
            return this.stringHistory.filter(option => {
                return option.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        } else {
            return this.stringHistory.slice();
        }
    }
}
