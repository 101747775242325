import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface WarningModalData {
    isDeleting: boolean;
    isSonarQube: boolean;
}
@Component({
    selector: 'app-SQ-warning-modal',
    templateUrl: './warning-modal.component.html',
    styleUrls: ['./warning-modal.component.css']
})
export class WarningModalComponentSQ implements OnInit {

    constructor(public dialogRef: MatDialogRef<WarningModalComponentSQ>,
                @Inject(MAT_DIALOG_DATA) public data: WarningModalData
    ) {
    }

    ngOnInit() {
        // do nothing.
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    onClose() {
        this.dialogRef.close();
    }
}
