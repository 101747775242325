import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from "../../dm/project";

export interface CreateContainerModalData {
    isProject: boolean;
    isProjects: boolean;
    isContainer: boolean;
    project: Project;
}
@Component({
    selector: 'app-create-container-modal',
    templateUrl: './create-container-modal.component.html',
    styleUrls: ['./create-container-modal.component.css']
})
export class CreateContainerModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<CreateContainerModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: CreateContainerModalData
    ) {
    }

    ngOnInit() {
        // do nothing.
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    onClose() {
        this.dialogRef.close();
    }
}
