import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-backup-upload-modal',
    templateUrl: './backup-upload-modal.component.html',
    styleUrls: ['./backup-upload-modal.component.css']
})
export class BackupUploadModalComponent implements OnInit {
    @ViewChild('fileInput') fileInput: ElementRef;
    readyToDeploy = false;
    backupMessage: string;
    fileData: any;
    fileName = '';
    files;

    constructor(public dialogRef: MatDialogRef<BackupUploadModalComponent>, public http: HttpClient) {
    }

    ngOnInit() {
        // do nothing.
    }

    onFileSelected(event: any) {
        this.files = event.target.files;
        this.fileName = this.files[0].name;
        if (this.files) {
            let data = new Blob([this.files[0]]);
            this.loadBuffer(data).then((buff) => {
                this.fileData = buff;
                this.readyToDeploy = true;
            });
        }
    }

    async loadBuffer(data) {
        return new Promise<any>(((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsArrayBuffer(data);
            reader.onload = () => {
                let arrBuffer = reader.result;
                const bytes = new Uint8Array(arrBuffer as ArrayBuffer);
                resolve(bytes);
            }
        }))
    }

    onSubmit() {
        this.dialogRef.close({file: this.files[0], fileData: this.fileData, description: this.backupMessage});
    }

    onClose() {
        this.dialogRef.close();
    }
}
