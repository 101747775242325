<mat-card id="sonarqube" class="table-filter">
    <mat-card-content class="table-filter-content">
        <mat-form-field>
            <input matInput type="text" (keyup)="filterBasic($event)" placeholder="Vyhledat"
                   [formControl]="basicFilterControl">
        </mat-form-field>
        <mat-form-field style="margin-left: 20px" >
            <input matInput type="text" (keyup)="filterPerson($event)" placeholder="Vyhledat podle studenta"
                   [formControl]="personFilterControl">
        </mat-form-field>
        <mat-form-field style="margin-left: 20px">
            <mat-select placeholder="Předmět" [formControl]="courseFilterControl">
                <mat-option [value]="" (click)="filterCourse('')"></mat-option>
                <mat-option *ngFor="let course of courses" [value]="course" (click)="filterCourse(course)">
                    {{ course }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="margin-left: 20px" *ngIf="authenticationService.isLoggedUserAdmin">
            <mat-select placeholder="Vedoucí" [formControl]="leaderFilterControl">
                <mat-option [value]="" (click)="filterLeader('')"></mat-option>
                <mat-option *ngFor="let leader of leaders" [value]="leader" (click)="filterLeader(leader)">
                    {{ leader }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button style="margin-left: 20px" (click)="clearFilters()">Zrušit filtry</button>
    </mat-card-content>
</mat-card>


<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let project"> {{project.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> Název projektu</th>
        <td  mat-cell *matCellDef="let project">
            <button mat-button  (click)="edit(project.id)">{{project.name}}</button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Leader Column -->
    <ng-container matColumnDef="leader">
        <th mat-header-cell style="width: 15%;" *matHeaderCellDef mat-sort-header> Vedoucí</th>
        <td mat-cell *matCellDef="let project"> {{project.leader.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Project db Column -->
    <ng-container matColumnDef="sonarQubeProject">
        <th mat-header-cell style="width: 20%;" *matHeaderCellDef mat-sort-header> SonarQube</th>
        <td mat-cell *matCellDef="let project">
            <button mat-raised-button *ngIf="!project.sonarQube" (click)="createSonarQube(project)">
                Vytvořit SQ
            </button>
            <button mat-raised-button style="background-color: #666363;" *ngIf="project.sonarQube" (click)="deleteSonarQube(project.id)">
                Smazat SQ
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Course Column -->
    <ng-container matColumnDef="course">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> Předmět</th>
        <td mat-cell *matCellDef="let project"> {{project.course.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Students Column -->
    <ng-container matColumnDef="enrolledStudents">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> # studentů</th>
        <td mat-cell *matCellDef="let project"> {{project.enrolledStudents.length}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- GitLab ID Column -->
    <ng-container matColumnDef="gitlabId">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> GitlabID</th>
        <td mat-cell *matCellDef="let project"><a href="{{project.gitlabProjectURL}}"
                                                  target="_blank">{{project.gitlabProjectID}}</a></td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <!--Selected Column -->
    <ng-container matColumnDef="selected">
        <th mat-header-cell style="text-align: center; vertical-align: middle; width: 4%;" *matHeaderCellDef>
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedProjects.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let project" style="text-align: center; vertical-align: middle;">
            <mat-checkbox (change)="switchSelected(project)" [checked]="project.selected"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedProjects.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </td>
    </ng-container>

    <!-- Action buttons Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 8%;" *matHeaderCellDef>
            <button mat-raised-button [disabled]="selectedProjects.size === 0" [matMenuTriggerFor]="actionMenuTop">Akce
                <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuTop="matMenu">
                <button mat-menu-item (click)="createSonarQubeProjectsSelected()">
                    Vytvořit SQ pro projekty
                </button>
                <button mat-menu-item (click)="deleteSonarQubeProjectsSelected()">
                    Smazať SQ pro projekty
                </button>

            </mat-menu>
        </th>
        <td mat-cell *matCellDef="let project" style="overflow: visible;">
            <button mat-raised-button [matMenuTriggerFor]="actionMenu">Akce
                <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
                <button mat-menu-item *ngIf="!project.sonarQube && !isLoading" (click)="createSonarQube(project)">Vytvořit SQ</button>
                <button mat-menu-item *ngIf="project.sonarQube && !isLoading" (click)="deleteSonarQube(project.id)">Smazat SQ</button>
               <!-- <button mat-menu-item *ngIf="project.sonarQube" (click)="addStudentToSonarQube(project.id)">Přidat Studenty do SQ</button>-->
            </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <button mat-raised-button [disabled]="selectedProjects.size === 0" [matMenuTriggerFor]="actionMenuBottom">
                Akce
                <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuBottom="matMenu">
                <button mat-menu-item (click)="createSonarQubeProjectsSelected()">
                    Vytvořit SQ pro projekty
                </button>

                <button mat-menu-item (click)="deleteSonarQubeProjectsSelected()">
                    Smazat SQ pro projekty
                </button>

            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>

<mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
