import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';


@Component({
    selector: 'app-bug-report-snack-bar',
    templateUrl: './bug-report-snackbar.component.html',
    styleUrls: ['./bug-report-snackbar.component.css']
})
export class BugReportSnackBar {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBarRef: MatSnackBarRef<BugReportSnackBar>,
    ) {
    }
}
