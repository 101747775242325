import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {AppStorage} from './app.storage';

@Directive()
@Injectable({providedIn: 'root'})
export class AuthenticationService {
    @Output() loggedInEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router) {
    }

    login(username: string, password: string, callback: (success: boolean) => void) {
        if (username && password) {
            this.http.post<any>('/api/auth/login', {username, password})
                .subscribe(
                    data => {
                        console.log('Authenticated successfully');
                        AppStorage.setToken(data.token);
                        this.authenticated = true;
                        this.loggedUserID = data.userID;
                        this.isLoggedUserAdmin = data.admin;
                        this.loggedUserName = data.username;
                        this.loggedInEmitter.emit(true);
                        this.router.navigate([this.route.snapshot.queryParams.returnUrl || '/']);
                        callback(true);
                    }, error => {
                        console.log('error in authentication ', error);
                        //this.logout(false);
                        callback(false);
                    });
        }
    }

    logout(logoutOnServer = true) {
        const token = AppStorage.getToken()
        if (logoutOnServer && !!token) {
            this.http.post('api/auth/logout', {token: AppStorage.getToken()}).subscribe(
                success => {
                    console.log(success);
                },
                fail => {
                    console.log(fail);
                }
            );
        }
        AppStorage.clear();
        this.authenticated = false;
        this.loggedUserID = null;
        this.loggedUserName = null;
        this.isLoggedUserAdmin = false;
        this.loggedInEmitter.emit(false)
        this.router.navigate(['/login']);
    }

    get authenticated(): boolean {
        return AppStorage.getObject('authenticated') as boolean;
    }

    set authenticated(value: boolean) {
        AppStorage.setObject('authenticated', value);
    }

    get loggedUserID(): number {
        return AppStorage.getObject('loggedUserID') as number;
    }

    set loggedUserID(value: number) {
        AppStorage.setObject('loggedUserID', value);
    }

    get loggedUserName(): string {
        return AppStorage.get('loggedUserName');
    }

    set loggedUserName(value: string) {
        AppStorage.set('loggedUserName', value);
    }

    get isLoggedUserAdmin(): boolean {
        return AppStorage.getObject('isLoggedUserAdmin') as boolean;
    }

    set isLoggedUserAdmin(value: boolean) {
        AppStorage.setObject('isLoggedUserAdmin', value);
    }
}
