import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from '../services/authentication.service';
import {MatTableDataSource} from '@angular/material/table';
import {HttpErrorService} from "../services/http.error.service";
import {EmailTemplate} from "../dm/emailTemplate";
import {UntypedFormControl} from "@angular/forms";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'app-list-templates',
    templateUrl: './list-templates.component.html',
    styleUrls: ['./list-templates.component.css']
})
export class ListTemplatesComponent implements OnInit {
    dataSource = new MatTableDataSource<EmailTemplate>();
    displayedColumns: string[] = ['id', 'name', 'actions'];
    basicFilterControl = new UntypedFormControl();
    basicFilter = '';

    constructor(private http: HttpClient,
                private router: Router,
                private notificationService: NotificationService,
                private authentificationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit() {
        this.getTemplates();
    }

    doFilter(query: string) {
        if (query) {
            this.dataSource.filter = query.trim().toLocaleLowerCase();
        } else {
            this.dataSource.filter = '';
        }
    }

    filterBasic($event) {
        if ($event.target.value) {
            let value = $event.target.value.trim().toLowerCase();
            this.basicFilter = $event.target.value;
            sessionStorage.setItem('basicFilter', this.basicFilter);
            this.dataSource.filterPredicate = (data: EmailTemplate, filter: string) => {
                return data.name.trim().toLowerCase().includes(filter);
            };
            this.dataSource.filter = value;
        } else {
            sessionStorage.setItem('basicFilter','');
            this.doFilter('');
        }
    }

    getTemplates() {
        this.http.get<EmailTemplate[]>('/api/template/all')
            .subscribe(
                data => {
                    this.dataSource.data = data;
                    this.doFilter('');
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání emailových šablon: [' + error.response + '].');
                    }
                }
            );
    }

    edit(id: number) {
        this.router.navigate(['./template', id]);
    }
}


