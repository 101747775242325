<div mat-dialog-content class="center" *ngIf="data.isContainerInstance && data.isRunning">
    <p class="center">Chcete smazat i běžící instanci?</p>
</div>
<div mat-dialog-content class="center" *ngIf="data.isContainerInstances && data.isRunning">
    <p class="center">Chcete smazat i běžící instance?</p>
</div>
<div mat-dialog-actions class="modal-buttons center" *ngIf="data.isContainerInstance && data.isRunning">
    <button mat-button (click)="onSubmit()">Ano</button>
    <button mat-button (click)="onClose()">Ne</button>
</div>

