<div mat-dialog-content>
    <p class="center">Vyberte datum expirace archivace</p>
    <mat-form-field class="center">
        <input matInput [(ngModel)]="expirationDate" [min]="minDate" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="center">
        <mat-select placeholder="Archiv" [(ngModel)]="selectedSemester" (selectionChange)="archiveChoose($event.value)">
            <mat-option *ngFor="let semester of semesters" [value]="semester">
                {{ semester }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions class="center">
    <button mat-raised-button (click)="onSubmit()" [disabled]="expirationDate === null || selectedSemester === null">Archivovat</button>
    <button mat-raised-button (click)="onClose()">Zrušit</button>
</div>

