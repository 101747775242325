import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {UntypedFormBuilder} from '@angular/forms';
import {ImportResult} from '../../dm/import/ImportResult';
import {GitlabProject} from '../../dm/import/gitlabProject';
import {GitlabPerson} from '../../dm/import/gitlabPerson';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ManageTagsComponent} from "../../manage-tags/manage-tags.component";
import {MatDialog} from "@angular/material/dialog";
import {Course} from "../../dm/course";
import {HttpErrorService} from "../../services/http.error.service";
import {MatChipsModule} from '@angular/material/chips';

@Component({
    selector: 'app-gitlab-preview',
    templateUrl: './gitlab-import-preview.component.html',
    styleUrls: ['./gitlab-import-preview.component.css']
})
export class GitlabImportPreviewComponent implements OnInit {
    mineProjects: GitlabProject[] = [];
    otherProjects: GitlabProject[] = [];
    selectedProjects: GitlabProject[] = [];
    clicked = false;
    allOtherSelected = false;
    allMineSelected = false;
    tags: string[] = [];
    selectedCourse = 'SI1';
    public courses: Course[] = [];
    projectsLoaded = false;

    constructor(private http: HttpClient,
                private fb: UntypedFormBuilder,
                private router: Router,
                private notificationService: NotificationService,
                private dialogRef: MatDialogRef<GitlabImportPreviewComponent>,
                public dialog: MatDialog,
                private httpErrorService: HttpErrorService,
                @Inject(MAT_DIALOG_DATA) public description: string) {}

    ngOnInit() {
        this.http.get<Course[]>('/api/course/all')
            .subscribe(
                data => {
                    this.courses = data;
                    this.selectedCourse = data[0].name;
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání kurzů: ' + error.status);
                    }
                }
            );

        this.notificationService.notify('Načítám preview...');
        this.http.post<ImportResult>('/api/import/gitlab/preview', {description: this.description}).subscribe(
            data => {
                console.log(data);
                this.mineProjects = [];
                this.otherProjects = [];
                for (const prj of data.otherProjects) {
                    const project = new GitlabProject();
                    project.id = prj.gitlabProjectID;
                    project.name = prj.name;
                    project.description = prj.description;
                    project.web_url = prj.gitlabProjectURL;
                    project.members = [];
                    project.selected = false;
                    const members = prj.enrolledStudents;
                    for (const person of members) {
                        const gitlabPerson = new GitlabPerson();
                        gitlabPerson.name = person.name;
                        gitlabPerson.username = person.loginCVUT;
                        gitlabPerson.email = person.email;
                        project.members.push(gitlabPerson);
                    }
                    this.otherProjects.push(project);
                }
                for (const prj of data.mineProjects) {
                    const project = new GitlabProject();
                    project.id = prj.gitlabProjectID;
                    project.name = prj.name;
                    project.description = prj.description;
                    project.web_url = prj.gitlabProjectURL;
                    project.members = [];
                    project.selected = true;
                    const members = prj.enrolledStudents;
                    for (const person of members) {
                        const gitlabPerson = new GitlabPerson();
                        gitlabPerson.name = person.name;
                        gitlabPerson.username = person.loginCVUT;
                        gitlabPerson.email = person.email;
                        project.members.push(gitlabPerson);
                    }
                    this.mineProjects.push(project);
                }
                if (this.otherProjects.length === 0 && this.mineProjects.length === 0) {
                    this.dialogRef.close();
                    this.notificationService.notify('Nenašel se žádný projekt.');
                } else {
                    this.updateAllMineSelected();
                    this.updateAllOtherSelected();
                    this.notificationService.notify('Preview načteno.');
                    this.projectsLoaded = true;
                }
                console.log(this.otherProjects);
            }, error => {
                console.error('Couldn\'t post because', error);
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Chyba při zobrazení preview. Zkontrolujte, zda máte správně nastavený gitlab access token.');
                }
            }
        );
    }

    filterProjects() {
        this.selectedProjects = [];
        for (const prj of this.mineProjects) {
            if (prj.selected === true) {
                this.selectedProjects.push(prj);
            }
        }

        for (const prj of this.otherProjects) {
            if (prj.selected === true) {
                this.selectedProjects.push(prj);
            }
        }
    }

    submitImport() {
        this.filterProjects();
        this.addPropertiesToSelectedProjects();
        this.notificationService.notify('Probíhá import projektů (' + this.selectedProjects.length + ')...');
        this.http.post<ImportResult>('api/import/gitlab/import', {projects: this.selectedProjects})
            .subscribe(
                data => {
                    this.notificationService.notify('Import úspěšný.');
                    this.dialogRef.close();
                    this.router.navigate(['./list-projects']);
                },
                error => {
                    console.log('error', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Import selhal: [' + error.status + '].');
                    }
                }
            );
    }

    updateAllOtherSelected() {
        this.allOtherSelected = this.otherProjects.length !== 0 && this.otherProjects.every(t => t.selected);
    }

    someOtherSelected(): boolean {
        if (this.otherProjects.length === 0) {
            return false;
        }
        return this.otherProjects.filter(t => t.selected).length > 0 && !this.allOtherSelected;
    }

    updateAllMineSelected() {
        this.allMineSelected = this.mineProjects.length !== 0 && this.mineProjects.every(t => t.selected);
    }

    someMineSelected(): boolean {
        if (this.mineProjects.length === 0) {
            return false;
        }
        return this.mineProjects.filter(t => t.selected).length > 0 && !this.allMineSelected;
    }

    selectAllOther(selected: boolean) {
        this.allOtherSelected = selected;
        if (this.otherProjects.length === 0) {
            return;
        }
        this.otherProjects.forEach(t => t.selected = selected);
    }

    selectAllMine(selected: boolean) {
        this.allMineSelected = selected;
        if (this.mineProjects.length === 0) {
            return;
        }
        this.mineProjects.forEach(t => t.selected = selected);
    }

    numberSelected(projects: GitlabProject[]): number {
        let selected = 0;
        projects.forEach(t => {
            if (t.selected === true) {
                selected++;
            }
        });
        return selected;
    }

    manageTags() {
        const dialogRefTags = this.dialog.open(ManageTagsComponent, {
            width: '600px'
        });
        dialogRefTags.afterClosed().subscribe(tag => {
            if (tag != undefined) {
                this.notificationService.notify('Tag byl nastaven.');
                this.tags.push(tag);
            }
        });
    }

    addPropertiesToSelectedProjects() {
        this.selectedProjects.forEach(element => {
            element.tags = this.tags;
            element.course = this.selectedCourse;
        });
    }

    removeTag(tag: string) {
        const index = this.tags.indexOf(tag, 0);
        if (index > -1) {
            this.tags.splice(index, 1);
        }
    }

}
