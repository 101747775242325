import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../services/notification.service';
import {MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {HttpErrorService} from "../services/http.error.service";

@Component({
    selector: 'app-manage-tags',
    templateUrl: './manage-tags.component.html',
    styleUrls: ['./manage-tags.component.css']
})
export class ManageTagsComponent implements OnInit {

    tagControl = new UntypedFormControl();
    lastTagFilter: string = '';
    userTags: string[] = [];
    filteredUserTags: Observable<string[]>;


    constructor(public dialogRef: MatDialogRef<ManageTagsComponent>,
                private http: HttpClient,
                private notificationService: NotificationService,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        this.getUserTags();

    }

    getUserTags() {
        this.userTags = [];
        this.http.get<string[]>('/api/user-tags/get')
            .subscribe(
                data => {
                    data.forEach(s => this.userTags.push(s));
                    this.filteredUserTags = this.tagControl.valueChanges.pipe(
                        startWith<string | string[]>(''),
                        map(value => typeof value === 'string' ? value : this.lastTagFilter),
                        map(filter => this.filterUserTags(filter))
                    );
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání tagů: [' + error.status + '].');
                    }
                }
            );
    }

    filterUserTags(filter: string): string[] {
        this.lastTagFilter = filter;
        if (filter) {
            return this.userTags.filter(option => {
                return option.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
        } else {
            return this.userTags.slice();
        }
    }

    addUserTag(tag: string) {
        this.http.post('/api/user-tags/add', tag)
            .subscribe(data => {
                console.log('New tag successfully added.', data);
                if (!!data) {
                    this.notificationService.notify('Tag zapamatován.')
                }
                this.getUserTags();
            }, error => {
                console.error('Couldn\'t post because', error);
            });
    }

    deleteUserTag(tag: string) {
        const saved = this.tagControl.value;
        this.http.post('/api/user-tags/delete', tag)
            .subscribe(data => {
                console.log('Tag successfully deleted.', data);
                if (!!data) {
                    this.notificationService.notify('Tag smazán.')
                }
                this.getUserTags();
            }, error => {
                console.error('Couldn\'t post because', error);
            });
        this.getUserTags();
        this.tagControl.setValue(saved);
    }


    tagClicked(event: Event, tag: string) {
        event.stopPropagation();
        this.tagControl.setValue(tag);
    }

    selectTag(tag: string) {
        this.tagControl.setValue(tag);
    }

    onNoClick() {
        this.dialogRef.close();
    }

}
