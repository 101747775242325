import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
import {NotificationService} from "../services/notification.service";

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm = this.formBuilder.group({
        username: [null, Validators.required],
        password: [null, Validators.required],
    });

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to projectForm fields
    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        if (this.loginForm.invalid) {
            return;
        }
        this.authenticationService.login(this.f.username.value, this.f.password.value,
            (success => {
                if (!success) {
                    this.notificationService.notifyError('Přihlášení selhalo.');
                }
                if (success) {
                    this.router.navigateByUrl('/list-projects');
                    this.notificationService.notify('Přihlášení úspěšné.');

                }
            }));
    }
}
