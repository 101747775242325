<mat-card id="list-templates" class="table-filter">
    <mat-card-content class="table-filter-content">
        <mat-form-field>
            <input matInput type="text" (keyup)="filterBasic($event)" placeholder="Vyhledat" [formControl]="basicFilterControl">
        </mat-form-field>
    </mat-card-content>
</mat-card>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Nazev</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Action buttons Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 12%;" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="overflow: visible;">
            <button mat-raised-button (click)="edit(element.id)">Upravit</button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
