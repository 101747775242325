import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'app-commit-message-modal',
    templateUrl: './commit-message-modal.component.html',
    styleUrls: ['./commit-message-modal.component.css']
})
export class CommitMessageModalComponent implements OnInit {
    backupMessage: string;

    constructor(public dialogRef: MatDialogRef<CommitMessageModalComponent>
    ) {
        this.backupMessage = 'Ruční záloha';
    }

    ngOnInit() {
        // do nothing.
    }

    onSubmit() {
        this.dialogRef.close(this.backupMessage);
    }

    onClose() {
        this.dialogRef.close();
    }

}
