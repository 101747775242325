import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-archive-modal',
    templateUrl: './archive-modal.component.html',
    styleUrls: ['./archive-modal.component.css']
})
export class ArchiveModalComponent implements OnInit {
    minDate: Date;
    expirationDate: Date;
    archive: string;
    semesters = [];
    selectedSemester: string=null;

    constructor(public dialogRef: MatDialogRef<ArchiveModalComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.semesters = data.semesters;
    }

    ngOnInit() {
        this.expirationDate = new Date();
        this.expirationDate.setFullYear(this.expirationDate.getFullYear() + 1); // Pridáva jedný rok

    }

    onSubmit() {
        let expirationDate = formatDate(this.expirationDate, 'yyyy-MM-dd', 'en');
        this.dialogRef.close({expirationDate: expirationDate, archiveSemester: this.archive});
    }

    onClose() {
        this.dialogRef.close();
    }

    archiveChoose(archive: string) {
        this.archive = archive;
        this.selectedSemester=archive;
    }
}
