import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-warning-modal',
    templateUrl: './warning-modal.component.html',
    styleUrls: ['./warning-modal.component.css']
})
export class WarningModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<WarningModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        // do nothing.
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    onClose() {
        this.dialogRef.close();
    }
}
