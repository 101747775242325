import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {AuthenticationService} from "../../services/authentication.service";
import {HttpErrorService} from "../../services/http.error.service";
import {MatTableDataSource} from "@angular/material/table";
import {ContainerTemplate} from "../../dm/containerTemplate";
import {ContainerConfigurationFile} from "../../dm/containerConfigurationFile";
import {Person} from "../../dm/person";
import {Course} from "../../dm/course";
import {
    ContainerConfigurationFileComponent
} from "../container-configuration-file-form/container-configuration-file.component";
import {Observable, Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-container-template-form',
    templateUrl: './container-template.component.html',
    styleUrls: ['./container-template.component.css']
})
export class ContainerTemplateComponent implements OnInit {
    dataSource = new MatTableDataSource<any>([]);
    filteredConfigurationFiles: Observable<ContainerConfigurationFile[]>;
    lastConfigurationFileFilter: string = '';
    configurationFiles = new Array<ContainerConfigurationFile>();
    containerTemplateForm = this.formBuilder.group({
        id: [null],
        templateServerId: [null],
        name: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9_][a-zA-Z0-9_\\ ]*$')
        ]],
        tag: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9_][a-zA-Z0-9_\\ ]*$')
        ]],
        configurationFileId: [null, Validators.required],
        loggedUser: [null]
    });
    containerTemplateSubscription: Subscription;
    configurationFileFilter = '';
    configurationFileFilterControl = new UntypedFormControl();
    loggedUser: Person;
    edited = false;
    selectedConfigurationFile: ContainerConfigurationFile;
    isAdmin = this.authenticationService.isLoggedUserAdmin;
    basicConfigurationFileId = 1;
    isLoading = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private notificationService: NotificationService,
                private authenticationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    ngOnInit() {
        this.getConfigurationFiles();
        this.http.get<Person>('/api/person/' + this.authenticationService.loggedUserID)
            .subscribe(
                data => {
                    this.loggedUser = data;
                    this.containerTemplateForm.patchValue({
                        loggedUser: this.loggedUser
                    });
                },
                error => {
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání dat: [' + error.status + '].');
                    }
                    console.error('Couldn\'t get because', error);
                }
            );
    }

    doFilter(query: string) {
        if (query) {
            this.dataSource.filter = query.trim().toLocaleLowerCase();
        } else {
            this.dataSource.filter = '_';
        }
    }

    getConfigurationFiles() {
        let url = '';
        let loggedUserId = this.authenticationService.loggedUserID;
        this.isAdmin = this.authenticationService.isLoggedUserAdmin;

        if (this.isAdmin) {
            url = '/api/containers/configuration-file/all';
        } else {
            url = '/api/containers/configuration-file/owned';
        }

        const options = {
            params: {
                loggedUserId: loggedUserId.toString()
            }
        };

        this.http.get<ContainerConfigurationFile[]>(url, options)
            .subscribe({
                next: (data) => {
                    this.dataSource.data = data;
                    this.configurationFiles = data;
                    this.doFilter('');
                },
                error: (error) => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání konfiguračních souborů: [' + error.status + '].');
                    }
                }
            });

        if (!this.isAdmin) {
            this.http.get<ContainerConfigurationFile>('/api/containers/configuration-file/' + this.basicConfigurationFileId, {params: {id: this.basicConfigurationFileId.toString()}})
                .subscribe({
                    next: (data) => {
                        this.configurationFiles.push(data);
                        this.doFilter('');
                    },
                    error: (error) => {
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Chyba při načítání basic_configuration: [' + error.status + '].');
                        }
                        console.error('Couldn\'t get because', error);
                    }
                });
        }
    }

    onSubmit() {
        this.isLoading = true;
        let serializedForm;
        let url = '/api/containers/templates/create';
        const values = this.containerTemplateForm.getRawValue();
        serializedForm = JSON.stringify(values);

        this.http.post<ContainerTemplate>(url, serializedForm, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')})
            .subscribe({
                next: (data) => {
                    console.log('success!', data);
                    this.notificationService.notify('Úspěšně vytvořeno. [ id:' + data.id + ', name: ' + data.name + ', tag: ' + data.tag + ' ]');
                    this.router.navigate(['list-containers', 'templates']);
                },
                error: (error) => {
                    console.error('Couldn\'t post because', error);
                    if (error.status == 400) {
                        this.notificationService.notifyError('Chyba při vytváření: ' + error.error);
                    } else if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při ukládání: ' + error.error);
                    }
                }
            }).add(() => {
            this.isLoading = false;
        });
    }

    configurationFileClicked(event: Event, containerConfigurationFile: ContainerConfigurationFile) {
        event.stopPropagation();
        this.selectedConfigurationFile = containerConfigurationFile;
        this.containerTemplateForm.patchValue({configurationFileId: this.selectedConfigurationFile.id});
        this.configurationFileFilterControl.setValue(containerConfigurationFile.name);
        this.edited = true;
    }

    ngOnDestroy() {
        if (this.containerTemplateSubscription) {
            this.containerTemplateSubscription.unsubscribe();
        }
    }
}
