<mat-card id="list-backups" class="table-filter">
    <mat-card-content class="table-filter-content">

        <mat-form-field>
            <mat-select placeholder="Databáze" [formControl]="backupFilterControl">
                <mat-option [value]="" (click)="filterDatabase('')"></mat-option>
                <mat-option (click)="filterDatabase('exists')">Existuje</mat-option>
                <mat-option (click)="filterDatabase('doesntExist')">Neexistuje</mat-option>
            </mat-select>
        </mat-form-field>

    </mat-card-content>
</mat-card>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="backupId">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let backup"> {{backup.backupId}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="projectName">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Projekt</th>
        <td mat-cell *matCellDef="let backup"> {{backup.projectName}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dbType">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Typ databáze</th>
        <td mat-cell *matCellDef="let backup"> {{backup.dbType}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="databaseId">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> ID databáze</th>
        <td mat-cell *matCellDef="let backup"> {{backup.databaseId}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell style="width: 7%;" *matHeaderCellDef mat-sort-header> Popis</th>
        <td mat-cell *matCellDef="let backup"> {{backup.description}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="whenCreated">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Vytvořeno</th>
        <td mat-cell *matCellDef="let backup"> {{backup.whenCreated}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deleteTime">
        <th mat-header-cell style="width: 4%;" *matHeaderCellDef mat-sort-header> Čas smazání</th>
        <td mat-cell *matCellDef="let backup"> {{backup.deleteTime}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!--Selected Column -->
    <ng-container matColumnDef="selected">
        <th mat-header-cell style="text-align: center; vertical-align: middle; width: 4%;" *matHeaderCellDef>
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedBackups.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let backup" style="text-align: center; vertical-align: middle;">
            <mat-checkbox (change)="switchSelected(backup)" [checked]="backup.selected"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle;">
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedBackups.size === dataSource.data.length && dataSource.data.length !== 0">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 3%;" *matHeaderCellDef>
            <button mat-raised-button [disabled]="selectedBackups.size === 0" [matMenuTriggerFor]="actionMenuTop">
                Akce
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuTop="matMenu">
                <button mat-menu-item (click)="restoreSelected(-1)">
                    Obnovit vybrané
                </button>
                <button mat-menu-item (click)="downloadSelected(-1)">
                    Stáhnout vybrané
                </button>
                <button mat-menu-item (click)="deleteSelected(-1)">
                    Smazat vybrané
                </button>
            </mat-menu>
        </th>
        <td mat-cell *matCellDef="let backup" style="overflow: visible;">
            <button mat-raised-button [matMenuTriggerFor]="actionMenu">
                Akce
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
                <button mat-menu-item (click)="restoreSelected(backup.backupId)">Obnovit</button>
                <button mat-menu-item (click)="downloadSelected(backup.backupId)">Stáhnout</button>
                <button mat-menu-item (click)="deleteSelected(backup.backupId)">Smazat</button>
            </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <button mat-raised-button [disabled]="selectedBackups.size === 0" [matMenuTriggerFor]="actionMenuBottom">
                Akce
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenuBottom="matMenu">
                <button mat-menu-item (click)="restoreSelected(-1)">
                    Obnovit vybrané
                </button>
                <button mat-menu-item (click)="downloadSelected(-1)">
                    Stáhnout vybrané
                </button>
                <button mat-menu-item (click)="deleteSelected(-1)">
                    Smazat vybrané
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
