import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatabaseData} from '../dm/databaseData';
import {Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AuthenticationService} from '../services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {CommitMessageModalComponent} from './commit-message-modal/commit-message-modal.component';
import {Database} from "../dm/database";
import * as fileSaver from 'file-saver';
import {WarningModalComponent} from "../list-backups/warning-modal/warning-modal.component";
import {HttpErrorService} from "../services/http.error.service";
import {BackupUploadModalComponent} from "./backup-upload-modal/backup-upload-modal.component";
import {BackupUpload} from "../dm/backupUpload";


@Component({
    selector: 'app-list-projects',
    templateUrl: './list-databases.component.html',
    styleUrls: ['./list-databases.component.css']
})
export class ListDatabasesComponent implements OnInit {
    dataSource = new MatTableDataSource<DatabaseData>();
    displayedColumns: string[] = ['database.id', 'database.name', 'database.host', 'project.name', 'diskUsage', 'selected', 'actions'];
    selectedDatabases = new Set();
    indeterminateV = false;

    constructor(private http: HttpClient,
                private router: Router,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private authentificationService: AuthenticationService,
                private httpErrorService: HttpErrorService) {
    }

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    ngOnInit() {
        this.getDatabases();
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'database.id':
                    return item.database.id;
                case 'database.name':
                    return item.database.name;
                case 'database.host':
                    return item.database.host;
                case 'project.name':
                    return item.project.name;
                case 'diskUsage':
                    return item.diskUsage;
            }
            return '';
        };
        this.dataSource.filterPredicate = (data, filter: string) => {
            const accumulator = (currentTerm, key) => {
                return this.nestedFilterCheck(currentTerm, data, key);
            };
            const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            const transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };
    }

    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }

    doFilter(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    getDatabases() {
        this.http.get<DatabaseData[]>('/api/database/dataAll')
            .subscribe(
                data => {
                    if (this.authentificationService.isLoggedUserAdmin) {
                        this.dataSource.data = data;
                    } else {
                        for (let database of data) {
                            if (this.authentificationService.loggedUserID == database.project.leader.id) {
                                this.dataSource.data.push(database);
                                this.doFilter("");
                            }
                        }
                    }
                },
                error => {
                    console.error('Couldn\'t get because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při načítání databází: [' + error.response + '].');
                    }
                }
            );
    }

    redirectToProject(id: number) {
        this.router.navigate(['project', id]);
    }

    redirectToDatabase(id: number) {
        this.router.navigate(['detail-database', id]);
    }

    setBackupMessage(id: number) {
        const dialogRef = this.dialog.open(CommitMessageModalComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(message => {
            if (message) {
                this.backup(id, message);
            }
        });
    }


    uploadBackup(id: number) {
        const dialogRef = this.dialog.open(BackupUploadModalComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(payload => {
            if (payload) {
                let fd = new FormData();
                fd.append('file', payload.file);
                this.http.post(`/api/backup/upload/${id}?description=${payload.description}`, fd).subscribe(data => {
                        this.notificationService.notify('Nahrání zálohy proběhlo úspěšně.');
                    }, error => {
                        this.notificationService.notifyError(`${error.error.message}`);
                    }
                );
            }
        })
    }

    switchAll() {
        if (this.selectedDatabases.size < this.dataSource.filteredData.length) {
            for (const element of this.dataSource.filteredData) {
                element.database.selected = true;
                this.selectedDatabases.add(element.database);
            }
        } else {
            for (const element of this.dataSource.filteredData) {
                element.database.selected = false;
                this.selectedDatabases.delete(element.database);
            }
        }
        this.indeterminate();
    }

    indeterminate() {
        this.indeterminateV = this.selectedDatabases.size !== 0 && this.dataSource.data.length !== 0
            && this.selectedDatabases.size !== this.dataSource.data.length;
    }

    switchSelected(database: Database) {
        database.selected = !database.selected;
        if (database.selected) {
            this.selectedDatabases.add(database);
        } else {
            this.selectedDatabases.delete(database);
        }
        this.indeterminate();
    }

    exportSelected() {
        const idArray: number[] = [];
        this.selectedDatabases.forEach((database: Database) => {
            idArray.push(database.id);
        });
        this.export(idArray)
    }

    exportDatabase(id: number) {
        const idArray: number[] = [];
        idArray.push(id);
        this.export(idArray)
    }

    export(idArray: number[]) {
        console.log(idArray)
        this.http.post('/api/database/export-json/', {ids: idArray}, {responseType: 'blob'})
            .subscribe(data => {
                this.notificationService.notify('Export proběhl úspěšně, zahajuji stahování...');
                fileSaver.saveAs(data, `export_to_pgAdmin.json`);
                console.log(data)
            }, error => {
                console.error('Couldn\'t post because', error);
                if (!this.httpErrorService.ignoreError(error.status)) {
                    this.notificationService.notifyError('Export selhal.');
                }
            });
    }

    //TODO Get DB type information from BE not from parsing string...
    getDatabaseType(databaseName: string) {
        return (databaseName.includes('_project_db') ? "project" : "ea")
    }

    backup(id: number, message: string) {
        const url = '/api/backup/create/' + id;
        const database = {
            backupId: 0,
            databaseId: 0,
            dbType: '',
            deleteTime: '',
            description: message,
            projectName: '',
            whenCreated: '',
        };
        const serialized = JSON.stringify(database);

        this.http.post(url, serialized, {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')})
            .subscribe(
                data => {
                    this.notificationService.notify('Záloha proběhla úspěšně.');
                },
                error => {
                    console.error('Couldn\'t post because', error);
                    if (!this.httpErrorService.ignoreError(error.status)) {
                        this.notificationService.notifyError('Chyba při zálohování: [' + error.error.message + '].');
                    }
                });
    }

    delete(element: DatabaseData) {
        const dialogRef = this.dialog.open(WarningModalComponent, {
            width: '500px',
            data: {isDatabase: true}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.http.delete('/api/database/' + element.database.id).subscribe(
                    data => {
                        const index = this.dataSource.data.indexOf(element, 0);
                        if (index > -1) {
                            this.dataSource.data.splice(index, 1);
                            this.dataSource = new MatTableDataSource<DatabaseData>(this.dataSource.data);
                            console.log('Delete successful ', data);
                            this.notificationService.notify('Databáze smazána úspěšně.');
                        }
                    },
                    error => {
                        console.error('Couldn\'t delete because', error);
                        if (!this.httpErrorService.ignoreError(error.status)) {
                            this.notificationService.notifyError('Chyba při mazání databáze: [' + error.error.message + '].');
                        }
                    }
                );
            }
        });
    }
}
