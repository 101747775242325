<form id="create-container-template" [formGroup]="containerTemplateForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="create-person">
        <mat-card-header>
            <mat-card-title>Nová šablona kontejneru</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row" style="margin-bottom: 20px;">
                <!--name-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Název" formControlName="name">
                        <mat-error *ngIf="containerTemplateForm.controls['name'].hasError('required')">
                            Název nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error *ngIf="containerTemplateForm.controls['name'].hasError('pattern')">
                            Název <strong>musí</strong> být tvořen kombinací
                            písmen bez diakritiky, čísel a mezer
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--tag-->
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Tag" formControlName="tag">
                        <mat-error *ngIf="containerTemplateForm.controls['tag'].hasError('required')">
                            Tag nemůže být <strong>prázdný</strong>
                        </mat-error>
                        <mat-error *ngIf="containerTemplateForm.controls['tag'].hasError('pattern')">
                            Tag <strong>musí</strong> být tvořen kombinací
                            písmen, čísel a mezer
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--configurationFile-->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input type="text" placeholder="Vybrat konfiguraci" matInput
                               [matAutocomplete]="configurationFile"
                               [formControl]="configurationFileFilterControl" formControlName="configurationFileId">
                    </mat-form-field>
                    <mat-autocomplete #configurationFile="matAutocomplete">
                        <ng-container *ngFor="let configurationFile of configurationFiles">
                            <mat-option *ngIf="!configurationFile.selected" [value]="configurationFile.name"
                                        (click)="configurationFileClicked($event, configurationFile)">
                                {{ configurationFile.name }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!containerTemplateForm.valid || isLoading" mat-raised-button color="primary"
                    type="submit">Vytvořit
            </button>
        </mat-card-actions>
    </mat-card>
</form>
<mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
