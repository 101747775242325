<form id="create-container-instance" [formGroup]="containerInstanceForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="create-container-instance">
        <mat-card-header>
            <mat-card-title style="color:white">Nová instance kontejneru</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input type="text" placeholder="Vybrat šablonu" matInput [matAutocomplete]="template"
                               [formControl]="templateFilterControl" formControlName="template">
                    </mat-form-field>
                    <mat-autocomplete #template="matAutocomplete">
                        <ng-container *ngFor="let template of templates">
                            <mat-option *ngIf="!template.selected" [value]="template.name"
                                        (click)="templateClicked($event, template)">
                                {{ template.name }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>
            <!--projects-->
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width" *ngIf="!projectId && idArray?.length === 0">
                        <input type="text" placeholder="Vybrat projekty" matInput [matAutocomplete]="project"
                               [formControl]="projectFilterControl" formControlName="projects">
                    </mat-form-field>
                    <mat-autocomplete #project="matAutocomplete">
                        <ng-container *ngFor="let project of projects">
                            <mat-option *ngIf="!project.selected" [value]="project.name"
                                        (click)="projectClicked($event, project)">
                                {{ project.name }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>
            <mat-label style="color: white">Projekty:</mat-label>
            <mat-list dense>
                <mat-list-item *ngIf="selectedProjects?.length === 0">Nebyl vybrán žádný projekt.</mat-list-item>
                <mat-list-item *ngFor="let project of selectedProjects">
                    <div style="display: flex; align-items: center;">
                        <mat-icon style="margin: 5px" matListIcon>fiber_manual_record</mat-icon>
                        {{ project.name }}
                    </div>
                </mat-list-item>
            </mat-list>
            <mat-checkbox (change)="changeStatus()"
                          [checked]="isChecked"><span
                style="font-size: 17px;">Spustit instanci po vytvoření</span></mat-checkbox>
        </mat-card-content>
        <mat-card-actions>
            <button [disabled]="!containerInstanceForm.valid || isLoading" mat-raised-button color="primary"
                    type="submit">Vytvořit
            </button>
        </mat-card-actions>
    </mat-card>
</form>
<div *ngIf="containerInstance !== undefined">
    <mat-card class="create-person h2">
        <mat-card-title>{{'Informace o osobě'}}</mat-card-title>
        <mat-card-content>
            <mat-grid-list cols="2" rowHeight="4em">
            </mat-grid-list>
        </mat-card-content>
    </mat-card>
</div>
<mat-spinner class="loading" *ngIf="isLoading"></mat-spinner>
