import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

export interface ModalData {
    isCertificationConfigurationCertificate: boolean;
    certificationAuthorityCertificate: string;
}

@Component({
    selector: 'app-certification-configuration-certificate-modal',
    templateUrl: './certification-configuration-certificate-modal.component.html',
    styleUrls: ['./certification-configuration-certificate-modal.component.css']
})
export class CertificationConfigurationCertificateModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<CertificationConfigurationCertificateModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ModalData) {
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close();
    }
}
