import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-database-list',
    templateUrl: './database-list.component.html',
    styleUrls: ['./database-list.component.css']
})
export class DatabaseListComponent implements OnInit {
    dbData = this.data.databasesName;
    dataSource = new MatTableDataSource<any>(this.dbData);
    displayedColumns: string[] = ['databaseName'];

    constructor(public dialogRef: MatDialogRef<DatabaseListComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        // do nothing.
    }

    onClose() {
        this.dialogRef.close();
    }
}
