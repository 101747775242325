<mat-card id="list-people" class="table-filter">
    <mat-card-content class="table-filter-content">
        <mat-form-field>
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Vyhledat">
        </mat-form-field>
    </mat-card-content>
</mat-card>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell style="width: 5%;" *matHeaderCellDef mat-sort-header> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell style="width: 26%;" *matHeaderCellDef mat-sort-header> Jméno</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
        <th mat-header-cell style="width: 18%;" *matHeaderCellDef mat-sort-header> Username</th>
        <td mat-cell *matCellDef="let element"> {{element.loginCVUT}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <!-- Database Role Column -->
    <ng-container matColumnDef="databaseRole">
        <th mat-header-cell style="width: 18%;" *matHeaderCellDef mat-sort-header> DB role</th>
        <td mat-cell *matCellDef="let element"> {{element.databaseRole}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell style="width: 0%;" *matHeaderCellDef mat-sort-header> Email</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Projects Column -->
    <ng-container matColumnDef="projects">
        <th mat-header-cell style="width: 6%;" *matHeaderCellDef mat-sort-header> # projektů</th>
        <td mat-cell *matCellDef="let element"> {{element.projects.length}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
        <th mat-header-cell style="width: 13%;" *matHeaderCellDef mat-sort-header> Role</th>
        <td mat-cell *matCellDef="let element"> {{element.role}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!--Selected Column -->
    <ng-container matColumnDef="selected">
        <th mat-header-cell style="width: 7%;" *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element" style="text-align: center; vertical-align: middle">
            <mat-checkbox (change)="switchSelected(element)" [checked]="element.selected"></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef style="text-align: center; vertical-align: middle">
            <mat-checkbox [(indeterminate)]="indeterminateV" (change)="switchAll()"
                          [checked]="selectedPeople.length === dataSource.data.length"></mat-checkbox>
        </td>
    </ng-container>

    <!-- Action buttons Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell style="width: 12%;" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="overflow: visible;">
            <button mat-raised-button (click)="edit(element.id)">Upravit</button>
            <button mat-raised-button (click)="delete(element)">Smazat</button>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <button mat-raised-button [disabled]="selectedPeople.length === 0" (click)="deleteSelected()">Smazat
                vybrané
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
